.g-status-university {
  @include breakpoint ($for-phone-only) {

    a {padding: 0}
  }
  @include breakpoint ($for-tablet-portrait-up) {

    a {padding: 0}
  }
  @include breakpoint ($for-tablet-landscape-up) {


  }
}