.m-structura {
  background-color: white;
  @include breakpoint ($for-phone-only) {
    border-bottom: 1px solid $color-border;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
  }
  @include breakpoint ($for-tablet-portrait-up) {
    border-bottom: 1px solid $color-border;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
  }
  @include breakpoint ($for-tablet-landscape-up) {
    border-bottom: 0;
    box-shadow: none;
  }
  @include breakpoint ($for-desktop-up) {
    border-bottom: 0;
    box-shadow: none;
  }
  @include breakpoint ($for-big-desktop-up) {
    border-bottom: 0;
    box-shadow: none;
  }
  &__filter-list {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
  &__filter_input {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
  &__type {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
  &__item {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
    &-open__child {
      @include breakpoint ($for-phone-only) {
        border-top: 0;
        border-left: 0;
        border-bottom: 0;
        border-right-width: .085rem;
        border-style: solid;
        border-color: $color-border;
        color: $color-link-normal;
        &:hover, &:focus {
          cursor: pointer;
          border-color: $color-border-pagination-hover;
        }
      }
      @include breakpoint ($for-tablet-portrait-up) {
        border-top: 0;
        border-left: 0;
        border-bottom: 0;
        border-right-width: .085rem;
        border-style: solid;
        border-color: $color-border;
        color: $color-link-normal;
        &:hover, &:focus {
          cursor: pointer;
          border-color: $color-border-pagination-hover;
        }
      }
      @include breakpoint ($for-tablet-landscape-up) {
        border-width: .085rem;
        border-style: solid;
        border-color: $color-border;
        color: $color-link-normal;
        &:hover, &:focus {
          cursor: pointer;
          border-color: $color-border-pagination-hover;
        }
      }
      @include breakpoint ($for-desktop-up) {
        border-width: .085rem;
        border-style: solid;
        border-color: $color-border;
        color: $color-link-normal;
        &:hover, &:focus {
          cursor: pointer;
          border-color: $color-border-pagination-hover;
        }

      }
      @include breakpoint ($for-big-desktop-up) {
        border-width: .085rem;
        border-style: solid;
        border-color: $color-border;
        color: $color-link-normal;
        &:hover, &:focus {
          cursor: pointer;
          border-color: $color-border-pagination-hover;
        }
      }
    }
    &-parent {
     list-style-type: none;
      @include breakpoint ($for-phone-only) {
        li{border-top: .01rem solid $color-border;}
      }
      @include breakpoint ($for-tablet-portrait-up) {
        li{border-top: .01rem solid $color-border;}
      }
      @include breakpoint ($for-tablet-landscape-up) {
        li {border: 0}
        .m-structura__item-name {
          font-weight: bold;
          font-size: 1.1em;
        }
      }
      @include breakpoint ($for-desktop-up) {
        li {border: 0}
        .m-structura__item-name {
          font-weight: bold;
          font-size: 1.1em;
        }
      }
      @include breakpoint ($for-big-desktop-up) {

        border: 0;
        li {border: 0}
        .m-structura__item-name {
          font-weight: bold;
          font-size: 1.1em;
        }
      }
    }
    &-name {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
    &-child {
      list-style-type: none;
      @include breakpoint ($for-phone-only) {
        li {
          border-top: .01rem solid $color-border;
        }
      }
      @include breakpoint ($for-tablet-portrait-up) {
        li {
          border-top: .01rem solid $color-border;
        }
      }
      @include breakpoint ($for-tablet-landscape-up) {
        border-left: .085rem dashed $color-border;
        li {
          border: 0;
        }
        .m-structura__item-name {
          font-weight: normal;
          font-size: inherit;
        }
      }
      @include breakpoint ($for-desktop-up) {
        border-left: .085rem dashed $color-border;
        li {
          border: 0;
        }
        .m-structura__item-name {
          font-weight: normal;
          font-size: inherit;
        }
      }
      @include breakpoint ($for-big-desktop-up) {
        border-left: .085rem dashed $color-border;
        li {
          border: 0;
        }
        .m-structura__item-name {
          font-weight: normal;
          font-size: inherit;
        }
      }
    }
    &-info {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
      &__item {
        @include breakpoint ($for-phone-only) {
          font-size: .85em;
          color: $color-text-grey;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          font-size: .85em;
          color: $color-text-grey;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          font-size: .85em;
          color: $color-text-grey;
        }
        @include breakpoint ($for-desktop-up) {
          font-size: .85em;
          color: $color-text-grey;
        }
        @include breakpoint ($for-big-desktop-up) {
          font-size: .85em;
          color: $color-text-grey;
        }
      }
    }
  }
  &__item-info-icon {
    background-image: url("/static/img/diagonal-arrow.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    fill: $color-border;
    &:hover, &:focus {
      cursor: pointer;
      opacity: .9;
    }
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
}
