.g-content-header {
  &__section {
    @include breakpoint($for-phone-only) {
      width: $small;
      display: flex;
      flex-flow: column;
      box-sizing: border-box;
    }
    @include breakpoint($for-tablet-portrait-up) {
      width: $small;
      display: flex;
      flex-flow: column;
      box-sizing: border-box;
    }
    @include breakpoint($for-tablet-landscape-up) {
      margin: 0 auto 4rem auto;
      width: $small;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
    }
    @include breakpoint($for-desktop-up) {
      margin: 0 auto 4rem auto;
      width: $middle;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
    }
    @include breakpoint($for-big-desktop-up) {
      margin: 0 auto 4rem auto;
      width: $big;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
    }

    &__mobile-name {
      @include breakpoint($for-phone-only) {
        padding: 1rem .5rem .5rem .5rem;
        display: flex;
        margin-bottom: 1rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        padding: 1rem .5rem .5rem .5rem;
        display: flex;
        margin-bottom: 1rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        display: none;
      }
      @include breakpoint($for-desktop-up) {
        display: none;
      }
      @include breakpoint($for-big-desktop-up) {
        display: none;
      }

      &-logo {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
      }

      &-title {

      }
    }

    &-direction {
      @include breakpoint($for-phone-only) {
        margin: 2rem .5rem 0 .5rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        margin: 2rem .5rem 0 .5rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        margin: 0;
      }
      @include breakpoint($for-desktop-up, $for-big-desktop-up) {
        margin: 0;
      }
    }

    &-name {
      @include breakpoint($for-phone-only) {
        margin: 1.5rem .25rem .5rem .75rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        flex: 1 0;
        width: 100%;
        margin: 1.5rem .25rem .5rem .75rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        flex: 1 0;
        width: 100%;
        margin: 0 0 2rem 0;
      }
      @include breakpoint($for-desktop-up, $for-big-desktop-up) {
        flex: 1 0;
        width: 100%;
        margin: 0 0 2rem 0;
      }


    }

    &__mobile-wrapper {
      @include breakpoint($for-phone-only) {
        display: none;
        padding: 1rem;
        align-items: center;
        margin-bottom: 1rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        display: flex;
        padding: 1rem;
        align-items: center;
        margin-bottom: 1rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        display: none;
      }
      @include breakpoint($for-desktop-up) {
        display: none;
      }
      @include breakpoint($for-big-desktop-up) {
        display: none;
      }

      &-close {
        .icon-close {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    &-wrapper {

      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {
        //flex: 1 0;
        width: 100%;

      }
      @include breakpoint($for-desktop-up) {
        //flex: 1 0;
        width: 100%;
      }
      @include breakpoint($for-big-desktop-up) {
        //flex: 1 0;
        width: 100%;
      }
    }

    &-info {
      @include breakpoint($for-phone-only) {
        padding: 1rem;
        margin: 1rem .25rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        padding: 1rem;
        margin: 1rem .25rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        display: flex;
        flex-flow: row nowrap;
        flex: 0 1;
        width: 100%;
        padding-bottom: 0;
        margin: 0;

      }
      @include breakpoint($for-desktop-up) {
        margin: 1rem 0 0 0;
        display: flex;
        flex-flow: row nowrap;
        flex: 0 1;
        width: 100%;
        padding: 0;
        position: relative;
      }
      @include breakpoint($for-big-desktop-up) {
        display: flex;
        flex-flow: row nowrap;
        flex: 0 1;
        width: 100%;
        padding: 0;
        margin: 1rem 0 0 0;
        position: relative;
      }

      &__more-menu {
        @include breakpoint($for-phone-only) {
          display: flex;
          flex-flow: row nowrap;
          padding: .75rem;
          margin: .5rem -1rem -1rem -1rem;
          justify-content: center;
        }
        @include breakpoint($for-tablet-portrait-up) {
          display: flex;
          flex-flow: row nowrap;
          padding: .75rem;
          margin: .5rem -1rem -1rem -1rem;
          justify-content: center;
        }
        @include breakpoint($for-tablet-landscape-up) {
          display: none;
        }
        @include breakpoint($for-desktop-up, $for-big-desktop-up) {
          display: none;
        }
      }

      &__more-menu__open {
        .g-child-section__lvl-2__wrapper, .g-navigation-module__lvl-1 .g-navigation-module__lvl-2__wrapper-1 {
          display: block;
        }
      }
    }


    &-nav {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {


      }
      @include breakpoint($for-tablet-landscape-up) {
        padding: 0;
        display: flex;
        flex-flow: row nowrap;
        margin: 0;
      }
      @include breakpoint($for-desktop-up, $for-big-desktop-up) {
        padding: 0;
        display: flex;
        flex-flow: row nowrap;
        //position: relative;
        min-width: 8rem;
        .g-navigation_edit {
          position: absolute;
          left: 9rem;
          top: -3.25rem;;
        }
      }


    }

    &-child {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {
        display: flex;
        flex-flow: row nowrap;
      }
      @include breakpoint($for-desktop-up, $for-big-desktop-up) {
        display: flex;
        flex-flow: row nowrap;
      }

      &:empty {
        display: none;

        .g-content-header__section-info {
          display: none
        }
      }
    }

    &-icon {
      @include breakpoint($for-phone-only) {
        width: 1.5rem;
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 1rem;
        height: 2.35rem;
        display: block;
        transform: rotate(90deg);
      }
      @include breakpoint($for-tablet-portrait-up) {
        width: 1.5rem;
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 1rem;
        height: 2.35rem;
        display: block;
        transform: rotate(90deg);
      }
      @include breakpoint($for-tablet-landscape-up) {
        width: 2%;
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 1rem;
        height: 2.35rem;
        display: none;
        transform: rotate(0deg);
        .g-icon_more_menu {
          height: 1.5rem;
        }
      }
      @include breakpoint($for-desktop-up, $for-big-desktop-up) {
        width: 1.5%;
        text-align: right;
        position: absolute;
        bottom: 0;
        left: 60%;
        height: 2.35rem;
        display: none;
        transform: rotate(0deg);
      }

      .g-icon_more_menu {
        @include breakpoint($for-phone-only) {
        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
        @include breakpoint($for-desktop-up, $for-big-desktop-up) {
          height: 1.5rem;
        }
      }
    }

    &-img {
      @include breakpoint($for-phone-only) {
        height: 6.25rem;
        flex-shrink: 0;
        margin: 0 .5rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        height: 6.25rem;
        flex-shrink: 0;
        margin: 0 .5rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        width: 12.25rem;
        height: 12.25rem;
        flex-shrink: 0;
        margin: 0 2.5rem 0 0;

      }
      @include breakpoint($for-desktop-up) {
        width: 12.25rem;
        height: 12.25rem;
        flex-shrink: 0;
        margin: 0 2.5rem 0 0;
      }
      @include breakpoint($for-big-desktop-up) {
        display: block;
        width: 12.25rem;
        height: 12.25rem;
        flex-shrink: 0;
        margin: 0 2.5rem 0 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

}