.g-content-header {
  &__center {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
      display: flex;
      flex-flow: column;
    }
    @include breakpoint($for-desktop-up) {
      margin-right: 14%;
    }
    @include breakpoint($for-big-desktop-up) {
      margin-right: 26%;
    }
  }

  &__left {
    position: relative;
    @include breakpoint($for-phone-only) {
      width: 4.5rem;
      margin-bottom: .5rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      width: 4.5rem;
      margin-bottom: .5rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      width: 7.5rem;
      margin-right: 1.5rem;
      margin-bottom: 0;
    }

  }

  &__right {
    @include breakpoint($for-phone-only) {
      display: none;
    }
    @include breakpoint($for-tablet-portrait-up) {
      display: none;
    }
    @include breakpoint($for-tablet-landscape-up) {
      display: block;

    }
    @include breakpoint($for-desktop-up) {
      display: block;
    }
    @include breakpoint($for-big-desktop-up) {
      display: block;
    }

  }
}