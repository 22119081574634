.g-registration {
  @include breakpoint ($for-phone-only) {
    padding: 1rem;
    margin-bottom: 1rem;
    margin-top: 0;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    padding: 1rem;
    margin-bottom: 1rem;
    margin-top: 0;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    padding: 1rem;
    margin: 0 0 2rem 0;
  }
  @include breakpoint ($for-desktop-up) {
    padding: 0;
    margin: 4rem 0 2rem 0;
  }
  &__fields {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up) {

    }
    @include breakpoint ($for-big-desktop-up) {

    }
    &__status {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
    &__welcome {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
    &__waiting {
      display: none;
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
    &__status-error {
      display: none;
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
    &__status-repeat {
      display: none;
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
    &__status-success {
      display: none;
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
  }
  &__next-step {
    display: none;
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
  &__link{
    margin-right: 1.5rem;
  }
}