.m-float-tag-menu{
  background-color: white;
  opacity: .3;
  border-left: 1px solid $color-border;
  //border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
  &:hover, &:focus{
    opacity: 1;
  }
  ul{
    li{
      list-style-type: none;
      font-size: .95em;
      line-height: 1.3em;
    }
  }
}