.m-top-bar {
  &__navigation {
    z-index: 50;
    &:hover, &:focus {
      cursor: pointer;
    }
  }
  &__logo {
    z-index: 50;
    a{
      line-height: 0;
      border-bottom: 0;
    }
  }
  &__breadcrumb {
    z-index: 50;
  }
  &__auth {
    z-index: 50;
    &:hover, &:focus {
      cursor: pointer;
    }
    .icon-auth {
      fill: #a2a2a2;
    }

  }
  &__search {
    z-index: 50;
    &:hover, &:focus {
      cursor: pointer;
    }
    .icon-search {
      fill: #a2a2a2;
    }
  }
  &__eye {
    z-index: 50;
    &:hover, &:focus {
      cursor: pointer;
    }
    .m-icon-eye {
      fill: #a2a2a2;
    }

  }
  &__languages {
    z-index: 50;

  }

}
