.g-header-menu {
  @include breakpoint($for-phone-only) {
  }
  @include breakpoint($for-tablet-portrait-up) {
  }
  @include breakpoint($for-tablet-landscape-up) {
  }
  @include breakpoint($for-desktop-up) {
  }
  @include breakpoint($for-big-desktop-up) {

  }

  &__section {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
      margin-right: 3.25rem;
    }
    @include breakpoint($for-desktop-up) {
      margin-right: 3.25rem;
    }
    @include breakpoint($for-big-desktop-up) {
      margin-right: 3.25rem;
    }

    &-wrapper {
      display: flex;
      flex-flow: row nowrap;
      @include breakpoint($for-phone-only) {
        height: 6.5rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        height: 6.5rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        height: 6.5rem;
      }
      @include breakpoint($for-desktop-up) {
        height: inherit;
      }
      @include breakpoint($for-big-desktop-up) {
        height: inherit;
      }
    }

    &-list {
      display: flex;
      flex-flow: row nowrap;
      @include breakpoint($for-phone-only) {
        padding: 1rem 0 1rem 1rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        padding: 1rem 0 1rem 1rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        padding: 0;
      }
      @include breakpoint($for-desktop-up) {
        padding: 0;
      }
      @include breakpoint($for-big-desktop-up) {
        padding: 0;
      }
    }

    &-item {
      a {
        font-size: .75rem;
        border-bottom: 1px solid transparent;

        &:hover {
          border-bottom: 1px solid $color-text-orange-underline-light;
        }

        &:visited, &:active, &:focus {
          border-bottom: 1px solid transparent;
        }
      }

      @include breakpoint($for-phone-only) {


      }
      @include breakpoint($for-tablet-portrait-up) {


      }
      @include breakpoint($for-tablet-landscape-up) {
        &:not(:last-child) {
          margin-bottom: .25rem;
        }


      }
      @include breakpoint($for-desktop-up) {
        &:not(:last-child) {
          margin-bottom: .25rem;
        }


      }
      @include breakpoint($for-big-desktop-up) {
        &:not(:last-child) {
          margin-bottom: .25rem;
        }

      }
    }

    &-info {
      @include breakpoint($for-phone-only) {
        display: none;
      }
      @include breakpoint($for-tablet-portrait-up) {
        display: none;
      }
      @include breakpoint($for-tablet-landscape-up) {
        display: none;
      }
      @include breakpoint($for-desktop-up) {
        width: 25rem;
        display: block;
        padding: 1rem 1.5rem;
        margin: -1rem;

      }
      @include breakpoint($for-big-desktop-up) {
        display: block;
        width: 35rem;
        padding: 1rem 1.5rem;
        margin: -1rem;
      }
    }
  }

  &__small {
    @include breakpoint($for-phone-only) {
      display: none;
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
      display: flex;
      flex-flow: row nowrap;
      margin-left: 1.5rem;
    }
    @include breakpoint($for-desktop-up) {
      display: flex;
      flex-flow: row nowrap;
      margin-left: 3rem;
    }
    @include breakpoint($for-big-desktop-up) {
      display: flex;
      flex-flow: row nowrap;
      margin-left: 3rem;
    }

    &__item {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {
      }

      &-link {
        margin-right: 1rem;
        @include breakpoint($for-phone-only) {
        }
        @include breakpoint($for-tablet-portrait-up) {
        }
        @include breakpoint($for-tablet-landscape-up) {
        }
        @include breakpoint($for-desktop-up) {
        }
        @include breakpoint($for-big-desktop-up) {
        }
      }
    }
  }
}