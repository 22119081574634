.m-contacts-module {
  @include breakpoint ($for-phone-only) {
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: .75rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
  }
  @include breakpoint ($for-tablet-landscape-up) {
    background-color: transparent;
    box-shadow: none;
  }
  @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
    background-color: transparent;
    box-shadow: none;
  }
  &__title {
    color: $color-text;
    font-weight: 600;
    @include breakpoint ($for-phone-only) {
      font-size: 1.2em;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      font-size: 1.2em;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      font-size: 1.2em;
    }
    @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
      font-size: 1.2em;
    }
  }
  &__address {
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {

    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up, $for-big-desktop-up) {

    }
  }
  &__phone {
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {

    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up, $for-big-desktop-up) {

    }
  }
  &__email {
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {

    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up, $for-big-desktop-up) {

    }
  }
  &__hour {
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {

    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up, $for-big-desktop-up) {

    }
  }
}