.m-victories {
  &__item {
    &-icon {
      border-radius: .5rem;
      border: 1px solid $color-border-tags;
      box-shadow: 0 0 0 2px $color-shadow-tags;
      background-color: white;
    }

    a {
      border: 0;

      &:visited, &:active, &:focus {
        border: 0;
      }

      &:hover {
        border: 0;
      }
    }

    &-text {
      font-size: .75rem;
      border: 1px solid transparent;
      color:$color-text;
      line-height: 1.1rem;

      &:visited {
        color: $color-text-light;
        border-bottom: 1px solid $color-text-underline-light;
      }

      &:hover, &:focus {
        color: $color-text-orange-light;
        border-bottom: 1px solid $color-text-orange-underline-light;
      }
    }
  }
}