table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1.8rem;
  border-collapse: collapse;
  thead {
    background-color: $color-bg-mobile;
    tr {
      th,td {
        padding: .5rem;
        line-height: 1.35em;
        vertical-align: bottom;
        border: 1px solid $color-border;
        font-weight: 600;
        text-align: left;
        @include breakpoint ($for-phone-only) {
          word-break: break-all;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          word-break: break-all;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          word-break: normal;
        }
        @include breakpoint ($for-desktop-up) {
          word-break: normal;
        }
        @include breakpoint ($for-big-desktop-up) {
          word-break: normal;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        padding: .5rem;
        line-height: 1.35em;
        vertical-align: top;
        border: 1px solid $color-border-light;
        @include breakpoint ($for-phone-only) {
          word-break: break-all;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          word-break: break-all;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          word-break: normal;
        }
        @include breakpoint ($for-desktop-up) {
          word-break: normal;
        }
        @include breakpoint ($for-big-desktop-up) {
          word-break: normal;
        }

      }
      &:hover, &:focus {
        background-color: lighten($color-bg-mobile, 5%);
        cursor: pointer;
      }
      .head {
        text-align: center;
        background-color: $color-bg-mobile;
      }
    }
  }
}

table.row_fixed {
  table-layout: fixed;
}

table {
  .form_type {
    text-align: center;
  }
  .center {
    text-align: center;
  }
}

.tableWordBreak {
  tr {
    td {
      word-wrap: break-word;
      word-break: break-all;
    }
  }
}