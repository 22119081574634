.ec-save-continue {
    top: 21%;
}

.right-btn-save__ec {
    white-space: nowrap;
    right: -167px;
}

.bg_layer {
    position: absolute;
    z-index: 15;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    pointer-events: none;
}
.modal-ec-banner__preloader {
    position: fixed;
    z-index: 20000;
    top: 20%;
    left: 50%;
    height: 200px;
}
