.g-number_of_sets {
  display: flex;
  flex-flow: row nowrap;
  @include breakpoint ($for-phone-only) {
    margin: .3rem  0;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    margin: .3rem  0;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    margin: .3rem 0;
  }
  @include breakpoint ($for-desktop-up) {
    margin: .3rem  0;
  }
  @include breakpoint ($for-big-desktop-up) {
    margin: .3rem 0;
  }
  &__counter{
    margin-right: .3rem;
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up) {

    }
    @include breakpoint ($for-big-desktop-up) {

    }
    &-all {
      @include breakpoint ($for-phone-only) {

      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {

      }
      @include breakpoint ($for-desktop-up) {

      }
      @include breakpoint ($for-big-desktop-up) {

      }
    }
    &-place {
      @include breakpoint ($for-phone-only) {

      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {

      }
      @include breakpoint ($for-desktop-up) {

      }
      @include breakpoint ($for-big-desktop-up) {

      }
    }
  }
  &__funding {
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up) {

    }
    @include breakpoint ($for-big-desktop-up) {

    }
  }
}