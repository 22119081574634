.g-button-navigation {
  &-mobile{
    align-items: center;
    justify-content: center;
    position: fixed;
    right: .5rem;
    bottom: .5rem;
    width: 2rem;
    height: 2rem;
    padding: .5rem;
    @include breakpoint ($for-phone-only) {
        display: flex;
    }
    @include breakpoint ($for-tablet-portrait-up) {
        display: flex;
    }
    @include breakpoint ($for-tablet-landscape-up) {
        display: none;
    }
    .icon-menu, .icon-close {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
