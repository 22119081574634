.g-banner {
  position: relative;
  @include breakpoint($for-phone-only) {
    height: 4rem;
    margin: .5rem .25rem 0 .25rem;
    padding: 1rem;
  }
  @include breakpoint($for-tablet-portrait-up) {
    height: 4rem;
    margin: .5rem .25rem 0 .25rem;
    padding: 1rem;
  }
  @include breakpoint($for-tablet-landscape-up) {
    height: auto;
    margin: 0;
    padding: 0 1rem;
  }
  @include breakpoint($for-desktop-up) {
    margin-top: 0;
    padding: 0;
    height: auto;
  }

  &__home {
    @include breakpoint($for-phone-only) {
      padding: 1rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      padding: 1rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      padding:  1rem 0;
    }
    @include breakpoint($for-desktop-up) {
      padding: 1rem 0;
    }
  }

  .owl-carousel .owl-item {
    @include breakpoint($for-phone-only) {
      height: max-content !important;
    }
    @include breakpoint($for-tablet-portrait-up) {
      height: max-content !important;
    }
    @include breakpoint($for-tablet-landscape-up) {
      height: max-content !important;
    }
  }

  &__preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -.5rem;
    margin-top: -.5rem;
    width: 2em;
    height: 2em;

    @include breakpoint($for-phone-only) {
      display: none;
    }
    @include breakpoint($for-tablet-portrait-up) {
      display: block;
    }
    @include breakpoint($for-tablet-landscape-up) {
      display: block;
    }
    @include breakpoint($for-desktop-up) {
      display: none;
    }
  }

  &__list {
    @include breakpoint($for-phone-only) {
      height: 6.76rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      height: 6.76rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      height: auto;
    }
    @include breakpoint($for-desktop-up) {
      height: auto;
      box-sizing: border-box;
      padding: 1.5rem;
    }

    &-item {
      position: relative;
      @include breakpoint($for-phone-only) {
        min-height: 100%;
      }
      @include breakpoint($for-tablet-portrait-up) {
        min-height: 100%;
        display: inline-block;
      }
      @include breakpoint($for-tablet-landscape-up) {
        min-height: 100%;
        margin-top: 1.5rem;
        display: block;
      }
      @include breakpoint($for-desktop-up) {
        min-height: auto;
        margin-top: 1.5rem;
        display: block;
      }

      &-top {
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {
          padding: 2rem 1rem;
        }
        @include breakpoint($for-desktop-up) {
          margin: -1.5rem -1.5rem 0 -1.5rem;
          padding: 1.5rem;
        }
      }

      &-title {
        padding-bottom: .1665em;
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
        @include breakpoint($for-desktop-up) {

        }

        &-top {
          @include breakpoint($for-phone-only) {

          }
          @include breakpoint($for-tablet-portrait-up) {

          }
          @include breakpoint($for-tablet-landscape-up) {

          }
          @include breakpoint($for-desktop-up) {

          }
        }
      }

      &-text {
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {
          margin-top: .25rem;
        }
        @include breakpoint($for-desktop-up) {
          margin-top: .25rem;
        }

        &-top {
          margin-top: .25rem;
          @include breakpoint($for-phone-only) {

          }
          @include breakpoint($for-tablet-portrait-up) {

          }
          @include breakpoint($for-tablet-landscape-up) {

          }
          @include breakpoint($for-desktop-up) {

          }
        }
      }

      &-img_1 {
        @include breakpoint($for-phone-only) {
          display: none;
        }
        @include breakpoint($for-tablet-portrait-up) {
          width: 10rem;
          height: 7rem;
          position: absolute;
          bottom: -25px;
          right: -16px;
        }
        @include breakpoint($for-tablet-landscape-up) {
          width: 10rem;
          height: 7rem;
          position: absolute;
          bottom: -25px;
          right: -16px;
        }
        @include breakpoint($for-desktop-up) {
          width: 10rem;
          height: 7rem;
          position: absolute;
          bottom: -25px;
          right: -16px;
        }
      }

      &-attention {
        position: absolute;
        @include breakpoint($for-phone-only) {
          top: 0;
          left: 0;
          width: 100%;
          height: .25rem;
        }
        @include breakpoint($for-tablet-portrait-up) {
          top: 0;
          left: 0;
          width: .25rem;
          height: 100%;
        }
        @include breakpoint($for-tablet-landscape-up) {
          top: 0;
          left: 0;
          width: .25rem;
          height: 100%;
        }
        @include breakpoint($for-desktop-up) {
          top: 0;
          left: -1rem;
          width: .25rem;
          height: 100%;
        }
      }
    }
  }
}