.g-indicator {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {
    display: flex;
    flex-flow: row nowrap;
  }
  &__wrapper {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
      width: 100%;
    }
  }

  &-list {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
      margin: 1rem 0;
    }
    &__item {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
        padding: .5rem 1rem .5rem 2rem;
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: 1.5rem;
        &:before {
          display: inline-block;
          width: 1.5rem;
          height: auto;
          margin-right: .3rem;
        }
      }
    }
  }
  &__name {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
      margin-right: 1rem;
      flex: 1 0;
    }
  }
  &__info {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
      width: 8rem;
    }
    &-status {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
    }
    &-score {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
    }
    &-danger {
      @include breakpoint ($for-phone-only) {
        margin-top: 1rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        margin-top: 1rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        margin-top: 1rem;
      }
      @include breakpoint ($for-desktop-up) {
        margin-top: 1rem;
      }
    }
  }
  &-moderator {
    position: relative;
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    &__tip {
      @include breakpoint ($for-phone-only) {
        padding: .5rem;
        width: 100%;
        box-sizing: border-box;
        margin-top: .5rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        padding: .5rem;
        width: 100%;
        box-sizing: border-box;
        margin-top: .5rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        padding: .5rem;
        width: 100%;
        box-sizing: border-box;
        margin-top: .5rem;

      }
      @include breakpoint ($for-desktop-up) {
        padding: .5rem;
        width: 100%;
        box-sizing: border-box;
        margin-top: .5rem;
      }
      &-photo {
        @include breakpoint ($for-phone-only) {
          width: 4rem;
          height: 4rem;
          margin-right: 2rem;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          width: 4rem;
          height: 4rem;
          margin-right: 2rem;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          width: 4rem;
          height: 4rem;
          margin-right: 2rem;

        }
        @include breakpoint ($for-desktop-up) {
          width: 4rem;
          height: 4rem;
          margin-right: 1rem;

        }
        img {
          width: 100%;
        }
      }
      &-name {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        
        }
        @include breakpoint ($for-desktop-up) {
          
        }
      }
      &-post {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
         
        }
        @include breakpoint ($for-desktop-up) {
         
        }
      }
      &-success {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
          
        }
        @include breakpoint ($for-desktop-up) {
          
        }
      }
      &-static {
        @include breakpoint ($for-phone-only) {

        }
        @include breakpoint ($for-tablet-portrait-up) {

        }
        @include breakpoint ($for-tablet-landscape-up) {


        }
        @include breakpoint ($for-desktop-up) {

        }
      }
    }
  }
}