.m-form {
  &__item-box {
  }

  &__item-input {
    font-weight: 600;
    color: $color-text;
    outline: none;
    @include breakpoint($for-phone-only) {
      border: 1px solid #B2B2B2;
      border-radius: .625rem;
      box-sizing: border-box;
      box-shadow: 0 0 0 1px rgba(224, 233, 254, 0.6);
    }
    @include breakpoint($for-tablet-portrait-up) {
      border: 1px solid #B2B2B2;
      border-radius: .625rem;
      box-sizing: border-box;
      box-shadow: 0 0 0 1px rgba(224, 233, 254, 0.6);
    }
    @include breakpoint($for-tablet-landscape-up) {
      border: 1px solid #B2B2B2;
      border-radius: .625rem;
      box-sizing: border-box;
      box-shadow: 0 0 0 1px rgba(224, 233, 254, 0.6);
    }

    &:-webkit-autofill {
      box-shadow: 0 0 0 1px #BACDFC;

      & ~ .m-form__item-label {
        transition: 0.2s;
        font-weight: 600;
        font-size: .625rem;
      }


    }


    &::placeholder {
      color: transparent;
    }

    &:placeholder-shown {

      & ~ .m-form__item-label {
        font-size: .875rem;
        cursor: text;
        font-weight: 600;
      }

      .m-form__item__error {
        border-color: $color-error;
        box-shadow: 0 0 0 1px $color-error-light;
      }
    }

    &:focus {
      box-shadow: 0 0 0 1px #BACDFC;

      & ~ .m-form__item-label {
        transition: 0.2s;
        font-weight: 600;
        font-size: .625rem;
      }


    }

    &__error {
      border-color: $color-error;
      box-shadow: 0 0 0 1px $color-error-light;

      &:-webkit-autofill {
        border-color: $color-error;
        box-shadow: 0 0 0 1px $color-error-light;
      }

      &:focus {
        border-color: $color-error;
        box-shadow: 0 0 0 1px $color-error-light;
      }
    }


  }

  &__item-label {
    color: #8989A0;
    font-weight: 600;
    font-size: .625rem;
    transition: 0.2s;

    &__error {
      color: $color-text-error;
    }
  }

  &__item-error {
    font-size: .625rem;
    color: $color-text-error;
  }
}