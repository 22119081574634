.g-navigation-window {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: none;
  z-index: 10000;

  &__list-actions {
    @include breakpoint($for-phone-only) {
      padding: 1rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      align-items: center;
    }
    @include breakpoint($for-tablet-portrait-up) {
      padding: 1rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      align-items: center;
    }
    @include breakpoint($for-tablet-landscape-up) {
      display: none;
    }
    @include breakpoint($for-desktop-up) {
      display: none;
    }

    &__item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      align-items: center;

      .g-action {
        width: 1.7rem;
        height: 1.7rem;
      }

      .icon-menu {
        width: 1.7rem;
        height: 1.7rem;
      }
    }
  }

  &-content {
    @include breakpoint($for-phone-only) {
      padding: 1rem;
    }

    @include breakpoint($for-tablet-portrait-up) {
      padding: 1rem;
    }

    @include breakpoint($for-tablet-landscape-up) {
      padding: .5rem;
      width: 30%;
    }

    @include breakpoint($for-desktop-up) {
      padding: .5rem;
      width: 30%;
    }

    &-bg {
      position: absolute;
      @include breakpoint($for-phone-only) {
        display: none;
      }

      @include breakpoint($for-tablet-portrait-up) {
        display: none;
      }

      @include breakpoint($for-tablet-landscape-up) {
        right: 0;
        bottom: 40%;
        display: block;
      }

      @include breakpoint($for-desktop-up) {
        display: block;
        right: 0;
        bottom: 40%;
      }
    }

    &__wrapper {
      position: relative;
      @include breakpoint($for-phone-only) {
        width: $small;
        height: calc(100vh - 8rem);
      }
      @include breakpoint($for-tablet-portrait-up) {
        width: $small;
        height: calc(100vh - 8rem);

      }
      @include breakpoint($for-tablet-landscape-up) {
        width: $small;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-flow: row nowrap;
      }
      @include breakpoint($for-desktop-up) {
        width: $middle;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-flow: row nowrap;
      }
      @include breakpoint($for-big-desktop-up) {
        width: $big;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-flow: row nowrap;
      }
    }

    &__title {
      @include breakpoint($for-phone-only) {
        display: none;
      }

      @include breakpoint($for-tablet-portrait-up) {
        display: none;
      }

      @include breakpoint($for-tablet-landscape-up) {
        display: block;
        margin-bottom: 2rem;
      }

      @include breakpoint($for-desktop-up) {
        display: block;
        margin-bottom: 2rem;
      }
    }

    &__item {
      display: none;

      .g-navigation__hot-links {

      }

      &-active {
        display: block;
        width: 100%;
        //height: calc(100vh - 10rem);
        //overflow-y: scroll;
      }
      &-scroll{

      }
    }

    &__iteresting-menu {
      @include breakpoint($for-phone-only) {
        display: none;
      }

      @include breakpoint($for-tablet-portrait-up) {
        display: none;
      }

      @include breakpoint($for-tablet-landscape-up) {
        display: block;
        padding: .5rem;
      }

      @include breakpoint($for-desktop-up) {
        display: block;
        padding: .5rem;
      }

      &__item {
        @include breakpoint($for-phone-only) {
        }

        @include breakpoint($for-tablet-portrait-up) {
        }

        @include breakpoint($for-tablet-landscape-up) {
          margin-bottom: .5rem;
          font-size: .9rem;
        }

        @include breakpoint($for-desktop-up) {
          margin-bottom: .5rem;
          font-size: .9rem;
        }
      }

      &__title {
        @include breakpoint($for-phone-only) {
        }

        @include breakpoint($for-tablet-portrait-up) {
        }

        @include breakpoint($for-tablet-landscape-up) {
          margin-bottom: 2rem;
        }

        @include breakpoint($for-desktop-up) {
          margin-bottom: 2rem;
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    @include breakpoint($for-phone-only) {
      width: auto;
      padding: 1rem .5rem 1rem .5rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      width: auto;
      padding: 1rem .5rem 1rem .5rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      width: auto;
      padding: 1rem .5rem 1rem .5rem;
    }
    @include breakpoint($for-desktop-up) {
      width: $middle;
      margin: 0 auto;
      padding: 1.5rem 0;
    }
    @include breakpoint($for-big-desktop-up) {
      width: $big;
      margin: 0 auto;
      padding: 1.5rem 0;
    }

    &-logo {
      @include breakpoint($for-phone-only) {
      }

      @include breakpoint($for-tablet-portrait-up) {
      }

      @include breakpoint($for-tablet-landscape-up) {
        display: none;
      }

      @include breakpoint($for-desktop-up) {
        display: none;
      }
      margin-right: .5rem;
      width: 2rem;
      height: 2rem;

      a {
        width: 100%;
        display: inline-block;
      }

    }

    &-title {
      display: none;
      flex: 1 0;

      &-active {
        @include breakpoint($for-phone-only) {
          display: block;
        }

        @include breakpoint($for-tablet-portrait-up) {
          display: block;
        }

        @include breakpoint($for-tablet-landscape-up) {
          display: none;
        }

        @include breakpoint($for-desktop-up) {
          display: none;
        }
      }
    }

    .icon-close {
      width: 1rem;
      height: 1rem;
      @include breakpoint($for-phone-only) {
      }

      @include breakpoint($for-tablet-portrait-up) {
      }

      @include breakpoint($for-tablet-landscape-up) {

      }

      @include breakpoint($for-desktop-up) {

      }
    }
  }
}

@include breakpoint($for-phone-only) {
}

@include breakpoint($for-tablet-portrait-up) {
}

@include breakpoint($for-tablet-landscape-up) {
}

@include breakpoint($for-desktop-up) {
}