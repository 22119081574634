.g-modal-window {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
    position: absolute;
    top: 0;
    bottom: inherit;
    display: none;
    flex-flow: column;
    min-width: 18rem;
    width: 100%;
    max-width: 100%;
  }

  &__position-right {
    right: .8rem;
  }
  &__position-left {
    left: 0;
  }
  &__title {
    @include breakpoint ($for-phone-only) {
      display: none;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      display: none;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      display: block;
      padding: .7rem .7rem .5rem .7rem;
      text-align: center;
    }

  }
  &__close {
    @include breakpoint ($for-phone-only) {
      display: none;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      display: none;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      display: block;
      position: absolute;
      top: 0rem;
      right: 0;
      width: 1rem;
      height: 1rem;
      padding: 1rem .5rem 0 0;
    }
    .icon-close {
      width: .8em;
    }
  }
  &__content {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
  }
}