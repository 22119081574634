.m-person-main-info {
  z-index: 50;
  @include breakpoint($for-phone-only) {
  }
  @include breakpoint($for-tablet-portrait-up) {
  }
  @include breakpoint($for-tablet-landscape-up) {
  }
  @include breakpoint($for-desktop-up) {
  }
  @include breakpoint($for-big-desktop-up) {

  }

  &__item {
    font-size: .875rem;
    border-bottom: 1px solid $color-link-underline;
    text-decoration: none;
    padding-bottom: .25rem;
    line-height: 1.7rem;
    @include link-colors($color-link-normal, $color-link-hover, $color-link-active, $color-link-visited, $color-link-focus);

    &:hover, &:focus {
      border-color: $color-text-orange-underline-light;
      cursor: pointer;
    }

    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {

    }

    &-active {
      @include link-colors($color-link-hover, $color-link-hover, $color-link-hover, $color-link-hover, $color-link-hover);
      border-color: $color-text-orange-underline-light;
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {

      }
      @include breakpoint($for-big-desktop-up) {

      }
    }
  }

  &__content {
    @include breakpoint($for-phone-only) {
      box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
      border-radius: .75rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
      border-radius: .75rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      background-color: transparent;
      box-shadow: none;
    }
    @include breakpoint($for-desktop-up) {
      background-color: transparent;
      box-shadow: none;
    }
    @include breakpoint($for-big-desktop-up) {
      background-color: transparent;
      box-shadow: none;
    }
  }
}