.m-jstabs {
  @include breakpoint($for-phone-only) {
    border-radius: .75rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
  }
  @include breakpoint($for-tablet-portrait-up) {
    border-radius: .75rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
  }
  @include breakpoint($for-tablet-landscape-up) {
    border-radius: 0;
    box-shadow: none;
  }
  @include breakpoint($for-desktop-up) {
    border-radius: 0;
    box-shadow: none;
  }
  @include breakpoint($for-big-desktop-up) {
    border-radius: 0;
    box-shadow: none;
  }

  &__labels {
    @include breakpoint($for-phone-only) {
      background-color: $color-bg-jstabs-labels;
      border-radius: .8rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      background-color: $color-bg-jstabs-labels;
      border-radius: .8rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      background-color: $color-bg-jstabs-labels;
      border-radius: .8rem;
    }
    @include breakpoint($for-desktop-up) {
      background-color: $color-bg-jstabs-labels;
      border-radius: .8rem;
    }

  }

  &__label {
    @include breakpoint($for-phone-only) {
      border-radius: .625rem;
      font-size: .825rem;
      line-height: .825rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      border-radius: .625rem;
      font-size: .825rem;
      line-height: .825rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      border-radius: .625rem;
      font-size: .825rem;
      line-height: .825rem;
    }
    @include breakpoint($for-desktop-up) {
      border-radius: .625rem;
      font-size: .825rem;
      line-height: .825rem;
    }

    &-active {
      background-color: $color-bg-jstabs-label-active;
      color: white;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__content {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {
    }
  }
}