.m-list-supporting-documentation {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {
  }
  &__item {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
      font-size: .9em;
    }
  }
}