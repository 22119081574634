.m-authorized-user {
  &__name {
    @include breakpoint($for-phone-only) {
      font-size: .75rem;
      z-index: 200;
    }
    @include breakpoint($for-tablet-portrait-up) {
      font-size: .75rem;
      z-index: 200;
    }
    @include breakpoint($for-tablet-landscape-up) {

    }

    &-button {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
        font-size: .75rem;
        border-radius: .625rem;
        border: 1px solid $color-border-auth;
        box-shadow: 0 0 0 1px $color-shadow-auth;
        z-index: 1000;
        background: white;
        &-icon {
          border-radius: .5rem;
          border: 1px solid $color-border-auth;
          background-color: #ECF1FF;
          overflow: hidden;
          img{
            object-fit: cover;
          }
        }
        a {
          border: 0;
          line-height: 1.3rem;
          font-size: .75rem;
        }

        &:hover {
          border: 1px solid $color-text-orange-light;
          box-shadow: 0 0 0 1px $color-text-orange-underline-light;

          .m-authorized-user__name-button-icon {
            border: 1px solid $color-text-orange-light;
          }
        }
      }
    }

    &-menu {
      font-size: .75rem;
      border-radius: .625rem;
      border: 1px solid $color-border-auth;
      box-shadow: 0 0 0 1px $color-shadow-auth;
      background: white;
      z-index: 999;

      a {
        border: 0;
        font-size: .75rem;
        line-height: 1rem;
      }
    }
  }


  &__list-user {
    @include breakpoint($for-phone-only) {
      font-size: 1rem;
      z-index: 200;
    }
    @include breakpoint($for-tablet-portrait-up) {
      font-size: 1rem;
      z-index: 200;
    }
    @include breakpoint($for-tablet-landscape-up) {

    }
  }

  &__logout {
    @include breakpoint($for-phone-only) {
      font-size: 1rem;
      z-index: 200;
    }
    @include breakpoint($for-tablet-portrait-up) {
      font-size: 1rem;
      z-index: 200;
    }
    @include breakpoint($for-tablet-landscape-up) {

    }
  }

  &__manual {
    @include breakpoint($for-phone-only) {
      font-size: 1rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      font-size: 1rem;
    }
    @include breakpoint($for-tablet-landscape-up) {

    }
  }
}
