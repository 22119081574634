.g-auth__link {
  a {
    padding: .5rem .75rem;
    display: inline-block;
  }
}

.g-form-auth {
  &__fields {
  }

  &__links {
    display: flex;
    justify-content: space-between;
    @include breakpoint($for-phone-only) {
      margin-bottom: 1rem;
      padding: 0;
    }
    @include breakpoint($for-tablet-portrait-up) {
      margin-bottom: 1rem;
      padding: 0;
    }
    @include breakpoint($for-tablet-landscape-up) {
      margin-top: .5rem;
      margin-bottom: 0;
      padding: 0 1rem;
    }
  }

}

