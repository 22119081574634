.g-person-main-info {
  @include breakpoint ($for-phone-only) {
    display: flex;
    flex-flow: column;
    padding: 0 .5rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    display: flex;
    flex-flow: column;
    padding: 0 .5rem;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    display: flex;
    flex-flow: row nowrap;
    padding: 0;
  }
  @include breakpoint ($for-desktop-up) {
    display: flex;
    flex-flow: row nowrap;
    padding: 0;
  }
  @include breakpoint ($for-big-desktop-up) {
    display: flex;
    flex-flow: row nowrap;
    padding: 0;
  }
  &__item {
    @include breakpoint ($for-phone-only) {
      padding-left: .5rem;
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: .7rem;
      }
    }
    @include breakpoint ($for-tablet-portrait-up) {
      padding-left: .5rem;
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: .7rem;
      }
    }
    @include breakpoint ($for-tablet-landscape-up) {
      padding-left: 0;
      &:not(:last-child) {
        margin-right: 1rem;
        margin-bottom: 0;
      }
    }
    @include breakpoint ($for-desktop-up) {
      padding-left: 0;
      &:not(:last-child) {
        margin-right: 1rem;
        margin-bottom: 0;
      }
    }
    @include breakpoint ($for-big-desktop-up) {
      padding-left: 0;
      &:not(:last-child) {
        margin-right: 1rem;
        margin-bottom: 0;
      }
    }

  }
  &__content {
    @include breakpoint ($for-phone-only) {
      margin: 1.5rem .25rem .5rem .25rem;
      padding: 1rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin: 1.5rem .25rem .5rem .25rem;
      padding: 1rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      padding: 0;
      margin: 0 0 1rem 0;
    }
    @include breakpoint ($for-desktop-up) {
      padding: 0;
      margin: 0 0 2rem 0;
    }
    @include breakpoint ($for-big-desktop-up) {
      padding: 0;
      margin: 0 0 2rem 0;
    }

      &-main {
        display: block;
      }
    }
  }