.min_list_info {
  margin-bottom: .5rem;
  line-height: 1.4em;
  strong {
    white-space: nowrap;
  }
}

.image_person {
  width: 15em;
  height: 15em;
  border-radius: 15em;
  overflow: hidden;
  margin: 0 auto;
  img {
    @include breakpoint ($for-phone-only) {
      width: 100%;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      width: 100%;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      width: 100% !important;
    }
    @include breakpoint ($for-desktop-up) {
      width: 100% !important;
    }
    @include breakpoint ($for-big-desktop-up) {
      width: 100% !important;
    }
  }
}

#ldap_bun {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
}

.info_for_memorandum {
  display: none;
}

.bg-warning {
  background-color: #fcf8e3;
  padding: 20px;
}

.bg-success {
  background-color: #dff0d8;
  padding: 20px;
}

.connection_error, .connection_success, .wait_connection {
  display: none;
}

i.fa.fa-spinner {
  margin-right: 10px;
}

.section, .wp {
  position: relative;
  border-radius: 5px;
  margin: 10px 0 !important;
  h3 {
    margin-bottom: 0;
  }
}

.wp {
  padding: 10px;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  input[type=checkbox] {
    width: 15px;
    height: 15px;
    margin-right: .3rem;
    &:hover {
      cursor: pointer;
    }
  }
}

.wp_error {
  border: 1px solid #ff5854;
}

.wp_ok {
  border: 1px solid #6EC06E;
}

.del_section {
  position: absolute;
  top: -.55rem;
  right: -.05rem;
  border-top-right-radius: .3rem;
}


.wait_rights {
  background-color: hsla(2, 64%, 58%, 0.2) !important;
}

.have_rights {
  background-color: hsla(240, 68%, 51%, 0.2) !important;
}

.lk_form {
  display: flex;
  flex-flow: row wrap;
  .lk_date {
    width: 75%;
    margin-right: 2%;
  }
  .lk_contact {
    width: 22%;
  }
  .lk_button {
    width: 100%;
  }
}

.section_constructor > span, .section_constructor > div > label {
  display: block;
  margin-bottom: -14px;
  padding: .3em;
}

.section_constructor > span.wait_rights {
  margin-bottom: 0;
}

.block_privilege {
  width: 56%;
  display: inline-block;
  vertical-align: top;
  .dropdown-menu {
    width: 1200px;
    li a {
      //width: 1170px;
      .text {
        width: 97%;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.block_privilege_help {
  width: 39%;
  display: inline-block;
  vertical-align: top;
  padding-left: 2rem;
}