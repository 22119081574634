.g-tag {
  margin-right: .4rem;
  @include breakpoint($for-phone-only) {

  }
  @include breakpoint($for-tablet-portrait-up) {

  }
  @include breakpoint($for-tablet-landscape-up) {
    &-body {
      padding: .25rem .5rem;
    }
    a {
      display: block;
      padding-bottom: 0;
    }
  }
}