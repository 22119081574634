.m-content-header {

  &__section {

    &__mobile-name {
      @include breakpoint($for-phone-only) {
        z-index: 2;
      }
      @include breakpoint($for-tablet-portrait-up) {
        z-index: 2;
      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {

      }
      @include breakpoint($for-big-desktop-up) {

      }

      &-logo {
        a{
          border-bottom: 0;
        }
      }

      &-title {
        font-size: .8rem;
        line-height: 1rem;
      }
    }

    &-direction {
      z-index: 50;
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up, $for-big-desktop-up) {

      }
    }

    &-name {

      font-family: 'Futura PT', sans-serif;
      @include breakpoint($for-phone-only) {
        font-size: 1.85rem;
        line-height: 1.85rem;
        z-index: 2;
      }
      @include breakpoint($for-tablet-portrait-up) {
        font-size: 1.85rem;
        line-height: 1.85rem;
        z-index: 2;
      }
      @include breakpoint($for-tablet-landscape-up) {
        z-index: 15;
        font-size: 1.875rem;
        line-height: 1.875rem;
      }
      @include breakpoint($for-desktop-up, $for-big-desktop-up) {
        z-index: 15;
        font-size: 1.875rem;
        line-height: 1.875rem;
      }


    }

    &__mobile-wrapper {
      background-color: #4134ad;

      &-close {
        .icon-close {
          fill: white;
        }
      }
    }

    &-info {
      @include breakpoint($for-phone-only) {
        z-index: 500;
        overflow-y: scroll;
        border-radius: .75rem;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
      }
      @include breakpoint($for-tablet-portrait-up) {
        z-index: 500;
        overflow-y: scroll;
        border-radius: .75rem;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
      }
      @include breakpoint($for-tablet-landscape-up) {
        background-color: transparent;
        overflow-y: inherit;
        z-index: auto;
        border-radius: 0;
        box-shadow: none;
      }
      @include breakpoint($for-desktop-up) {
        background-color: transparent;
        overflow-y: inherit;
        z-index: auto;
        border-radius: 0;
        box-shadow: none;
      }
      @include breakpoint($for-big-desktop-up) {
        background-color: transparent;
        overflow-y: inherit;
        z-index: auto;
        border-radius: 0;
        box-shadow: none;
      }

      &__more-menu {
        @include breakpoint($for-phone-only) {
          background-color: $color-green;
          color: white;
          border-radius: 0 0 .75rem .75rem;
          text-align: center;
        }
        @include breakpoint($for-tablet-portrait-up) {
          background-color: $color-green;
          color: white;
          border-radius: 0 0 .75rem .75rem;
          text-align: center;
        }
        @include breakpoint($for-tablet-landscape-up) {

        }
        @include breakpoint($for-desktop-up, $for-big-desktop-up) {

        }
      }

      &__more-menu__open {
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
        @include breakpoint($for-desktop-up, $for-big-desktop-up) {

        }

      }
    }


    &-nav {
      //z-index: 50;
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {
      }


    }

    &-child {
      z-index: 50;
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {
      }
    }

    &-icon {
      z-index: 40;
      cursor: pointer;
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up, $for-big-desktop-up) {

      }

      .m-icon_more_menu {
        cursor: pointer;
        @include breakpoint($for-phone-only) {
          fill: white;
        }
        @include breakpoint($for-tablet-portrait-up) {
          fill: white;
        }
        @include breakpoint($for-tablet-landscape-up) {
          fill: white;
        }
        @include breakpoint($for-desktop-up, $for-big-desktop-up) {
          fill: white;
        }
      }
    }

    &-img {
      box-shadow: 0 13px 15px -5px rgba(197, 189, 234, 0.7);
      @include breakpoint($for-phone-only) {
        //background: linear-gradient(360deg, rgba(0, 0, 0, 1) 46.31%, rgba(0, 0, 0, 0) 143.58%);
        border-radius: 1.25rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        //background: linear-gradient(360deg, rgba(0, 0, 0, 1) 46.31%, rgba(0, 0, 0, 0) 143.58%);
        border-radius: 1.25rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        //background: linear-gradient(360deg, rgba(0, 0, 0, 1) 46.31%, rgba(0, 0, 0, 0) 143.58%);
        border-radius: 1.875rem;
      }
      @include breakpoint($for-desktop-up) {
        //background: linear-gradient(360deg, rgba(0, 0, 0, 1) 46.31%, rgba(0, 0, 0, 0) 143.58%);
        border-radius: 1.875rem;
      }
      @include breakpoint($for-big-desktop-up) {
        //background: linear-gradient(360deg, rgba(0, 0, 0, 1) 46.31%, rgba(0, 0, 0, 0) 143.58%);
        border-radius: 1.75rem;

      }

      img {
        @include breakpoint($for-phone-only) {
          border-radius: 1.25rem;
        }
        @include breakpoint($for-tablet-portrait-up) {
          border-radius: 1.25rem;
        }
        @include breakpoint($for-tablet-landscape-up) {
          border-radius: 1.875rem;
        }
        @include breakpoint($for-desktop-up, $for-big-desktop-up) {
          border-radius: 1.875rem;
        }
      }
    }
  }

}