.m-logo{
  z-index: 200;
  border: 0;
  &:visited,&:active, &:focus, &:hover{
    border: 0;
  }
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {
  }
  @include breakpoint ($for-big-desktop-up) {
  }
}