.m-header-menu {
  @include breakpoint($for-phone-only) {
  }
  @include breakpoint($for-tablet-portrait-up) {
  }
  @include breakpoint($for-tablet-landscape-up) {
  }
  @include breakpoint($for-desktop-up) {
  }
  @include breakpoint($for-big-desktop-up) {
  }

  &__section {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {
    }

    &-wrapper {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {

      }
      @include breakpoint($for-big-desktop-up) {

      }
    }

    &-item {
      font-size: .625rem;
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {
      }

      a {
        font-size: .75rem;
        line-height: 1.2rem;
      }
    }

    &-info {
      font-size: .75rem;
      background-color: white;
      border-radius: 1.625rem;

      a {
        font-size: .75rem;
        line-height: 1rem;
      }
    }
  }

  &__small {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {
    }

    &__item {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {
      }

      &-link {
        font-size: .625rem;
        color: $color-text-grey-light;
        border-bottom: 1px solid $color-text-grey-underline-light;

        &:visited, &:active, &:focus {
          color: $color-text-grey-light;
        }

        &:hover {
          color: $color-text-orange-light;
        }

        @include breakpoint($for-phone-only) {
        }
        @include breakpoint($for-tablet-portrait-up) {
        }
        @include breakpoint($for-tablet-landscape-up) {
        }
        @include breakpoint($for-desktop-up) {
        }
        @include breakpoint($for-big-desktop-up) {
        }
      }
    }
  }
}