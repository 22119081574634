.g-celebrations {
  position: absolute;
  bottom: 0;
  left: 0;
  //display: none;
  &__8march {
    @include breakpoint($for-phone-only) {
      width: 4.5rem;
      position: absolute;
      bottom: -.25rem;
      left: -0.75rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      width: 4.5rem;
      position: absolute;
      bottom: -.25rem;
      left: -0.75rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      width: 7rem;
      position: absolute;
      bottom: -.5rem;
      left: -1rem;
    }
    @include breakpoint($for-desktop-up) {
      width: 7rem;
      position: absolute;
      bottom: -.5rem;
      left: -1rem;
    }
  }

  &_new_year {
    &__show {
      @include breakpoint($for-phone-only) {
        width: 30px;
        height: 17px;
        position: absolute;
        top: 0;
        right: 0.1rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        width: 30px;
        height: 17px;
        position: absolute;
        top: 0;
        right: 0.1rem;

      }
      @include breakpoint($for-tablet-landscape-up) {
        width: 52px;
        height: 29px;
        position: absolute;
        top: 0;
        right: 0.1rem;

      }
      @include breakpoint($for-desktop-up) {
        width: 52px;
        height: 29px;
        position: absolute;
        top: 0;
        right: 0.1rem;
      }
    }

    &__tree {
      @include breakpoint($for-phone-only) {
        width: 102px;
        height: 62px;
        position: absolute;
        bottom: 0;
        left: -1rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        width: 102px;
        height: 62px;
        position: absolute;
        bottom: 0;
        left: -1rem;

      }
      @include breakpoint($for-tablet-landscape-up) {
        width: 178px;
        height: 108px;
        position: absolute;
        bottom: 0;
        left: -2rem;
      }
      @include breakpoint($for-desktop-up) {
        width: 178px;
        height: 108px;
        position: absolute;
        bottom: 0;
        left: -2rem;
      }
    }

  }
}