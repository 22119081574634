.g-form {
  &__item-box {
    position: relative;
    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {
    }
  }

  &__item-input {
    @include breakpoint($for-phone-only) {
      height: 3rem;
      padding: 1.5rem .75rem .5rem .75rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      height: 3rem;
      padding: 1.5rem .75rem .5rem .75rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      height: 3rem;
      padding: 1.5rem .75rem .5rem .75rem;
    }

    &::placeholder {

    }

    &:-webkit-autofill {
      padding: 1.5rem .75rem .5rem .75rem;

      & ~ .g-form__item-label {
        top: .5rem;
        left: .75rem;
        display: block;
      }
    }

    &:placeholder-shown {
      padding: 1rem .75rem;

      & ~ .g-form__item-label {
        top: .9rem;
        left: .75rem;
      }
    }

    &:focus {
      padding: 1.5rem .75rem .5rem .75rem;

      & ~ .g-form__item-label {
        top: .5rem;
        left: .75rem;
        display: block;
      }
    }
  }

  &__item-label {
    position: absolute;
    top: .5rem;
    left: .75rem;
  }

  &__item-error {
    margin: .5rem 0 0 .5rem;
  }

  &__search {
    @include breakpoint($for-phone-only) {
      padding: 1rem .5rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      padding: 1rem .5rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      padding: 0;
    }

    &-btn {
      @include breakpoint($for-phone-only) {
        position: absolute;
        top: 0;
        right: .25rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        position: absolute;
        top: 0;
        right: .25rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        position: absolute;
        top: .25rem;
        right: .125rem;
      }
    }
  }
}

