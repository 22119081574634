.m-news-carousel {
  &__item {
    &-wrapper {
      //z-index: 2000;
      &-text {
        color: white;
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        &-title {
          z-index: 500;
          cursor: pointer;
          a {
            color: white;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: 'Century Gothic', sans-serif;
            &:hover, &:focus {
              text-decoration: underline;
            }
            &:visited {
              color: white;
            }
            @include breakpoint ($for-phone-only) {
            }
            @include breakpoint ($for-tablet-portrait-up) {
              font-size: 1.4em;
            }
            @include breakpoint ($for-tablet-landscape-up) {
              font-size: 1.8em;
            }
          }
        }
        &-paragraph {
          z-index: 500;
          @include breakpoint ($for-phone-only) {
          }
          @include breakpoint ($for-tablet-portrait-up) {
            font-size: .9em;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          @include breakpoint ($for-tablet-landscape-up) {
            font-size: 1em;
          }
          a{
            text-decoration: underline;
            color: white;
            &:hover, &:focus {
              text-decoration-color: #e6e6e6;
            }
            &:visited {
              color: white;
            }
          }
        }
      }
    }
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    &-img {
      img {
        opacity: .6;
      }
    }
  }
}