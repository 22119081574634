.m-person-auxiliary-info {
  @include breakpoint($for-phone-only) {
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: .75rem;
  }
  @include breakpoint($for-tablet-portrait-up) {
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: .75rem;
  }
  @include breakpoint($for-tablet-landscape-up) {
    background-color: transparent;
    box-shadow: none;
  }
  @include breakpoint($for-desktop-up) {
    background-color: transparent;
    box-shadow: none;
  }
  @include breakpoint($for-big-desktop-up) {
    background-color: transparent;
    box-shadow: none;
  }

  &__work {
    margin-top: 1rem;
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {

    }
  }

  &__grate {
    span {
      font-family: 'Futura PT', sans-serif;
      font-size: 1.1rem;
      font-weight: 600;
    }

    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {

    }
  }

  &__experience {
    span {
      font-family: 'Futura PT', sans-serif;
      font-weight: 600;
      font-size: 1.1rem;
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {

      }
    }

    @include breakpoint($for-phone-only) {
      font-size: .75rem;

    }
    @include breakpoint($for-tablet-portrait-up) {
      font-size: .75rem;

    }
    @include breakpoint($for-tablet-landscape-up) {
      font-size: .75rem;

    }
    @include breakpoint($for-desktop-up) {
      font-size: .875rem;

    }
    @include breakpoint($for-big-desktop-up) {
      font-size: .875rem;
    }
  }

  &__contacts {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {

    }

    &-address {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {

      }
    }

    &-phone {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {

      }
    }

    &-email {
      &:first-child {
        white-space: nowrap;
      }

      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {

      }
    }

    &-opening-hours {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {

      }
    }
  }
}