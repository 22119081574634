.m-vacancies {
  &__header-modules {
    @include breakpoint ($for-phone-only) {
      font-weight: 600;
      font-size: 1.5em;
      border-bottom: .01rem solid $color-border;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      font-weight: 600;
      font-size: 1.6em;
      border-bottom: .01rem solid $color-border;
    }
    @include breakpoint ($for-tablet-landscape-up) {

      font-weight: 600;
      font-size: 1.7em;
      box-shadow: none;
      border-bottom: none;
    }
    @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
      font-weight: 600;
      font-size: 1.7em;
      box-shadow: none;
      border-bottom: none;
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: .325rem;
        border-radius: .5rem;
        background-color: $color-dark-blue;
        margin-top: .25rem;
      }
    }
  }

}

.m-vacancy {
  @include breakpoint ($for-phone-only) {
    border-bottom: 1px solid $color-border;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
  }
  @include breakpoint ($for-tablet-portrait-up) {
    border-bottom: 1px solid $color-border;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
  }
  @include breakpoint ($for-tablet-landscape-up) {
    border-bottom: 0;
    box-shadow: none;
  }

  &__meta {
    z-index: 1;

    &-icon {
      border-radius: 4rem;
      overflow: hidden;
      object-fit: cover;
    }

    &-date {
      &-number {
        color: #512da8;
        font-size: 1.2em;
      }

      &-month-year {
        color: $color-text-grey;
      }
    }
  }

  &__content {
    &-title {
      color: $color-text;
      @include breakpoint ($for-phone-only) {
        font-size: 1.2em;
        line-height: 1.2em;
        font-weight: 700;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        font-size: 1.2em;
        font-weight: 700;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        font-size: 1.8em;
        font-weight: normal;
      }

      &:hover, &:hover {
        color: #673AB7;
      }
    }

    &-body {
    }

    &-gallery {
    }

    &-from {
      &__section {
        color: $color-text-grey;
        font-style: italic;
        font-size: .9em;

        &:hover, &:focus, &:visited {
          color: $color-text-grey;
        }
      }
    }

    &__name-company {
      @include breakpoint ($for-phone-only) {
        font-size: .75rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        font-size: .75rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        font-size: .75rem;
      }
    }

    &-place {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }

      &__region {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
          font-size: .75rem;
          color: $color-text-grey;
        }
      }

      &__city {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
          font-size: .75rem;
          color: $color-text-grey;
        }
      }

      &__address {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
          font-size: .75rem;
          color: $color-text-grey;
        }
      }
    }

    &__action {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }

      &-response {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
      }

      &-contacts {
        cursor: pointer;

        span {
          color: $color-link-normal;
          z-index: 100;
        }

        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }

        &-active {
          @include breakpoint ($for-phone-only) {
          }
          @include breakpoint ($for-tablet-portrait-up) {
          }
          @include breakpoint ($for-tablet-landscape-up) {


          }
        }
      }
    }
  }

  &__contacts {
    background-color: #ffffff;
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
      box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    }


    &-fio {
      font-weight: bold;
      font-size: 1.1em;
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
    }

    &-phone {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
    }

    &-email {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
    }
  }

  &-detail {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }

    &-company {

      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
        background-color: #F2F5F5;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
      }

      &__icon {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }

        &-img {

          @include breakpoint ($for-phone-only) {
          }
          @include breakpoint ($for-tablet-portrait-up) {
          }
          @include breakpoint ($for-tablet-landscape-up) {

          }
        }
      }

      &_meta {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {

        }
      }
    }

    .m-vacancy__content-title {
      &:hover, &:focus {
        color: inherit;
      }

      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
        font-size: 2.5em;
      }
    }
  }
}

.m-img-icon {
  &:hover {
    cursor: pointer;
  }
}