.g-passport-profile {
  width: 100%;
  &__content {
    display: none;
    @include breakpoint ($for-phone-only) {
      padding-top: .5rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      padding-top: .5rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      padding-top: 0;
      margin-top: 1.3rem;
    }
    @include breakpoint ($for-desktop-up) {
      padding-top: 0;
      margin-top: 1.3rem;
    }
    @include breakpoint ($for-big-desktop-up) {
      padding-top: 0;
      margin-top: 1.5rem;
    }
  }
  input[id^='g-passport-profile-tab'] {
    display: none;
  }
  $list: 1 2 3 4 5 6 7;
  @each $i in $list {
    #g-passport-profile-tab-#{$i}:checked ~ #g-passport-profile__content-tab-#{$i} {
      display: block;
    }
  }
  input[id^='g-passport-profile-tab'] + label {
    @include breakpoint ($for-phone-only) {
      padding-bottom: .1rem;
      display: block;
      margin-bottom: .7rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      padding-bottom: .1rem;
      display: inline-block;
      margin-bottom: .7rem;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    @include breakpoint ($for-tablet-landscape-up) {
      padding-bottom: .5rem;
      display: inline-block;
      margin-bottom: 0;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    @include breakpoint ($for-desktop-up) {
      padding-bottom: .5rem;
      display: inline-block;
      margin-bottom: 0;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    @include breakpoint ($for-big-desktop-up) {
      display: inline-block;
      padding-bottom: .5rem;
      margin-bottom: 0;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}