.g-training-course {
  @include breakpoint ($for-phone-only) {
    padding: .5rem 0;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    padding: .5rem 0;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    display: flex;
    flex-flow: row nowrap;
    padding: 1rem;
    margin-left: -1rem;
    &:hover, &:focus {
      margin-left: -2.1rem;
      padding: 1rem 1rem 1rem 2rem;;
    }
  }
  @include breakpoint ($for-desktop-up) {
    display: flex;
    flex-flow: row nowrap;
    padding: 1rem;
    margin-left: -1rem;
    &:hover, &:focus {
      margin-left: -2.1rem;
      padding: 1rem 1rem 1rem 2rem;;
    }
  }
  @include breakpoint ($for-big-desktop-up) {
    display: flex;
    flex-flow: row nowrap;
    padding: 1rem;
    margin-left: -1rem;
    &:hover, &:focus {
      margin-left: -2.1rem;
      padding: 1rem 1rem 1rem 2rem;
    }
  }

  &__left {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
      margin-left: -1rem;
    }
    @include breakpoint ($for-big-desktop-up) {
      margin-left: -1rem;
    }
  }

  &__empty {
    margin-left: -2.02rem;
    padding: 1rem 1rem 1rem 2rem;
  }

  &__info {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
      flex: 1 0;
    }
    @include breakpoint ($for-desktop-up) {
      flex: 1 0;
    }
    @include breakpoint ($for-big-desktop-up) {
      flex: 1 0;
    }

    &-name {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
        max-width: 40rem;
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
        max-width: 56rem;
      }
    }

    &-other {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }

    }

    &__status {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }

    &__language {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
  }

  &__semester {
    @include breakpoint ($for-phone-only) {
      margin: .5rem 0;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin: .5rem 0;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin: 0 2rem;
    }
    @include breakpoint ($for-desktop-up) {
      margin: 0 2rem;
    }
    @include breakpoint ($for-big-desktop-up) {
      margin: 0 2rem;
    }
  }

  &__docs {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
      text-align: right;
    }
    @include breakpoint ($for-desktop-up) {
      text-align: right;
    }
    @include breakpoint ($for-big-desktop-up) {
      text-align: right;
    }
  }

  &-title {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
      display: flex;
      justify-content: space-between;
      flex-flow: row nowrap;
      padding: 0 1rem .5rem 0;
      margin-top: 1rem;

    }
    @include breakpoint ($for-desktop-up) {
      display: flex;
      justify-content: space-between;
      flex-flow: row nowrap;
      padding: 0 1rem .5rem 0;
      margin-top: 1rem;

    }
    @include breakpoint ($for-big-desktop-up) {
      display: flex;
      justify-content: space-between;
      flex-flow: row nowrap;
      padding: 0 1rem .5rem 0;
      margin-top: 1rem;
    }

    &__name {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {

      }
      @include breakpoint ($for-desktop-up) {

      }
      @include breakpoint ($for-big-desktop-up) {

      }
    }

    &__doc {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {

      }
      @include breakpoint ($for-desktop-up) {

      }
      @include breakpoint ($for-big-desktop-up) {

      }
    }
  }

}