.g-header-all-news {

  @include breakpoint($for-phone-only) {

  }
  @include breakpoint($for-tablet-portrait-up) {

  }
  @include breakpoint($for-tablet-landscape-up) {

  }
  @include breakpoint($for-desktop-up) {

  }
  @include breakpoint($for-big-desktop-up) {

  }

  &__wrapper {

    @include breakpoint($for-phone-only) {
      width: $small;
      display: flex;
      flex-flow: column;
      box-sizing: border-box;
    }
    @include breakpoint($for-tablet-portrait-up) {
      width: $small;
      display: flex;
      flex-flow: column;
      box-sizing: border-box;
    }
    @include breakpoint($for-tablet-landscape-up) {
      margin: 0 auto 4rem auto;
      width: $small;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
    }
    @include breakpoint($for-desktop-up) {
      margin: 0 auto 4rem auto;
      width: $middle;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
    }
    @include breakpoint($for-big-desktop-up) {
      margin: 0 auto 4rem auto;
      width: $big;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
    }
  }

  &__img {
    @include breakpoint($for-phone-only) {
      height: 6.25rem;
      flex-shrink: 0;
      margin: 0 .25rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      height: 6.25rem;
      flex-shrink: 0;
      margin: 0 .25rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      width: 12.25rem;
      height: 12.25rem;
      flex-shrink: 0;
      margin: 0 2.5rem 0 0;

    }
    @include breakpoint($for-desktop-up) {
      width: 12.25rem;
      height: 12.25rem;
      flex-shrink: 0;
      margin: 0 2.5rem 0 0;
    }
    @include breakpoint($for-big-desktop-up) {
      display: block;
      width: 12.25rem;
      height: 12.25rem;
      flex-shrink: 0;
      margin: 0 2.5rem 0 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    @include breakpoint($for-phone-only) {
      margin: 1.5rem .25rem .5rem .75rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      margin: 1.5rem .25rem .5rem .75rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      margin: 0;
    }
    @include breakpoint($for-desktop-up, $for-big-desktop-up) {
      margin: 0;
    }

    &-title {

      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {
        margin-left: -.25rem;
      }
    }

    &-subtitle {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {
        margin-top: .5rem;
      }
    }

    &-search {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {
        margin-top: 1rem;
      }

      &__input {

        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
      }

      &__btn {
        img {
          width: 1rem !important;
          height: 1rem !important;
        }

        @include breakpoint($for-phone-only) {
          width: 1rem;
        }
        @include breakpoint($for-tablet-portrait-up) {
          width: 1rem;
        }
        @include breakpoint($for-tablet-landscape-up) {
          width: 1rem;
        }
      }
    }
  }
}