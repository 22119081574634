.result-search {
  margin-top: 3.5rem;

  .group-search {
    margin: 1rem 0;

    .body-result-search {
      margin: 0 0 2rem 1rem;
      border-left: 1px solid $color-border;
      padding: 0 0 0 1rem;
      clear: both;
    }
  }
}

.b-loader__wrapper {
  tr {
    td {
      border: 0 !important;
    }

    &:hover {
      background: transparent !important;
    }
  }
}

.ya-site-form__form, yass-div, b-loader__ext-wrapper {
  table {
    tr {
      td {
        border: 0 !important;
      }

      &:hover {
        background: transparent !important;
      }
    }
  }
}

.ya-site-form__form{
  font-size: 1em !important;
  color: $color-text !important;
}
.ya-site-form__input-text {
  font-size: 1em !important;
  display: block !important;
  width: 100% !important;
  //height: 2.6rem;
  padding: .75rem .9rem !important;
  color: #555 !important;
  margin-bottom: .5rem !important;
  background-color: #fff !important;
  background-image: none !important;
  border: 1px solid $color-border !important;
  border-radius: 4px !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075) !important;
}

.ya-site-form__submit {
  display: inline-block!important;
  box-sizing: border-box!important;
  margin: .25rem 0!important;
  font-family: 'Futura PT Book', sans-serif!important;
  border-width: 0!important;
  border-style: solid!important;
  background: #4c73ec;
  border-radius: .625rem!important;
  padding: .5rem 1.5rem!important;
  line-height: inherit!important;
  font-size: 1em!important;
  color: white !important;

}

#ya-site-results .b-pager__current {
  color: #232323 !important;
  border-radius: 25px !important;
  width: 30px !important;
  height: 30px !important;
  font-size: 0.8em !important;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  /* border-color: #5cb85c; */
  border: 2px solid #5CB85D !important;
  background-color: transparent !important;

  &:hover {
    cursor: pointer;
    color: white !important;
    background-color: #5CB85D !important;
  }
}

.b-pager__pages {
  .b-link {
    border-radius: 25px !important;
    width: 30px !important;
    height: 30px !important;
    font-size: 0.8em !important;
    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
    /* border-color: #5cb85c; */
    border: 2px solid #c1c1c1 !important;
    color: #c1c1c1 !important;

    &:hover {
      cursor: pointer !important;
      border: 2px solid #5CB85D !important;
      color: white !important;
      text-decoration: none !important;
    }
  }
}

#ya-site-results .b-serp-item__text {
  margin-top: 6px !important;
}

.b-pager__title, .b-pager__inactive, .b-pager__active {
  display: none;
}

#ya-site-results .b-pager__select {
  background-color: transparent !important;
}


#ya-site-results .b-pager__select {
  background: transparent;
}
