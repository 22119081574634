.g-header-information {
  &__cycle {
    width: .6rem;
    height: .6rem;
    margin-right: .3rem;
    display: inline-block;
  }

  @include breakpoint($for-phone-only) {
  }
  @include breakpoint($for-tablet-portrait-up) {
  }
  @include breakpoint($for-tablet-landscape-up) {
    margin-left: .5rem;
  }
  @include breakpoint($for-desktop-up) {
    margin-left: 0;
  }
}