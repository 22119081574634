@media screen and (max-width:1000px) {
    .m-photo {
      display: none;
    }
    .content-pb {
      margin-left: 2%;
      margin-right: 2%;
    }
}

.g-list-pb {
  display: flex;
  align-items: center;
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 2rem 0 2.5rem 0;
    padding-bottom: .5rem;
  }
  @include breakpoint ($for-desktop-up) {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 2rem 0 2.5rem 0;
    padding-bottom: .5rem;
  }
  &__item {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
    @include breakpoint ($for-desktop-up) {
      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
    &-counter {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
    }
    &-text {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
        margin-top: -.4rem;
      }
      @include breakpoint ($for-desktop-up) {
        margin-top: -.4rem;
      }
    }
  }
}

.g-filter-pb {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {

  }
  &__item {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin-bottom: .1rem;
    }
    @include breakpoint ($for-desktop-up) {
      margin-bottom: .1rem;
    }
    label{
      padding: .2rem .2rem .1rem .2rem;
      display: inline-block;
    }
  }
  &__sort {
    display: flex;
    align-items: center;
  }
}