.m-modal-window{
  @include breakpoint ($for-phone-only){
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up){
    box-shadow: 0 4px 12px 0 rgba(50,50,50,.35);
  }
  background-color: white;
  z-index: 100;
  &__title{
    font-size: 1.2em;
    color: $color-text-grey;
  }
  &__close{
    .icon-close{
      fill: #a2a2a2;
    }
  }
  &__content{}
}
