.unknown_curator {

}
.g-feedback-ec {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
    display: flex;
    flex-flow: row nowrap;
    position: fixed;
    top: 25%;
    right: -600px;
    z-index: 1000;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    display: flex;
    flex-flow: row nowrap;
    position: fixed;
    top: 25%;
    right: -600px;
    z-index: 1000;
  }
  @include breakpoint ($for-desktop-up) {
    display: flex;
    flex-flow: row nowrap;
    position: fixed;
    top: 25%;
    right: -600px;
    z-index: 1000;
  }
  &__button {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
      width: 8rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      width: 8rem;
    }
    @include breakpoint ($for-desktop-up) {
      width: 8rem;
    }
  }
  &__form {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {

    }
  }
}