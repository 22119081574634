.m-news {
  @include breakpoint($for-phone-only) {
    z-index: 100000;
  }
  @include breakpoint($for-tablet-portrait-up) {
    z-index: 100000;
  }
  @include breakpoint($for-tablet-landscape-up) {
    z-index: 1;
  }

  &__header-modules {
    font-weight: 500;
    font-family: 'Futura PT', sans-serif;
    @include breakpoint($for-phone-only) {
      font-size: 1.75em;
      line-height: 1.5rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      font-size: 1.75em;
      line-height: 1.5rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      font-size: 1.5rem;
      box-shadow: none;
      border-bottom: none;
    }
    @include breakpoint($for-desktop-up, $for-big-desktop-up) {
      font-size: 1.5rem;
      box-shadow: none;
      border-bottom: none;
      &:after {
        border-radius: .5rem;
        background-color: $color-dark-blue;

      }
    }
  }

  &__action {
    &-add {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
    }

    &-all {
      //background-color: $color-bg-button-all-news;
      @include breakpoint($for-phone-only) {
        //border-radius: 0 0 .75rem .75rem;
        //box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
      }
      @include breakpoint($for-tablet-portrait-up) {
        //border-radius: .625rem;
      }
      @include breakpoint($for-tablet-landscape-up) {

      }

    }
  }
}

.m-article {
  @include breakpoint($for-phone-only) {
    border-bottom: 1px solid $color-border;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: .75rem;
  }
  @include breakpoint($for-tablet-portrait-up) {
    border-bottom: 1px solid $color-border;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: .75rem;
  }
  @include breakpoint($for-tablet-landscape-up) {
    border-bottom: 0;
    box-shadow: none;
  }

  &__meta {
    z-index: 1;

    &-icon {
      border-radius: .375rem;
      overflow: hidden;
      object-fit: cover;
      border: 1px solid rgba(178, 200, 255, 1);
      background: rgba(178, 200, 255, .8);
    }

    &-date {
      color: $color-text-grey;
      font-family: 'Futura PT Book', sans-serif;
      font-size: 1em;
      line-height: 1em;

      &__edit {
        color: $color-text-grey;
        font-family: 'Futura PT Book', sans-serif;
        font-size: .85em;
      }
    }

    &-btn {
      &:hover {
        cursor: pointer;
      }

      &-editing {

      }

      &-confirm {

      }

      &-en {

      }

      &-ch {

      }
    }
  }

  &__content {
    &-title {
      font-family: 'Futura PT Demi', sans-serif;
      text-decoration: none;
      line-height: 1.219em;
      @include breakpoint($for-phone-only) {
        font-size: 1.2rem;
        font-weight: 700;
      }
      @include breakpoint($for-tablet-portrait-up) {
        font-size: 1.2rem;
        font-weight: 700;
      }
      @include breakpoint($for-tablet-landscape-up) {
        font-size: 1.765em;
        font-weight: 600;
      }

      &:hover, &:focus, &:visited {
        text-decoration: none;
      }

      &-link {
        border-bottom: 1px solid $color-text-underline;
        color: $color-text;
        font-family: 'Futura PT Demi', sans-serif;
        text-decoration: none;
        @include breakpoint($for-phone-only) {
          font-size: 1.2rem;
          font-weight: 700;
          font-weight: 700;
        }
        @include breakpoint($for-tablet-portrait-up) {
          font-size: 1.2rem;
          font-weight: 700;
          line-height: 2rem;
        }
        @include breakpoint($for-tablet-landscape-up) {
          font-size: 1.765em;
          line-height: 2.5rem;
          font-weight: 600;
        }

        &:visited {
          text-decoration: none;
          color: $color-text-light;
          border-bottom: 1px solid $color-text-underline-light;
        }

        &:hover, &:focus {
          text-decoration: none;
          color: $color-text-orange-light;
          border-bottom: 1px solid $color-text-orange-underline-light;
        }
      }
    }

    &-body {
      img {
        border-radius: .75rem !important;
        border: none !important;
        object-fit: cover !important;
        box-shadow: 0 13px 15px -5px rgba(197,189,234,0.6) !important;
      }
    }

    &-gallery {
      &__title {
        color: $color-text;
        font-family: 'Futura PT', sans-serif;
        font-weight: 500;
        font-size: 1.35em;
      }
    }

    &-from {
      &__section {
        color: $color-text-grey;
        font-style: italic;
        font-size: .9em;

        &:hover, &:focus, &:visited {
          color: $color-text-grey;
        }
      }
    }

    &-tags {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
    }
  }

  &-detail {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }

    .m-article__content-title {
      &:hover, &:focus {
        color: inherit;
      }

      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
        font-size: 2.5em;
      }
    }
  }
}

.m-img-icon {
  &:hover {
    cursor: pointer;
  }
}