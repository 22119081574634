.m-status-university {
  @include breakpoint($for-phone-only) {
    a {
      border: 0;
      font-size: .625rem;
      &:visited, &:active, &:focus, &:hover {
        border: 0;
      }
    }

  }
  @include breakpoint($for-tablet-portrait-up) {
    a {
      font-size: .625rem;
      border: 0;
      &:visited, &:active, &:focus, &:hover {
        border: 0;
      }
    }
  }
  @include breakpoint($for-tablet-landscape-up) {
    font-size: 1em;
  }
}