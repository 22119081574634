.m-schedule {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {
  }
  @include breakpoint ($for-big-desktop-up) {
  }
  &__class-now {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
  &__weeks {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
    &__item {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
        &:hover, &:focus {
          cursor: pointer;
        }
      }
      @include breakpoint ($for-desktop-up) {
        &:hover, &:focus {
          cursor: pointer;
        }
      }
      @include breakpoint ($for-big-desktop-up) {
        &:hover, &:focus {
          cursor: pointer;
        }
      }
      &-active {
        @include breakpoint ($for-phone-only) {
          border-bottom: .1rem solid mapget($color-btn, primary);
          font-weight: 600;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          border-bottom: .1rem solid mapget($color-btn, primary);
          font-weight: 600;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          border-bottom: .1rem solid mapget($color-btn, primary);
          font-weight: 600;
        }
        @include breakpoint ($for-desktop-up) {
          border-bottom: .1rem solid mapget($color-btn, primary);
          font-weight: 600;
        }
        @include breakpoint ($for-big-desktop-up) {
          border-bottom: .1rem solid mapget($color-btn, primary);
          font-weight: 600;
        }
      }
    }
  }
  &__days-of-the-week {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
    &__item {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
        &:hover, &:focus {
          cursor: pointer;
        }
        &:not(:last-child) {
          border-right: .02rem solid $color-border;
        }
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }

      &-active {
        @include breakpoint ($for-phone-only) {
          font-weight: 600;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          font-weight: 600;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          font-weight: 600;
        }
        @include breakpoint ($for-desktop-up) {
          font-weight: 600;
          background-color: rgba(103, 58, 183, 0.11);
        }
        @include breakpoint ($for-big-desktop-up) {
          font-weight: 600;
          background-color: rgba(103, 58, 183, 0.11);
        }
      }
    }
  }
  &__list-class {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
      &:not(:last-child) {
        border-right: .02rem solid $color-border;
      }

    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
    &__item {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
  }
  &__card {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
    &__header {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }

    }
    &__content {
      @include breakpoint ($for-phone-only) {
        border: .08rem solid $color-border;
        //border-radius: .2rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        border: .08rem solid $color-border;
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }

    }
    &__content-home {
      @include breakpoint ($for-phone-only) {
        background-color: white;
        border: 0;
        box-shadow: 0 5px 15px 0 #bababa;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        background-color: white;
        border: 0;
        box-shadow: 0 5px 15px 0 #bababa;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        background-color: white;
        border: 0;
        box-shadow: 0 5px 15px 0 #bababa;
      }
      @include breakpoint ($for-desktop-up) {
        background-color: white;
        border: 0;
        box-shadow: 0 5px 15px 0 #bababa;
      }
      @include breakpoint ($for-big-desktop-up) {
        background-color: white;
        border: 0;
        box-shadow: 0 5px 15px 0 #bababa;
      }

    }
    &-type {
      @include breakpoint ($for-phone-only) {
        font-size: .9em;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        font-size: .9em;
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
    &-room {
      @include breakpoint ($for-phone-only) {
        background-color: mapget($color-btn, danger);
        color: white;
        font-size: .95em;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        background-color: mapget($color-btn, danger);
        color: white;
        font-size: .95em;
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
    &-name {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
    &-time {
      @include breakpoint ($for-phone-only) {
        border-right: .1rem solid mapget($color-btn, warning);
      }
      @include breakpoint ($for-tablet-portrait-up) {
        border-right: .1rem solid mapget($color-btn, warning);
      }
      @include breakpoint ($for-tablet-landscape-up) {
        border-bottom: .1rem solid mapget($color-btn, warning);
        border-right: 0;
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
      &__from {
        @include breakpoint ($for-phone-only) {
          font-size: .9em;
          color: $color-text-grey;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          font-size: .9em;
          color: $color-text-grey;
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
        }
        @include breakpoint ($for-big-desktop-up) {
        }
      }
      &__to {
        @include breakpoint ($for-phone-only) {
          font-size: .9em;
          color: $color-text-grey;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          font-size: .9em;
          color: $color-text-grey;
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
        }
        @include breakpoint ($for-big-desktop-up) {
        }
      }
    }
    &-lecturer {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
  }
}