.g-victories {
  @include breakpoint ($for-phone-only) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    display: flex;
    flex-flow: column;
  }
  &__item {
    display: flex;
    align-items: center;
    @include breakpoint ($for-phone-only) {
      margin: 0;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin: 0;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin-bottom: .5rem;
    }
    a {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
        display: flex;
        align-items: center;
        margin-bottom: .25rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        display: flex;
        align-items: center;
        margin-bottom: .25rem;
      }
    }
    &-icon {
      width: 2rem;
      height: 2rem;
      padding: .25rem;
      box-sizing: border-box;
      img{
        width: 100%;
      }
      @include breakpoint ($for-phone-only) {
        margin: 0;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        margin: 0;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        margin-right: .75rem;
      }
    }
    &-text {
      @include breakpoint ($for-phone-only) {
        display: none;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        display: none;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        display: block;
      }
    }
  }
}