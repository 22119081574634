.m-education-list-fac {
  @include breakpoint($for-phone-only) {

  }
  @include breakpoint($for-tablet-portrait-up) {

  }
  @include breakpoint($for-tablet-landscape-up) {

  }
  @include breakpoint($for-desktop-up) {

  }
  @include breakpoint($for-big-desktop-up) {

  }

  &__title {
    font-weight: 500;
    font-family: 'Futura PT', sans-serif;
    @include breakpoint($for-phone-only) {
      font-size: 1.75em;
      line-height: 1.5rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      font-size: 1.75em;
      line-height: 1.5rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      font-size: 1.5rem;
      box-shadow: none;
      border-bottom: none;
    }
    @include breakpoint($for-desktop-up, $for-big-desktop-up) {
      font-size: 1.5rem;
      box-shadow: none;
      border-bottom: none;
      &:after {
        border-radius: .5rem;
        background-color: $color-dark-blue;

      }
    }
  }

  &__oop-list {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {
    }

    &__item {
      background-color: #FFFAF2;
      border-radius: 1rem;
      line-height: 1.25rem;
      font-size: .85rem;
      border-bottom: 0;

      &:hover {
        border-bottom: 0;
        background-color: darken(#FFFAF2, 1.5%);
      }

      &:visited, active, focus {
        border-bottom: 0;
      }

      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }

    }
  }
}