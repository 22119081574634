.gallery{
  display: flex;
  flex-flow: row wrap;
  &-item{
    margin-right: .7rem;
    img{
      width: 7rem;
      height:7rem;
      object-fit: cover;
    }
    input[type=checkbox]{
      margin: 0 !important;
    }
  }
}