.m-pagination {
  @include breakpoint($for-phone-only) {
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: .75rem;;
  }
  @include breakpoint($for-tablet-portrait-up) {
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: .75rem;;
  }
  @include breakpoint($for-tablet-landscape-up) {
    background-color: transparent;
    box-shadow: none;
  }

  &__pages {
    &__item {
      @include breakpoint($for-phone-only) {
        border-bottom: 0;
        &-next {
          &:after {
            content: ">";
          }
        }
        &-prev {
          &:after {
            content: "<";
          }
        }
      }
      @include breakpoint($for-tablet-portrait-up) {
        border-bottom: 0;
        &-next {
          &:after {
            content: ">";
          }
        }
        &-prev {
          &:after {
            content: "<";
          }
        }
      }
      @include breakpoint($for-tablet-landscape-up) {
        border: 1px solid $color-border-tags;
        box-shadow: 0 0 0 2px $color-shadow-tags;
        background-color: white;
        border-radius: .625rem;
        color: $color-text-grey;
        &:hover, &:focus {
          border-color: mapget($color-btn, success);
          box-shadow: 0 0 0 2px lighten($color-bg-jstabs-label-active, 25%);
          color: $color-text-grey;
          cursor: pointer;
        }
        &:visited {
          color: $color-text-grey;
        }
        &-prev {
          &:after {
            content: "Назад";
          }
        }
        &-next {
          &:after {
            content: "Вперед";
          }
        }
      }

      &-active {
        line-height: 1.7rem;
        @include breakpoint($for-phone-only) {
          background-color: mapget($color-btn, success);
          color: white;
          border-radius: .625rem;
        }
        @include breakpoint($for-tablet-portrait-up) {
          color: white;
          background-color: mapget($color-btn, success);
          border-color: mapget($color-btn, success);
          box-shadow: 0 0 0 2px lighten($color-bg-jstabs-label-active, 10%);
          border-radius: .625rem;
        }
        @include breakpoint($for-tablet-landscape-up) {
          color: white;
          background-color: mapget($color-btn, success);
          border-color: mapget($color-btn, success);
          box-shadow: 0 0 0 2px lighten($color-bg-jstabs-label-active, 10%);
          &:hover, &:focus {
            border-color: mapget($color-btn, success);
            box-shadow: 0 0 0 2px lighten($color-bg-jstabs-label-active, 10%);
            color: white;
          }
        }
      }
    }

    &__separator {
      @include breakpoint($for-phone-only) {
        background-color: white;
        border-radius: .3rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        background-color: $color-icon;
        border-radius: .3rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        border: 1px solid $color-border-tags;
        box-shadow: 0 0 0 2px $color-shadow-tags;
        background-color: white;
      }
    }
  }
}