.m-tag {
  cursor: pointer;
  @include breakpoint($for-phone-only) {
    border-radius: 0;
    background-color: transparent;
  }
  @include breakpoint($for-tablet-portrait-up) {
    border-radius: 0;
    background-color: transparent;
  }
  @include breakpoint($for-tablet-landscape-up) {
    border-radius: .35rem;
    border-width: 1px;
    border-style: solid;
    box-shadow: 0 0 0 1px $color-shadow-tags;
  }

  &__color__default {
    border-color: $color-border-tags;;
  }

  a {
    @include breakpoint($for-phone-only) {
      line-height: 1.25rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      line-height: 1.25rem;

    }
    @include breakpoint($for-tablet-landscape-up) {
      line-height: 0.65rem;
    }
    font-size: .75em;
    color: $color-text-tags;
    border-bottom: 0;
    white-space: nowrap;

    &:visited {
      color: $color-text-tags;
      text-decoration: none;
    }

    &:hover, &:active {
      text-decoration: none;
    }
  }
}