.g-sv-control {
  display: none;
  &__wrapper {
    display: flex;
    flex-flow: row nowrap;
    margin: 0 auto;
    padding: .5rem 0;
    @include breakpoint($for-phone-only) {
      width: $small;
    }
    @include breakpoint($for-tablet-portrait-up) {
      width: $small;
    }
    @include breakpoint($for-tablet-landscape-up) {
      width: $small;

    }
    @include breakpoint($for-desktop-up) {
      width: $middle;
    }
    @include breakpoint($for-big-desktop-up) {
      width: $big;
    }
  }

  &__block {
    margin-right: 3.6rem;
    display: flex;
    align-items: center;
  }

  &__list {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-content: center;
  }

  &__item {
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 2.5rem;
    height: 2.5rem;
    line-height: 2rem;
    padding: 0 .425rem;
    margin: 0 .125rem;
  }
}