.m-form_education_at_profile {
  @include breakpoint ($for-phone-only) {

  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {

  }
  @include breakpoint ($for-desktop-up) {

  }
  @include breakpoint ($for-big-desktop-up) {

  }
  &-name {
    color: $color-dark-blue;
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
      font-size: .85rem;
    }
    @include breakpoint ($for-desktop-up) {
      font-size: .85rem;
    }
    @include breakpoint ($for-big-desktop-up) {
      font-size: .85rem;
    }
  }
  &-list_fac {
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up) {

    }
    @include breakpoint ($for-big-desktop-up) {

    }
    &__item {
      &-name{
        a{
          @include link-colors($color-text-grey,darken($color-text-grey,10%),$color-text-grey,$color-text-grey,$color-text-grey );
        }
        color: $color-text-grey;
        font-style: italic;
        @include breakpoint ($for-phone-only) {
          font-size: .625rem;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          font-size: .625rem;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          font-size: .625rem;
        }
        @include breakpoint ($for-desktop-up) {
          font-size: .625rem;
        }
        @include breakpoint ($for-big-desktop-up) {
          font-size: .625rem;
        }
      }
    }
  }
}