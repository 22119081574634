.m-search {
  &__link {
    a {
      font-size: .75rem;
      color: $color-text-grey-light;
      border-bottom: 0;

      &:visited, &:active, &:focus {
        color: $color-text-grey-light;
      }

      &:hover {
        color: $color-text-orange-light;
      }
    }
  }
}