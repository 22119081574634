.g-publication-base {
  display: flex;
  @include breakpoint ($for-phone-only) {
    flex-flow: column;
    margin: .5rem .25rem;
    padding: 1rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    flex-flow: column;
    margin: .5rem .25rem;
    padding: 1rem;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    flex-flow: row nowrap;
    padding: 0;
    margin-bottom: 2rem;
  }

}
