.m-navigation-window {
  z-index: 500000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .15);

  @include breakpoint($for-phone-only) {
    background-color: $color-bg-mobile;
  }

  @include breakpoint($for-tablet-portrait-up) {
    background-color: $color-bg-mobile;
  }

  @include breakpoint($for-tablet-landscape-up) {
    background-color: white;
    .m-border-bottom {
      border: 0;
    }
  }

  @include breakpoint($for-desktop-up) {
    background-color: white;
    .m-border-bottom {
      border: 0;
    }
  }

  &__list-actions {
    background-color: white;
    border-top: 0.01rem solid $color-border;

    &__item {
      .m-action {
        fill: $color-icon;
      }

      &-active {
        .m-action {
          fill: $color-dark-blue;

        }
      }
    }
  }

  &-content {
    &__item {
      .m-navigation__section {
        &-item {
          @include breakpoint($for-phone-only) {
            font-size: 1em;
          }

          @include breakpoint($for-tablet-portrait-up) {
            font-size: 1em;

          }

          @include breakpoint($for-tablet-landscape-up) {
            font-size: 1em;
          }

          @include breakpoint($for-desktop-up) {
            font-size: 1em;
          }
        }
      }

      .m-navigation__hot-links {
        &-item {
          @include breakpoint($for-phone-only) {
          }
          @include breakpoint($for-tablet-portrait-up) {
          }
          @include breakpoint($for-tablet-landscape-up) {
            font-size: 1em !important;
            @include link-colors($color-link-normal, $color-link-hover, $color-link-visited, $color-link-active, $color-link-focus);
            &:hover, &:focus {
              text-decoration: underline;
            }
          }
          @include breakpoint($for-desktop-up) {
            font-size: 1em !important;
            @include link-colors($color-link-normal, $color-link-hover, $color-link-visited, $color-link-active, $color-link-focus);
            &:hover, &:focus {
              text-decoration: underline;
            }
          }
        }

      }
    }

    &__wrapper {
      background-color: white;
      @include breakpoint($for-phone-only) {
        overflow-y: scroll;
      }

      @include breakpoint($for-tablet-portrait-up) {
        overflow-y: scroll;
      }

      @include breakpoint($for-tablet-landscape-up) {
        overflow-y: inherit;
        background: url("/static/img/bg_study.svg") no-repeat;
        background-position: 100% 90%;
      }

      @include breakpoint($for-desktop-up) {
        overflow-y: inherit;
        background: url("/static/img/bg_study.svg") no-repeat;
        background-position: 100% 90%;
      }
    }

    &__title {
      font: normal 85%/1.45 'Futura PT', sans-serif;
      border-bottom: 0.01rem solid $color-border-light;
      color: $color-text;

    }

    &__iteresting-menu__title {
      font: normal 85%/1.45 'Futura PT', sans-serif;
      border-bottom: 0.01rem solid $color-border-light;
      color: $color-text;
    }

  }

  &__header {
    background-color: white;

    &-title {
      color: $color-text;
      font-size: 1em;
    }

    .icon-close {
      fill: $color-icon;
      @include breakpoint($for-phone-only) {
      }

      @include breakpoint($for-tablet-portrait-up) {
      }

      @include breakpoint($for-tablet-landscape-up) {
        cursor: pointer;
      }

      @include breakpoint($for-desktop-up) {
        cursor: pointer;
      }
    }

    &-logo {
      @include breakpoint($for-phone-only) {
      }

      @include breakpoint($for-tablet-portrait-up) {
      }

      @include breakpoint($for-tablet-landscape-up) {

      }

      @include breakpoint($for-desktop-up) {

      }


      a {
        border: 0;

        &:visited, &:active, &:focus, &:hover {
          border: 0;
        }
      }

    }
  }
}