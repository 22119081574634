.g-score {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 2rem 0 2.5rem 0;
    padding-bottom: .5rem;
  }
  @include breakpoint ($for-desktop-up) {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 2rem 0 2.5rem 0;
    padding-bottom: .5rem;
  }
  &__item {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
    @include breakpoint ($for-desktop-up) {
      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
    &-counter {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
    }
    &-text {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
    }
    &__ec-heading {
      font-size: 1.5em !important;
    }
  }

  &__ec {
    margin: 0;
  }
  &__ec-points {
    margin: 0 0 0 1.4rem;
  }
}
