.m-socials {
  &__title {
    font-size: 1.25rem;
    color: $color-text;
    font-family: 'Futura PT', sans-serif;
  }

  &__list {
    &-item {

      border: 0;

      &:visited, &:active, &:focus {
        border: 0;
      }

      &:hover {
        border: 0;
      }


      &__icon {
        border-radius: .5rem;
        background-color: white;;
      }

      &__name {
        font-size: .75rem;
        border: 1px solid transparent;
        color: $color-text;

        &:visited {
          color: $color-text-light;
          border-bottom: 1px solid $color-text-underline-light;
        }

        &:hover, &:focus {
          color: $color-text-orange-light;
          border-bottom: 1px solid $color-text-orange-underline-light;
        }
      }
    }
  }
}