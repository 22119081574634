.m-header-mobile {

  @include breakpoint ($for-phone-only) {

  }
  @include breakpoint ($for-tablet-portrait-up) {

  }
  @include breakpoint ($for-tablet-landscape-up) {

  }

  &__center {
    &-short {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      .m-name-university {
        @include breakpoint ($for-phone-only) {
          font-size: .8rem;
          line-height: 1rem;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          font-size: 1.3em;
        }
        @include breakpoint ($for-tablet-landscape-up) {
           font-size: 1.3em;
        }
      }
    }
  }

  &__bottom {
    &-short {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }

    }
  }
}