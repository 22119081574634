.m-contact{
  &__title{
    font-size: 1.25rem;
    color: $color-text;
    font-family: 'Futura PT',sans-serif;
  }
  &__item{
    font-size: .75rem;
    a{font-size: .75rem;}
    &-title{
      color: $color-text;
      font-weight: 600;
    }
  }
}