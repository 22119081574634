.g-header-mobile {
  @include breakpoint($for-phone-only) {
  }
  @include breakpoint($for-tablet-portrait-up) {
  }
  @include breakpoint($for-tablet-landscape-up) {
    display: none;
  }
  @include breakpoint($for-desktop-up) {
    display: none;
  }
  @include breakpoint($for-big-desktop-up) {
    display: none;
  }

  &__top {
    @include breakpoint($for-phone-only) {
      flex-flow: column-reverse;
      display: block;


    }
    @include breakpoint($for-tablet-portrait-up) {
      flex-flow: row nowrap;
      justify-content: space-between;
      display: block;

    }
    @include breakpoint($for-tablet-landscape-up) {
      margin-bottom: 0;
      display: flex;

    }
    @include breakpoint($for-desktop-up) {
      display: flex;


    }
    @include breakpoint($for-big-desktop-up) {
      display: flex;


    }
  }

  &__center {
    display: flex;
    align-items: center;
    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {
      flex-flow: row wrap;
    }
    @include breakpoint($for-tablet-landscape-up) {
      flex-flow: row nowrap;
    }

    &-short {
      display: flex;
      flex-flow: row nowrap;
      @include breakpoint($for-phone-only) {
        padding: 1rem .5rem .5rem .5rem;
        margin-bottom: 1rem;
        align-items: center;
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      .g-logo {
        @include breakpoint($for-phone-only) {
          width: 2rem;
        }
        @include breakpoint($for-tablet-portrait-up) {
          width: 3rem;
        }
        @include breakpoint($for-tablet-landscape-up) {
        }
      }
    }
  }

  &__bottom {
    display: flex;
    @include breakpoint($for-phone-only) {
      justify-content: flex-start;
      flex-flow: column;
    }
    @include breakpoint($for-tablet-portrait-up) {
      justify-content: flex-start;
      flex-flow: column;
    }
    @include breakpoint($for-tablet-landscape-up) {
      justify-content: flex-end;
    }

    &-short {
      @include breakpoint($for-phone-only) {
        margin-top: -2rem;
        padding-left: 2.3rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        margin-top: -1.5rem;
        padding-left: 3.8rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        margin-top: 0;
        padding-left: 0;
      }

      .g-status-university {
        @include breakpoint($for-phone-only) {
          margin-top: 0;
        }
        @include breakpoint($for-tablet-portrait-up) {
          margin-top: 0;
        }
        @include breakpoint($for-tablet-landscape-up) {
          margin-top: 0;
        }
      }
    }
  }
}