.m-header-all-news {
  @include breakpoint($for-phone-only) {
  }
  @include breakpoint($for-tablet-portrait-up) {
  }
  @include breakpoint($for-tablet-landscape-up) {
  }

  &__wrapper {
  }

  &__img {
    box-shadow: 0 13px 15px -5px rgba(197, 189, 234, 0.7);
    @include breakpoint($for-phone-only) {
      border-radius: 1.25rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      border-radius: 1.25rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      border-radius: 1.875rem;
    }
    @include breakpoint($for-desktop-up) {
      border-radius: 1.875rem;
    }
    @include breakpoint($for-big-desktop-up) {
      border-radius: 1.875rem;

    }

    img {
      @include breakpoint($for-phone-only) {
        border-radius: 1.25rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        border-radius: 1.25rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        border-radius: 1.875rem;
      }
      @include breakpoint($for-desktop-up, $for-big-desktop-up) {
        border-radius: 1.875rem;
      }
    }
  }
  &__text {

    &-title {
      font-family: 'Futura PT', sans-serif;
      @include breakpoint($for-phone-only) {
        font-size: 3rem;
        line-height: 3rem;
        z-index: 2;
      }
      @include breakpoint($for-tablet-portrait-up) {
        font-size: 3rem;
        line-height: 3rem;
        z-index: 2;
      }
      @include breakpoint($for-tablet-landscape-up) {
        z-index: 15;
        font-size: 4rem;
        line-height: 4rem;
      }
      @include breakpoint($for-desktop-up, $for-big-desktop-up) {
        z-index: 15;
        font-size: 4rem;
        line-height: 4rem;
      }
    }

    &-subtitle {

      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
    }

    &-search {
      z-index: 300;
      border-bottom: .01rem solid white;
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }

      &__input {
        outline: 0;

        &::-webkit-input-placeholder {

        }

        &::-moz-placeholder {

        }

        /* Firefox 19+ */
        &:-moz-placeholder {

        }

        /* Firefox 18- */
        &:-ms-input-placeholder {

        }

        @include breakpoint($for-phone-only) {
          font-size: 1em;
        }
        @include breakpoint($for-tablet-portrait-up) {
          font-size: 1em;
        }
        @include breakpoint($for-tablet-landscape-up) {
          font-size: 1em;
        }
      }
    }
  }

}