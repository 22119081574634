.g-static-page {
  @include breakpoint ($for-phone-only) {
    padding: 1rem;
    margin: 0 .25rem;
    img {
      width: 100% !important;
      height: auto !important;
    }
  }
  @include breakpoint ($for-tablet-portrait-up) {
    padding: 1rem;
    img {
      width: auto;
    }
  }
  @include breakpoint ($for-tablet-landscape-up) {
    padding: 1rem;
    img {
      width: auto;
    }
  }
  @include breakpoint ($for-desktop-up) {
    padding: 0;
    img {
      width: auto;
    }
  }
  @include breakpoint ($for-big-desktop-up) {
    padding: 0;
    img {
      width: auto;
    }
  }
  &__title {
    @include breakpoint ($for-phone-only) {
      padding: 2rem 1rem 0 1rem;
      margin-bottom: 1rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      padding: 2rem 1rem 0 1rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      padding: 4rem 1rem 1rem 1rem;
    }
    @include breakpoint ($for-desktop-up) {
      padding: 4rem 0 0 0;
    }
    @include breakpoint ($for-big-desktop-up) {
      padding: 4rem 0 0 0;
    }
  }
  &__subtitle {
    @include breakpoint ($for-phone-only) {
      padding: 1rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      padding: 1rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      padding: 1rem;
    }
    @include breakpoint ($for-desktop-up) {
      padding: 1rem 0 2rem 0;
    }
    @include breakpoint ($for-big-desktop-up) {
      padding: 1rem 0 2rem 0;
    }
  }
  &__editor {
    position: relative;
    @include breakpoint ($for-phone-only) {
      padding: 1rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      padding: 1rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      padding: 1rem;
      margin-top: 1rem;
    }
    @include breakpoint ($for-desktop-up) {
      padding: 0;
      margin-top: 1rem;
    }
    @include breakpoint ($for-big-desktop-up) {
      padding: 0;
      margin-top: 1rem;
    }
  }
}