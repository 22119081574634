.m-languages {
  &__item {
    color: $color-text-grey-light;
    border-bottom: 0;
    white-space: nowrap;
    &:visited, &:active, &:focus {
      color: $color-text-grey-light;
    }

    &:hover {
      color: $color-text-orange-light;
      cursor: pointer;
    }

    @include breakpoint($for-phone-only) {
      font-size: 1.2em;
    }
    @include breakpoint($for-tablet-portrait-up) {
      font-size: 1.1em;
    }
    @include breakpoint($for-tablet-landscape-up) {
      font-size: .75rem;
    }
  }
}
