.m-name-university {
  font-family: 'Futura PT Book', sans-serif;
  color: $color-text;
  @include breakpoint($for-phone-only) {
    font-size: 1.4em;
    line-height: 1.125em;
  }
  @include breakpoint($for-tablet-portrait-up) {
    font-size: 1.5em;
    line-height: 1.125em;
  }
  @include breakpoint($for-tablet-landscape-up) {
    font-size: 1.75em;
    line-height: 1.125em;
  }
  @include breakpoint($for-desktop-up) {
    font-size: 1.9em;
    line-height: 1.125em;
  }
}