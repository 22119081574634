.g-oop {
  &__description {
    @include breakpoint ($for-phone-only) {
      padding: 1rem;
      margin: 1rem .25rem 1rem .25rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      padding: 1rem;
      margin-bottom: 1rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      padding: 1rem;
      margin-bottom: 0;
    }
    @include breakpoint ($for-desktop-up) {
      padding: 0;
      margin-bottom: 0;
    }
    @include breakpoint ($for-big-desktop-up) {
      padding: 0;
      margin-bottom: 0;
    }
  }

  &__passport-min {
    @include breakpoint ($for-phone-only) {
      padding: 1rem;
      margin: 0 .25rem 1rem .25rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      padding: 1rem;
      margin-bottom: 1rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      padding: 1rem;
      margin-bottom: 0;
      display: flex;
      flex-flow: row nowrap;
      margin-top: 2rem;
    }
    @include breakpoint ($for-desktop-up) {
      padding: 0;
      margin-bottom: 0;
      display: flex;
      flex-flow: row nowrap;
      margin-top: 2rem;
    }
    @include breakpoint ($for-big-desktop-up) {
      display: flex;
      flex-flow: row nowrap;
      margin-top: 2rem;
      margin-bottom: 0;
      padding: 0;
    }

    &__list-years {
      @include breakpoint ($for-phone-only) {
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: 1rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: 1rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        display: block;
        margin-bottom: 0;
      }
      @include breakpoint ($for-desktop-up) {
        display: block;
        margin-bottom: 0;
      }
      @include breakpoint ($for-big-desktop-up) {
        display: block;
        margin-bottom: 0;
      }
      &__wrapper{
        @include breakpoint ($for-phone-only) {
          display: flex;
          flex-flow: row nowrap;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          display: flex;
          flex-flow: row nowrap;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          display: flex;
          flex-flow: row nowrap;
        }
        @include breakpoint ($for-desktop-up) {
          display: block;
        }
        @include breakpoint ($for-big-desktop-up) {
          display: block;
        }
      }
      &-width {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
          width: 5.2rem;
        }
        @include breakpoint ($for-big-desktop-up) {
          width: 4.1rem;
        }

        &-edit {
          @include breakpoint ($for-phone-only) {
          }
          @include breakpoint ($for-tablet-portrait-up) {
          }
          @include breakpoint ($for-tablet-landscape-up) {
          }
          @include breakpoint ($for-desktop-up) {
            width:8.5rem;
          }
          @include breakpoint ($for-big-desktop-up) {
            width: 6.7rem;
          }
        }
      }

      &__item {
        @include breakpoint ($for-phone-only) {
          margin-right: 1rem;
          padding-left: 0;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          margin-right: 1rem;
          padding-left: 0;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          margin-right: 0;
          padding-right: 1.1rem;
          padding-left: 0;
          padding-bottom: .7rem;
        }
        @include breakpoint ($for-desktop-up) {
          margin-right: 0;
          padding-left: 0;
          padding-bottom: .7rem;
          padding-right: 1.1rem;
          .m-btn {
            margin-right: .5rem;
          }
          &-fixed {
            padding-right: 1.3rem;
            margin-right: 0;
            padding-left: 0;
            padding-bottom: .7rem;
            width: 2.5rem;
          }
          &-fixed-edit {
            padding-right: .5rem;
            margin-right: 0;
            padding-left: 0;
            padding-bottom: .7rem;
          }
        }
        @include breakpoint ($for-big-desktop-up) {
          margin-right: 0;
          padding-left: 0;
          padding-right: 1.1rem;
          padding-bottom: .7rem;
          .m-btn {
            margin-right: .5rem;
          }
          &-fixed {
            padding-right: 1.3rem;
            margin-right: 0;
            padding-left: 0;
            padding-bottom: .7rem;
            width: 2.5rem;
          }
          &-fixed-edit {
            padding-right: .5rem;
            margin-right: 0;
            padding-left: 0;
            padding-bottom: .7rem;
          }
        }

        &-now {
          @include breakpoint ($for-desktop-up) {
            .m-btn {
              margin-right: .5rem;
              margin-top: 1rem;
            }
          }
          @include breakpoint ($for-big-desktop-up) {
            .m-btn {
              margin-top: 1rem;
              margin-right: .5rem;
            }
          }
        }
      }

      &-content {
        @include breakpoint ($for-phone-only) {
          margin-right: -1rem;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          margin-right: -1rem;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          margin-right: -1rem;
          margin-left: -.04rem;
          padding-left: 1rem;
        }
        @include breakpoint ($for-desktop-up) {
          margin-left: -.04rem;
          padding-left: 1rem;
        }
        @include breakpoint ($for-big-desktop-up) {
          margin-left: -.04rem;
          padding-left: 1rem;
        }
      }
    }
  }

  &-basic-info {
    @include breakpoint ($for-phone-only) {
      padding: 1rem;
      margin: 0 .25rem 1rem .25rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      padding: 1rem;
      margin-bottom: 1rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      padding: 1rem;
      margin-bottom: 0;
    }
    @include breakpoint ($for-desktop-up) {
      padding: 1rem 0 0 0;
      margin-bottom: 0;
    }
    @include breakpoint ($for-big-desktop-up) {
      padding: 1rem 0 0 0;
      margin-bottom: 0;
    }

    &__item {
      @include breakpoint ($for-phone-only) {
        margin-bottom: .5rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        margin-bottom: .5rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        margin-bottom: .5rem;
      }
      @include breakpoint ($for-desktop-up) {
        margin-bottom: .5rem;
      }
      @include breakpoint ($for-big-desktop-up) {
        margin-bottom: .5rem;
      }
    }
  }

  &-accreditation {
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {

    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up) {

    }
    @include breakpoint ($for-big-desktop-up) {

    }
  }
}
