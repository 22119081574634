.g-module-oop {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {
  }
  @include breakpoint ($for-big-desktop-up) {
  }
  &-title {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
  &-item {
    a{display: block;}
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-desktop-up) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-big-desktop-up) {
      margin-bottom: .5rem;
    }
  }
}