.m-border-bottom {
  border-bottom: 1px solid $color-border;
}

.m-input {
  &-disable {
    background-color: $color-border;
  }

  &-light {
    border-top: 0;
    border-bottom: .15rem solid $color-border;
    border-left: 0;
    border-right: 0;
    color: $color-text;
    outline: none;
    background: transparent;
    box-sizing: border-box;

    font-size: 1em;

    &:hover, &:focus {
      border-color: darken($color-border, 10%);
      cursor: pointer;
      outline: none;
    }
  }

  &-cycle {
    font-size: 1em;
    display: block;
    //width: 100%;
    //height: 2.6rem;
    padding: .725rem .9rem;
    color: $color-text;
    //margin-bottom: .5rem;
    background-color: #fff;
    background-image: none;
    border-radius: .3rem;
    border: .09rem solid #8c8c8c;
    box-sizing: border-box;
  }

  &-white {
    border-color: white;
    color: white;

    &:hover, &:focus {
      border-color: white;
    }

    &::-webkit-input-placeholder {
      color: white;
    }

    &::-moz-placeholder {
      color: white;
    }

    /* Firefox 19+ */
    &:-moz-placeholder {
      color: white;
    }

    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: white;
    }
  }

  @include breakpoint($for-phone-only) {
    margin-bottom: 1rem;
    height: 2.6rem;
  }
  @include breakpoint($for-tablet-portrait-up) {
    margin-bottom: 1rem;
    height: 2.6rem;
  }
  @include breakpoint($for-tablet-landscape-up) {
    margin-bottom: .5rem;
    height: 1.3rem;
  }
}


.m-label {
  display: block;
  color: $color-text-blue;
  font-size: .7em;
  margin-bottom: .3rem;

  &__be-sure {
    color: #d84c44;
  }
}



.m-btn {
  @include breakpoint($for-phone-only) {
  }
  @include breakpoint($for-tablet-portrait-up) {
  }
  @include breakpoint($for-tablet-landscape-up) {
    margin: 0 .125rem;
  }
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
  margin: .25rem 0;
  font-family: 'Futura PT Book', sans-serif;
  border-width: 0;
  border-style: solid;

  &:hover, &:visited, &:active, &:focus {
    cursor: pointer;
    outline: none;
  }

  &:disabled {
    opacity: .8;
  }

  &__filler {
  }


  &__lg {
  }

  &__md {
    border-radius: .625rem;
    padding: .5rem 1.5rem;
    line-height: inherit;
    font-size: 1rem;
  }

  &__sm {
    font-size: .675rem;
    line-height: .75rem;
    border-radius: .315rem;
    padding: .25rem .5rem .125rem .5rem;
    font-family: 'Roboto', sans-serif;
    border-width: 1px;
  }

  &__ep {
    @include link-colors($color-link-normal, $color-link-hover, $color-link-visited, $color-link-active, $color-link-focus);
  }
}

.m-form {
  &__fields {
    @include breakpoint($for-phone-only) {
      padding: 0;
      margin-bottom: 1rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      padding: 0;
      margin-bottom: 1rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      padding: 0 1rem;
    }
  }

  &-no-style {
    background: transparent;
    border: 0;
  }
}


.m-compass-bg {
  background: url(/static/img/kompas.png) 0 31% no-repeat #fff;
}

.m-hidden {
  display: none;
  opacity: 0;
}

.m-visible-opacity {
  opacity: .5;
}

.m-header-bg {
  @include breakpoint($for-phone-only) {
  }
  @include breakpoint($for-tablet-portrait-up) {
  }
  @include breakpoint($for-tablet-landscape-up) {
  }
  @include breakpoint($for-desktop-up) {
    //background: url(/static/img/backgrount-left.png) bottom left no-repeat,
    //url(/static/img/backgrount-right.png) bottom right no-repeat;
  }
  @include breakpoint($for-big-desktop-up) {
    //background: url(/static/img/backgrount-left.png) bottom left no-repeat,
    //url(/static/img/backgrount-right.png) bottom right no-repeat;
  }

}

.m-unstyled-link {
  text-decoration: inherit;
  cursor: inherit;
  border-bottom: 0;
  padding-bottom: 0;

  &:active, &:focus, &:hover, &:visited {
    outline: none;
    border-bottom: 0;
    text-decoration: none;
    padding: 0;
  }
}

.m-v-center {
  display: flex;
  align-items: center;
}

.g-wrap {
  flex-flow: row wrap;
}

.g-nowrap {
  flex-flow: row nowrap;
}

.m-no-info {
  color: #d84c44;

  &__no-margin {
    @extend .m-no-info;
    margin-top: -.5rem;
    display: block;
  }
}

.m-success {
  color: #00a13e;
}

.m-pointer {
  &:hover, &:focus {
    cursor: pointer;
  }
}

.g-br {
  height: 1rem;

  &-small {
    height: .5rem;
  }
}

.m-break-word {
  word-break: break-all;
}

.error {
  @extend .m-no-info
}

.m-middle-font-size {
  font-size: .85em;
}

.add_zip_wp {
  @include breakpoint($for-phone-only) {
    width: 100%;
  }
  @include breakpoint($for-tablet-portrait-up) {
    width: 100%;
  }
  @include breakpoint($for-tablet-landscape-up) {
    width: 850px;
  }
}

.flatblock-title {
  margin: 1.67em 0 .5em 0;
  padding: 0;
  font-size: 100%;
}

#hotlog_counter {
  display: none
}

.g-bg-section {
  position: absolute;
  top: 0;
  right: 0;

  @include breakpoint($for-phone-only) {
  }
  @include breakpoint($for-tablet-portrait-up) {
  }
  @include breakpoint($for-tablet-landscape-up) {
  }
  @include breakpoint($for-desktop-up) {
    width: 1017px;
    height: 762px;
  }
  @include breakpoint($for-big-desktop-up) {
    width: 1458px;
    height: 822px;
  }
  @include breakpoint($for-extrabig-desktop-up) {
    width: 1939px;
    height: 1079px;
  }
}

.m-bg-section {
  z-index: -1;
  @include breakpoint($for-phone-only) {
  }
  @include breakpoint($for-tablet-portrait-up) {
  }
  @include breakpoint($for-tablet-landscape-up) {
  }
  @include breakpoint($for-desktop-up) {
    background: url(/static/img/bg_section.svg) top right no-repeat;
  }
  @include breakpoint($for-big-desktop-up) {
    background: url(/static/img/bg_section-big.svg) top right no-repeat;
  }
  @include breakpoint($for-extrabig-desktop-up) {
    background: url(/static/img/bg_section-extrabig.svg) top right no-repeat;
  }
}