.document-group{
  .files{
    display: flex;
    flex-flow: row wrap;
    .box_file{
      max-width: 25rem;
      padding: .7rem;
      border: 1px solid $color-border;
      border-radius: .5rem;
      margin-top: .4rem;
      margin-bottom: .4rem;
      position: relative;
      &:not(:last-child){
        margin-right: .4rem;
      }
    }
  }
}
.file{
  line-height: 1.4rem;
  a{
    word-wrap: break-word;
    padding-bottom: .5rem;
  }
  input[type="file"]{
    width: 100%;
    margin-bottom: .5rem;
  }
  label{font-weight: 600;}
}
.del_file{
  position: absolute;
  top: 0;
  right: 0;
  padding: .7rem;
  width: 2.9rem;
  button{
    #close{
      width: 1rem;
      height: 1rem;
    }
  }
}
.wp-section{
  .wp{
    border: 1px solid $color-border;
    border-radius: .5rem;
    padding: .7rem;
    position: relative;
    .other_info{
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-end;
      .when, .status,.how{
        flex: 1;
        &:not(:last-child){
        margin-right: .4rem;
      }
      }
    }
    .files_wp{
      @extend .other_info;
      margin-top: 1rem;
    }
    .del_doc{
      @extend .del_file
    }
    h5{margin-top: 1rem;}
  }
}
.delete_working_program {
  position: absolute;
  top: 0;
  right: 0;
}