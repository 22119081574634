.m-gallery {
  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    background: white;
    line-height: 0;
    box-shadow: 0 13px 15px -5px rgba(197,189,234,0.6);
    border-radius: .75rem;
    border: 0;
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    &:hover{
      box-shadow: 0 13px 15px -5px rgba(197,189,234,0.8);
      border: 0;
    }
    &:active,&:focus,&:visited{
      border: 0;
    }
    img {
      border-radius: .75rem;
      border: none;
      object-fit: cover;
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
    }
  }

}
