.g-vacancies {
  margin-top: 1rem;

  &__header-modules {
    @include breakpoint ($for-phone-only) {
      padding: 1rem;
      margin-bottom: 0;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      padding: 1rem;
      margin-bottom: 0;
      display: inline-block;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin: 2rem 0;
      display: inline-block;
    }
    @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
      margin: 2rem 0;
      display: inline-block;
    }
  }

  &__add-margin {
    @include breakpoint ($for-phone-only) {
      margin-top: 0;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin-top: 0;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin-top: 4rem;
    }
  }

  &__add-new {
    @include breakpoint ($for-phone-only) {
      margin: 2rem 0;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin: 2rem 0;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin: 2rem 0;
    }
  }
}

.g-vacancy {
  display: flex;
  @include breakpoint ($for-phone-only) {
    flex-flow: column;
    margin-bottom: 1rem;
    padding: 1rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    flex-flow: column;
    margin-bottom: 1rem;
    padding: 1rem;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    flex-flow: row nowrap;
    padding: 0;
    margin-bottom: 2rem;
  }

  &__meta {
    display: flex;
    align-items: center;
    position: relative;
    @include breakpoint ($for-phone-only) {
      flex-flow: row nowrap;
      width: 100%;
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      flex-flow: row nowrap;
      width: 100%;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      flex-flow: column;
      width: auto;
      margin-right: 1.5rem;
    }

    &-icon {
      @include breakpoint ($for-phone-only) {
        display: none;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        display: none;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        display: block;
      }
      width: 6rem;
      height: 6rem;
      margin-bottom: 1rem;

      &-img {
        width: 100%;
      }
    }

    &-date {
      @include breakpoint ($for-phone-only) {
        display: flex;
        flex-flow: row;
        align-items: center;
        font-size: 70%;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        display: flex;
        flex-flow: row;
        align-items: center;
        font-size: 70%;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        display: block;
        font-size: 100%;
      }

      &-number {
        @include breakpoint ($for-phone-only) {
          text-align: left;
          margin-right: .2rem;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          text-align: left;
          margin-right: .5rem;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          text-align: center;
          margin-right: 0;
        }
      }

      &-month-year {
        @include breakpoint ($for-phone-only) {
          text-align: left;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          text-align: left;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          text-align: center;
        }
      }
    }

  }

  &__content {
    &-title {
    }

    &-body {
    }

    &-gallery {
    }

    &-from {
      margin: .5rem 0 1rem 0;

      &__section {
      }
    }

    &__name-company {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
    }

    &-place {
      display: flex;
      flex-flow: row wrap;
      @include breakpoint ($for-phone-only) {

      }
      @include breakpoint ($for-tablet-portrait-up) {

      }
      @include breakpoint ($for-tablet-landscape-up) {
        margin-bottom: .4rem;
      }

      &__region {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
          margin-right: .3rem;
        }
      }

      &__city {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
          margin-right: .3rem;
        }
      }

      &__address {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
      }
    }

    &__action {
      display: flex;
      flex-flow: row wrap;
      margin-top: 1rem;
      align-items: center;
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }

      &-response {
        margin-right: 1.5rem;
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
      }

      &-contacts {
        position: relative;
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }

        span {
          position: relative;
        }

        &-active {
          @include breakpoint ($for-phone-only) {
          }
          @include breakpoint ($for-tablet-portrait-up) {
          }
          @include breakpoint ($for-tablet-landscape-up) {
          }
        }
      }
    }
  }

  &__contacts {
    display: none;
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {

    }
    @include breakpoint ($for-tablet-landscape-up) {
      position: absolute;
      top: -.5rem;
      left: -1rem;
      padding: 2.5rem 1rem 1rem 1rem;
      min-width: 12rem;
    }


    &-fio {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
        margin-bottom: .5rem;
      }
    }

    &-phone {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
    }

    &-email {

    }
  }

  &-detail {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin: 5rem 0;
    }

    &-company {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
        margin-top: 5rem;
        padding: 1rem;
      }

      &__icon {
        @include breakpoint ($for-phone-only) {
          width: 4rem;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          width: 6rem;
        }
        @include breakpoint ($for-tablet-landscape-up) {
           width: 7rem;
        }

        &-img {
          width: 100%;
          @include breakpoint ($for-phone-only) {
          }
          @include breakpoint ($for-tablet-portrait-up) {
          }
          @include breakpoint ($for-tablet-landscape-up) {

          }
        }
      }

      &__meta {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
          margin-top: 1rem;
        }
      }
    }

    .g-vacancy__content-title {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
        margin: 0 0 2rem 0;
      }
    }
  }
}

.g-img-icon {
  width: 6rem;
  height: 6rem;
  margin: .25rem;
}