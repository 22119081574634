.g-header-section {
  position: relative;
  box-sizing: border-box;
  @include breakpoint($for-phone-only) {
    padding-right: 0;
    margin: 0 .25rem;
  }
  @include breakpoint($for-tablet-portrait-up) {
    padding-right: 0;
    margin: 0 .25rem;
  }
  @include breakpoint($for-tablet-landscape-up) {
    width: $small;
    padding: 1rem;

  }
  @include breakpoint($for-desktop-up) {
    width: $middle;
    padding: 1.5rem;

  }
  @include breakpoint($for-big-desktop-up) {
    width: $big;
    padding: 1.5rem;
  }

  img {
    @include breakpoint($for-phone-only) {
      margin: 0;
      height: 15rem;
      width: 100%;
    }
    @include breakpoint($for-tablet-portrait-up) {
      //margin-top: -20%;
      height: 15rem;
      width: 100%;
    }
    @include breakpoint($for-tablet-landscape-up) {
      //margin-top: -20%;
      height: 18rem;
      width: 100%;
    }
    @include breakpoint($for-desktop-up, $for-big-desktop-up) {
      //margin-top: -20%;
      height: 18rem;
      width: 100%;
    }
  }


  &-small {
    @include breakpoint($for-phone-only) {
      height: 5rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      height: 5rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      height: 5rem;
    }
    @include breakpoint($for-desktop-up) {
      height: 5rem;
    }
    @include breakpoint($for-big-desktop-up) {
      height: 5rem;
    }
  }

  &-middle {
    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {
      margin: 1.5rem auto 0 auto;
    }
    @include breakpoint($for-desktop-up) {
      margin: 1.5rem auto 0 auto;
    }
    @include breakpoint($for-big-desktop-up) {
      margin: 1.5rem auto 0 auto;
    }
  }



  &__content {
    @include breakpoint($for-phone-only) {
      width: 100%;
    }
    @include breakpoint($for-tablet-portrait-up) {
      width: 100%;
    }
    @include breakpoint($for-tablet-landscape-up) {
      //width: calc(67% - 6rem);
    }
    @include breakpoint($for-desktop-up) {
      //width: calc(1200px - 13rem);
    }
    @include breakpoint($for-big-desktop-up) {

      //width: calc(1500px - 28rem);
    }
  }
}
