.m-accounts {
  @include breakpoint($for-phone-only) {
  }
  @include breakpoint($for-tablet-portrait-up) {
  }
  @include breakpoint($for-tablet-landscape-up) {

  }
  @include breakpoint($for-desktop-up) {

  }
  @include breakpoint($for-big-desktop-up) {

  }

  &__bg-img {
    z-index: -1;
  }
}