.g-float-tag-menu{
  width: 20rem;
  padding: 1rem;
  position: fixed;
  right: 0;
  top:0;
   ul{
     margin: 0;
    li{
        &:not(:last-child){
          margin-bottom: .5rem;
        }
    }
  }
}