.g-person-name-post {
  @include breakpoint ($for-phone-only) {
    //width: 100%;
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {
    //margin-bottom: .5rem;
  }
  @include breakpoint ($for-big-desktop-up) {
    //margin-bottom: .5rem;
  }
  &__name {
    @include breakpoint ($for-phone-only) {
      font-weight: 600;
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-desktop-up) {
      margin-bottom: .3rem;
    }
    @include breakpoint ($for-big-desktop-up) {
      margin-right: -.15rem;
      margin-bottom: .3rem;
    }
  }
  &__post {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
  &__academic-degree {
    @include breakpoint ($for-phone-only) {
      width: 100%;

    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
  &__rank {
    @include breakpoint ($for-phone-only) {
      width: 100%;

    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
}