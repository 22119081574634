.m-registration {
  @include breakpoint($for-phone-only) {
    background-color: white;
    box-shadow: 0 5px 15px 0 #bababa;
  }
  @include breakpoint($for-tablet-portrait-up) {
    background-color: white;
    box-shadow: 0 5px 15px 0 #bababa;
  }
  @include breakpoint($for-tablet-landscape-up) {
    background-color: transparent;
    box-shadow: none;
  }
  @include breakpoint($for-desktop-up) {
    background-color: transparent;
    box-shadow: none;
  }

  &__fields {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {
    }

    &__status {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {
      }
    }

    &__welcome {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {
      }
    }

    &__waiting {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {
      }
    }

    &__status-error {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {
      }
    }

    &__status-repeat {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {
      }
    }

    &__status-success {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {
      }
    }
  }

  &__link a {
    font-size: .75rem;
    color: $color-text-grey-light;
    border-bottom: 0;

    &:visited, &:active, &:focus {
      color: $color-text-grey-light;
    }

    &:hover {
      color: $color-text-orange-light;
      cursor: pointer;
    }
  }
}