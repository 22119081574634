.m-slogan {
  font-family: 'Futura PT Book', sans-serif;
  @include breakpoint($for-phone-only) {
    font-size: 1em;
  }
  @include breakpoint($for-tablet-portrait-up) {
    font-size: 1em;
  }
  @include breakpoint($for-tablet-landscape-up) {
    font-size: 1.125em;
  }
  @include breakpoint($for-desktop-up) {
    font-size: 1.125em;
  }

  a {
    border-bottom: 0;
  }

  &__two {
    font-family: 'Futura PT Book', sans-serif;
    color: $color-text-grey;
    @include breakpoint($for-phone-only) {
      font-size: 1em;
    }
    @include breakpoint($for-tablet-portrait-up) {
      font-size: 1em;
    }
    @include breakpoint($for-tablet-landscape-up) {
      font-size: 1.125em;
    }
    @include breakpoint($for-desktop-up) {
      font-size: 1.125em;
    }
  }
}