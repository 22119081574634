.m-number_of_sets {
  @include breakpoint ($for-phone-only) {

  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {

  }
  @include breakpoint ($for-desktop-up) {

  }
  @include breakpoint ($for-big-desktop-up) {

  }
  &__counter{
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up) {

    }
    @include breakpoint ($for-big-desktop-up) {

    }
    &-all {
      font-weight: 600;
      font-size: 1em;
      @include breakpoint ($for-phone-only) {

      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {

      }
      @include breakpoint ($for-desktop-up) {

      }
      @include breakpoint ($for-big-desktop-up) {

      }
    }
    &-place {
      font-size: .7em;
      @include breakpoint ($for-phone-only) {

      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {

      }
      @include breakpoint ($for-desktop-up) {

      }
      @include breakpoint ($for-big-desktop-up) {

      }
    }
  }
  &__funding {
    font-size: 1em;
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up) {

    }
    @include breakpoint ($for-big-desktop-up) {

    }
  }
}