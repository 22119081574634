.m-list-oop {
  @include breakpoint ($for-phone-only) {

  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {

  }
  @include breakpoint ($for-desktop-up) {

  }
  @include breakpoint ($for-big-desktop-up) {

  }
  &__header {
    @include breakpoint ($for-phone-only) {
      
    }
    @include breakpoint ($for-tablet-portrait-up) {
      
    }
    @include breakpoint ($for-tablet-landscape-up) {
      
    }
    @include breakpoint ($for-desktop-up) {
      
    }
    @include breakpoint ($for-big-desktop-up) {
      
    }
    &-title {
      font-weight: 600;
      @include breakpoint ($for-phone-only) {
        font-size: 1em;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        font-size: 1em;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        font-size: 1em;
      }
      @include breakpoint ($for-desktop-up) {
        font-size: 1em;
      }
      @include breakpoint ($for-big-desktop-up) {
        font-size: 1em;
      }
    }
    &-subtititle {
      @include breakpoint ($for-phone-only) {
        font-size: .7em;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        font-size: .7em;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        font-size: .7em;
      }
      @include breakpoint ($for-desktop-up) {
        font-size: .7em;
      }
      @include breakpoint ($for-big-desktop-up) {
        font-size: .7em;
      }
    }
  }
  &__item {
      @include breakpoint ($for-phone-only) {
        
        box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
        border-radius: .75rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        
        box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
        border-radius: .75rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        
        box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
        border-radius: .75rem;
      }
      @include breakpoint ($for-desktop-up) {
        border: .01rem solid $color-border-light;
        box-shadow: 0 13px 15px -5px rgba(197, 189, 234, 0.2);
        border-radius: .75rem;
      }
      @include breakpoint ($for-big-desktop-up) {
        border: .01rem solid $color-border-light;
        box-shadow: 0 13px 15px -5px rgba(197, 189, 234, 0.2);
        border-radius: .75rem;
      }
    &-name a {
      font-family: 'Futura PT Book',sans-serif;
      font-weight: 600;
      @include breakpoint ($for-phone-only) {
        font-size: 1rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        font-size: 1rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        font-size: 1.15rem;
      }
      @include breakpoint ($for-desktop-up) {
        font-size: 1.15rem;
      }
      @include breakpoint ($for-big-desktop-up) {
        font-size: 1.15rem;
      }
    }
    &-code_level {
    }
    &-code {
      color: $color-text-grey;
      @include breakpoint ($for-phone-only) {
        font-size: .8rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        font-size: .8rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        font-size: .8rem;
      }
      @include breakpoint ($for-desktop-up) {
        font-size: .8rem;
      }
      @include breakpoint ($for-big-desktop-up) {
        font-size: .8rem;
      }
    }
    &-level {
      color: $color-text-grey;
      @include breakpoint ($for-phone-only) {
        font-size: .8rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        font-size: .8rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        font-size: .8rem;
      }
      @include breakpoint ($for-desktop-up) {
        font-size: .8rem;
      }
      @include breakpoint ($for-big-desktop-up) {
        font-size: .8rem;
      }
    }
    &-profiles {
      @include breakpoint ($for-phone-only) {

      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {

      }
      @include breakpoint ($for-desktop-up) {

      }
      @include breakpoint ($for-big-desktop-up) {

      }
      &__header {
        @include breakpoint ($for-phone-only) {
          font-size: .9rem;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          font-size: .9rem;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          font-size: .9rem;
        }
        @include breakpoint ($for-desktop-up) {
          font-size: .9rem;
        }
        @include breakpoint ($for-big-desktop-up) {
          font-size: .9rem;
        }
      }
      &__content {

      }
    }
  }
}