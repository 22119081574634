.g-list-oop {
  @include breakpoint($for-phone-only) {
    //padding: 1rem;
    margin-top: 1rem;
  }
  @include breakpoint($for-tablet-portrait-up) {
    //padding: 1rem;
    margin-top: 1rem;
  }
  @include breakpoint($for-tablet-landscape-up) {
    //padding: 1rem;
    margin-top: 1rem;
  }
  @include breakpoint($for-desktop-up) {
    //padding: 0;
    margin-top: 1rem;
  }
  @include breakpoint($for-big-desktop-up) {
    //padding: 0;
    margin-top: 1rem;
  }

  &__header {
    @include breakpoint($for-phone-only) {
      padding: 1rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      padding: 1rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      margin-bottom: 1rem;
      padding: 1rem;
    }
    @include breakpoint($for-desktop-up) {
      margin-bottom: 1rem;
      padding: 0;
    }
    @include breakpoint($for-big-desktop-up) {
      margin-bottom: 1rem;
      padding: 0;
    }

    &-title {
    }

    &-subtititle {
    }
  }

  &__wrapper {
    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
    @include breakpoint($for-big-desktop-up) {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }

  &__item {
    @include breakpoint($for-phone-only) {
      padding: 1rem;
      margin: .5rem .25rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      padding: 1rem;
      margin: .5rem .25rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      padding: 1rem;
      margin-bottom: 1rem;
    }
    @include breakpoint($for-desktop-up) {
      margin-bottom: 1rem;
      width: 44.5%;
      padding: 1.5rem;
    }
    @include breakpoint($for-big-desktop-up) {
      margin-bottom: 1rem;
      width: 45.5%;
      padding: 1.5rem;

    }

    &-name {
      @include breakpoint($for-phone-only) {
        margin-bottom: .5rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        margin-bottom: .5rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        margin-bottom: .5rem;
      }
      @include breakpoint($for-desktop-up) {
        margin-bottom: .5rem;
      }
      @include breakpoint($for-big-desktop-up) {
        margin-bottom: .5rem;
      }
    }

    &-code_level {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {

      }
      @include breakpoint($for-big-desktop-up) {


      }
    }

    &-code {
      display: inline-block;
      margin-right: .5rem;
    }

    &-level {
      display: inline-block;
    }

    &-profiles {
      @include breakpoint($for-phone-only) {
        margin-top: .8rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        margin-top: .8rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        margin-top: .8rem;
      }
      @include breakpoint($for-desktop-up) {
        margin-top: .8rem;
      }
      @include breakpoint($for-big-desktop-up) {
        margin-top: .8rem;
      }

      &__header {
      }

      &__content {
        @include breakpoint($for-phone-only) {
          display: flex;
          flex-flow: column;
        }
        @include breakpoint($for-tablet-portrait-up) {
          display: flex;
          flex-flow: column;
        }
        @include breakpoint($for-tablet-landscape-up) {
          display: flex;
          flex-flow: row nowrap;
        }
        @include breakpoint($for-desktop-up) {
          display: flex;
          flex-flow: row nowrap;
        }
        @include breakpoint($for-big-desktop-up) {
          display: flex;
          flex-flow: row nowrap;
        }
      }
    }
  }
}