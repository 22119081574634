.m-header-wrapper {

  &__top-bar {
    border-bottom: 1px solid #F3F3F8;
  }

  &__bottom {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {

    }
    @include breakpoint($for-big-desktop-up) {

    }

    &-item {
      font-size: .625rem;
      color: $color-text-grey-light;
      border-bottom: 1px solid $color-text-grey-underline-light;

      &:visited, &:active, &:focus {
        color: $color-text-grey-light;
      }

      &:hover {
        color: $color-text-orange-light;
      }
    }


    &-bar {
    }
  }
}