.g-news {
  @include breakpoint($for-phone-only) {

  }
  @include breakpoint($for-tablet-portrait-up) {

  }
  @include breakpoint($for-tablet-landscape-up) {

  }

  &__home {
    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {
      padding-top: 3rem;
    }
  }


  &__header-modules {
    @include breakpoint($for-phone-only) {
      padding: 1rem;
      margin-top: 2rem;
      margin-bottom: -1rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      padding: 1rem;
      margin-bottom: 0;
      display: inline-block;
    }
    @include breakpoint($for-tablet-landscape-up) {
      margin: 3rem 0 2.5rem 0;
      display: inline-block;
      padding: 0;
    }
    @include breakpoint($for-desktop-up, $for-big-desktop-up) {
      margin: 3rem 0 2.5rem 0;
      padding: 0;
      display: inline-block;
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: .325rem;
        margin-top: .25rem;
      }
    }
  }

  &__add-margin {
    @include breakpoint($for-phone-only) {
      margin-top: 0;
    }
    @include breakpoint($for-tablet-portrait-up) {
      margin-top: 0;
    }
    @include breakpoint($for-tablet-landscape-up) {
      margin-top: 4rem;
    }
  }

  &__action {
    @include breakpoint($for-phone-only) {
    margin: .5rem 0 .5rem .5rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
    margin: .25rem 0 .25rem .25rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      position: static;
      margin: .5rem 0 0 0;
    }

    &-add {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
    }

    &-all {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
    }
  }
}

.g-article {
  @include breakpoint($for-phone-only) {
    margin: .5rem .25rem;
    padding: 1rem;
  }
  @include breakpoint($for-tablet-portrait-up) {
    margin: .5rem .25rem;
    padding: 1rem;
  }
  @include breakpoint($for-tablet-landscape-up) {
    padding: 0;
    margin: 0 0 3rem 0;
  }

  &__meta {
    display: flex;
    position: relative;
    flex-flow: row nowrap;
    @include breakpoint($for-phone-only) {
      width: 100%;
      margin-bottom: .5rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      width: 100%;
      margin-bottom: 1rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      margin-bottom: 1rem;
    }

    &-wrapper {
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
    }

    &-icon {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      width: 2rem;
      height: 2rem;
      margin-right: .75rem;

      &-img {
        width: 100%;
      }
    }

    &-date {
      display: flex;
      @include breakpoint($for-phone-only) {
        flex-flow: row;
        align-items: center;
        font-size: 70%;
      }
      @include breakpoint($for-tablet-portrait-up) {
        flex-flow: row;
        align-items: center;
        font-size: 70%;
      }
      @include breakpoint($for-tablet-landscape-up) {
        font-size: 100%;
        justify-content: flex-end;
      }

    }

    &-btn {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
        display: flex;
        margin-bottom: .5rem;
        position: relative;
      }
      @include breakpoint($for-desktop-up) {
        display: flex;
        margin-bottom: .25rem;
        position: relative;
      }
      @include breakpoint($for-big-desktop-up) {
        display: flex;
        margin-bottom: .25rem;
        position: relative;
      }


      &-editing {

      }

      &-confirm {

      }

      &-en {

      }

      &-ch {

      }
    }
  }

  &__content {
    &-title {
      &-link {
        padding-bottom: 0.1665em;
      }
    }

    &-body {
      //padding: .875rem 0;
      img {
        width: 6rem !important;
        height: 6rem !important;;
        margin-right: 1rem !important;
      }
    }

    &-gallery {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
        margin-top: 2rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        margin-top: 2rem;
      }

      &__title {
        margin-bottom: 1rem;
      }
    }

    &-from {
      margin: .5rem 0 1rem 0;

      &__section {
      }
    }

    &-tags {
      display: flex;
      flex-flow: row wrap;
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
    }
  }

  &-detail {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
      margin: 5rem 0;
    }

    .g-article__content-title {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
        margin: 0 0 2rem 0;
      }
    }

  }
}

.g-img-icon {
  width: 6rem;
  height: 6rem;
  margin: .25rem;
}