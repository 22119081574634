.m-button-navigation {
  &-mobile {
    z-index: 200000;
    background-color: $color-dark-blue;
    border-radius: .75rem;
    .icon-menu, .icon-close {
        fill: white;
    }
  }
  &-white{
      .icon-menu, .icon-close {
        fill: white;
    }

  }
  &-black{
    .icon-menu, .icon-close {
        fill: $color-icon;
    }
  }
}


