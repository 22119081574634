.m-list-employees {
  @include breakpoint($for-phone-only) {

  }
  @include breakpoint($for-tablet-portrait-up) {

  }
  @include breakpoint($for-tablet-landscape-up) {

  }
  @include breakpoint($for-desktop-up) {

  }

  &__header {
    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {
      border-bottom: .01rem solid $color-border;
    }
    @include breakpoint($for-desktop-up) {
      border-bottom: .01rem solid $color-border;
    }

    &-fio {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {
        color: $color-text-grey;
        font-style: italic;
      }
      @include breakpoint($for-desktop-up) {
        color: $color-text-grey;
        font-style: italic;
      }
    }

    &-contact {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {
        color: $color-text-grey;
        font-style: italic;
      }
      @include breakpoint($for-desktop-up) {
        color: $color-text-grey;
        font-style: italic;
      }
    }
  }

  &__load {
    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {

    }
    @include breakpoint($for-desktop-up) {

    }
  }

  &__content {
    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {

    }
    @include breakpoint($for-desktop-up) {

    }
  }

  &__item {
    @include breakpoint($for-phone-only) {
      //background-color: white;
      //box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
      //border-radius: .75rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      //background-color: white;
      //box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
      //border-radius: .75rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      //background-color: transparent;
      //box-shadow: none;
      //border-radius: 0;
    }
    @include breakpoint($for-desktop-up) {
      //background-color: transparent;
      //box-shadow: none;
      //border-radius: 0;
    }

    &-template {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {

      }
    }

    &-img {
      box-shadow: 0 13px 15px -5px rgba(197, 189, 234, 0.7);
      @include breakpoint($for-phone-only) {
        border-radius: 1.25rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        border-radius: 1.25rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        border-radius: 1.25rem;
      }
      @include breakpoint($for-desktop-up) {
        border-radius: 1.5rem;
      }
      @include breakpoint($for-big-desktop-up) {
        border-radius: 1.5rem;

      }

      img {
        @include breakpoint($for-phone-only) {
          border-radius: 1.25rem;
        }
        @include breakpoint($for-tablet-portrait-up) {
          border-radius: 1.25rem;
        }
        @include breakpoint($for-tablet-landscape-up) {
          border-radius: 1.25rem;
        }
        @include breakpoint($for-desktop-up, $for-big-desktop-up) {
          border-radius: 1.5rem;
        }
      }
    }

    &-content {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {

      }

      &__name {
        color: $color-text;
        @include breakpoint($for-phone-only) {
          font-size: 1rem;
          font-weight: 600;
        }
        @include breakpoint($for-tablet-portrait-up) {
          font-size: 1rem;
          font-weight: 600;
        }
        @include breakpoint($for-tablet-landscape-up) {
          font-size: 1rem;
          font-weight: 600;
        }
        @include breakpoint($for-desktop-up) {
          font-size: 1rem;
          font-weight: 600;
        }

        a {
          @include link-colors($color-text, $color-link-hover, $color-link-active, $color-text, $color-text);
          border-color: $color-text-grey-underline-light;

          &:hover, &:active {
            border-color: $color-text-orange-underline-light;
          }

          @include breakpoint($for-phone-only) {
            font-size: 1rem;
            font-weight: 600;
          }
          @include breakpoint($for-tablet-portrait-up) {
            font-size: 1rem;
            font-weight: 600;
          }
          @include breakpoint($for-tablet-landscape-up) {
            font-size: 1rem;
            font-weight: 600;
          }
          @include breakpoint($for-desktop-up) {
            font-size: 1rem;
            font-weight: 600;
          }
        }
      }

      &__post {
        font-size: .75rem;


        @include breakpoint($for-phone-only) {
          //color: $color-dark-blue;
        }
        @include breakpoint($for-tablet-portrait-up) {
          //color: $color-dark-blue;
        }
        @include breakpoint($for-tablet-landscape-up) {
          //color: $color-dark-blue;
        }
        @include breakpoint($for-desktop-up) {
          //color: $color-dark-blue;
        }
      }

      &__department {
        @include breakpoint($for-phone-only) {
          font-size: .75rem;
          font-style: italic;
          a {
            font-size: .75rem;
            @include link-colors($color-link-normal, $color-link-hover, $color-link-active, $color-link-visited, $color-link-focus);
          }
        }
        @include breakpoint($for-tablet-portrait-up) {
          font-size: .75rem;
          font-style: italic;
          a {
            font-size: .75rem;
            @include link-colors($color-link-normal, $color-link-hover, $color-link-active, $color-link-visited, $color-link-focus);
          }
        }
        @include breakpoint($for-tablet-landscape-up) {
          font-size: .75rem;
          font-style: italic;
          a {
            font-size: .75rem;
            @include link-colors($color-link-normal, $color-link-hover, $color-link-active, $color-link-visited, $color-link-focus);
          }
        }
        @include breakpoint($for-desktop-up) {
          font-size: .75rem;
          font-style: italic;
          a {
            font-size: .75rem;
            @include link-colors($color-link-normal, $color-link-hover, $color-link-active, $color-link-visited, $color-link-focus);
          }
        }
      }
    }

    &-contact {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {

      }

      &-phone {
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
        @include breakpoint($for-desktop-up) {

        }
      }

      &-email {
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
        @include breakpoint($for-desktop-up) {

        }
      }

      &-email-3 {
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
        @include breakpoint($for-desktop-up) {

        }
      }
    }
  }
}