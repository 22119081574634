html {
  font-size: 16px;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font: normal 85%/1.5 'Roboto', sans-serif;
  font-weight: 400;
  color: $color-text;

  @include breakpoint($for-phone-only) {
    background: white;
  }
  @include breakpoint($for-tablet-portrait-up) {
    background: white;
  }
  @include breakpoint($for-tablet-landscape-up) {
    //background: url(/static/img/backgrount-right.png) 90% 0 no-repeat;
  }
}

p {
  font-size: 1em;
}

a {
  font-size: 1em;
  border-bottom: 1px solid $color-link-underline;
  text-decoration: none;
  padding-bottom: .25em;
  line-height: 1.7em;
  @include link-colors($color-link-normal, $color-link-hover, $color-link-active, $color-link-visited, $color-link-focus);

  &:hover, &:focus {
    border-color:  $color-text-orange-underline-light;
  }
}

h1 {
  font-size: 3.157em
}

h2 {
  font-size: 2.369em
}

h3 {

  font-family: 'Futura PT', sans-serif;
  @include breakpoint($for-phone-only) {
    font-size: 1.1em;
  }
  @include breakpoint($for-tablet-portrait-up) {
    font-size: 1.333em;
  }
  @include breakpoint($for-tablet-landscape-up) {
    font-size: 1.777em;
  }
}

h4 {
  font-size: 1.333em;
  margin-bottom: 0;
}

h5 {
  font-size: 1em;
  margin: 1.67rem 0 .5rem 0;
}

h6 {
  font-size: 0.75em
}

small {
  font-size: .75em;
}

b, strong {
  font-weight: 600;
}

button {
  font-family: 'Roboto', sans-serif;
}

select {
  background-color: white;
}

ul li, ol li {
  padding-bottom: .6rem;
  line-height: 1.7rem;
}