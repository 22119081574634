.g-languages {
  &__item {
    padding: 0;
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
      margin-right: .75rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      margin-right: .75rem;
    }
  }
}