.g-breadcrumb {
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  padding: 0;
  &__item {
    &:not(:first-child):before {
      display: inline-block;
      margin: 0 .15rem;
    }
    padding-bottom: 0;
    @include breakpoint ($for-phone-only) {
      max-width: 15rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      max-width: 11rem !important;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      max-width: 11rem !important;
    }
    @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
      max-width: 11rem !important;
    }
  }
}