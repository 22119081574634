.m-tabs {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {
  }
  @include breakpoint ($for-big-desktop-up) {
  }
  section {
  }
  input[id^="tab"] {
    &:not(:checked) + label {
      &:hover, &:focus {
        cursor: pointer;
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
        }
        @include breakpoint ($for-big-desktop-up) {
        }
      }
    }
  }
  label[for^="tab"] {
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {

    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up) {

    }
    @include breakpoint ($for-big-desktop-up) {

    }
  }

  input:checked + label[for^="tab"] {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
}

.m-tabs_main {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {
  }
  @include breakpoint ($for-big-desktop-up) {
  }
  section {
  }
  input[id^="tab"] {
    &:not(:checked) + label {
      &:hover, &:focus {
        background-color: $color-border-light;
        @include breakpoint ($for-phone-only) {
          border-radius: 0;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          border-radius: 0;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          border-radius: 1rem;
        }
        @include breakpoint ($for-desktop-up) {
          border-radius: 1rem;
        }
        @include breakpoint ($for-big-desktop-up) {
          border-radius: 1rem;
        }
      }
    }
  }
  label[for^="tab"] {
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {

    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up) {

    }
    @include breakpoint ($for-big-desktop-up) {

    }
  }

  input:checked + label[for^="tab"] {
    background-color: mapget($color-btn, success);
    color: white;
    @include breakpoint ($for-phone-only) {
      border-radius: 0;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      border-radius: 0;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      border-radius: 1rem;
    }
    @include breakpoint ($for-desktop-up) {
      border-radius: 1rem;
    }
    @include breakpoint ($for-big-desktop-up) {
      border-radius: 1rem;
    }
  }
}
