.g-structura {
  ul {
    //padding: 0;
    li {
      //padding: 0;
      list-style-type: none;

    }
  }
  @include breakpoint ($for-phone-only) {
    padding: 1rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    padding: 1rem;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    padding: 0 1rem;
  }
  @include breakpoint ($for-desktop-up) {
    padding: 0;
  }
  @include breakpoint ($for-big-desktop-up) {
    padding: 0;
  }
  &__filter-list {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
  &__filter_input {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
  &__type {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
  &__item {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
    &-name {
      @include breakpoint ($for-phone-only) {
        line-height: 1.5em;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        line-height: 1.5em;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        line-height: 1.5em;
      }
      @include breakpoint ($for-desktop-up) {
        line-height: 2em;
      }
      @include breakpoint ($for-big-desktop-up) {
        line-height: 2em;
      }
    }
    &-open__child {
      @include breakpoint ($for-phone-only) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 1.388rem;
        height: 100%;
        text-align: center;
        border-radius: 0;
        padding-bottom: 0;
        margin-right: .5rem;
        position: absolute;
        top: 0;
        left: 0;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 1.388rem;
        height: 100%;
        text-align: center;
        border-radius: 0;
        padding-bottom: 0;
        margin-right: .5rem;
        position: absolute;
        top: 0;
        left: 0;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        display: inline-block;
        width: 1.388rem;
        height: 1.188rem;
        text-align: center;
        border-radius: 1rem;
        padding-bottom: .2rem;
        margin-right: .5rem;
        position: static;
      }
      @include breakpoint ($for-desktop-up) {
        display: inline-block;
        width: 1.388rem;
        height: 1.188rem;
        text-align: center;
        border-radius: 1rem;
        padding-bottom: .2rem;
        margin-right: .5rem;
        position: static;
      }
      @include breakpoint ($for-big-desktop-up) {
        display: inline-block;
        width: 1.388rem;
        height: 1.188rem;
        text-align: center;
        border-radius: 1rem;
        padding-bottom: .2rem;
        margin-right: .5rem;
        position: static;
      }
    }
    &-no-child {
      @include breakpoint ($for-phone-only) {
        margin: 0;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        margin: 0;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        margin-left: 2.1rem;
      }
      @include breakpoint ($for-desktop-up) {
        margin-left: 2.1rem;
      }
      @include breakpoint ($for-big-desktop-up) {
        margin-left: 2.1rem;

      }
    }
    &-parent {
      @include breakpoint ($for-phone-only) {
        //padding: .8rem 0;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        position: relative;
        .g-structura__item-info {
          margin-left: .5rem;
        }
        li {
          padding: .4rem 0 .4rem 2rem;
        }
      }
      @include breakpoint ($for-tablet-portrait-up) {
        position: relative;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        .g-structura__item-info {
          margin-left: .5rem;
        }
        li {
          padding: .4rem 0 .4rem 2rem;
        }
      }
      @include breakpoint ($for-tablet-landscape-up) {
        padding: 0;
        position: static;
        .g-structura__item-info {
          margin-left: 2.4rem;
        }
        li {
          padding-left: 0;
        }
      }
      @include breakpoint ($for-desktop-up) {
        position: static;
        padding: 0;
        .g-structura__item-info {
          margin-left: 2.4rem;
        }
        li {
          padding-left: 0;
        }
      }
      @include breakpoint ($for-big-desktop-up) {
        position: static;
        padding: 0;
        .g-structura__item-info {
          margin-left: 2.4rem;
        }
        li {
          padding-left: 0;
        }
      }
    }
    &-child {
      display: none;
      @include breakpoint ($for-phone-only) {
        padding: 0 0 .5rem .5rem;
        li {
          padding-left: 0;
          padding-top: .5rem;
          padding-bottom: .5rem;
          margin-top: .5rem;
        }
      }
      @include breakpoint ($for-tablet-portrait-up) {
        padding: 0 0 .5rem .5rem;
        li {
          padding-left: 0;
          padding-top: 0;
          //padding-top: .5rem;
          //padding-bottom: .5rem;
          padding-bottom: 0;
          margin-top: .5rem;

        }
      }
      @include breakpoint ($for-tablet-landscape-up) {
        margin-left: .75rem;
        padding-left: 1.5rem;
        .g-structura__item-info {
          margin-left: .5rem;
        }
      }
      @include breakpoint ($for-desktop-up) {
        margin-left: .75rem;
        padding-left: 1.5rem;
        .g-structura__item-info {
          margin-left: .5rem;
        }
      }
      @include breakpoint ($for-big-desktop-up) {
        margin-left: .75rem;
        padding-left: 1.5rem;
        .g-structura__item-info {
          margin-left: .5rem;
        }
      }
      & > li {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
          margin: .5rem 0;
        }
        @include breakpoint ($for-desktop-up) {
          //margin: .5rem 0;
          margin: 0;
        }
        @include breakpoint ($for-big-desktop-up) {
          //margin: .5rem 0;
          margin: 0;
        }
      }
    }
    &-info {
      display: none;
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
        margin: 0;
        //margin-top: .5rem;
      }
      @include breakpoint ($for-desktop-up) {
        //margin-top: .5rem;
        margin: 0;
      }
      @include breakpoint ($for-big-desktop-up) {
        //margin-top: .5rem;
        margin: 0;
      }
      &__item {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
        }
        @include breakpoint ($for-big-desktop-up) {
        }
      }
    }
  }
  &__item-info-icon {
    @include breakpoint ($for-phone-only) {
      display: inline-block;
      width: .85rem;
      height: .85rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      display: inline-block;
      width: .85rem;
      height: 1rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      display: inline-block;
      width: .85rem;
      height: .85rem;
    }
    @include breakpoint ($for-desktop-up) {
      display: inline-block;
      width: .85rem;
      height: .85rem;
    }
    @include breakpoint ($for-big-desktop-up) {
      display: inline-block;
      width: .85rem;
      height: .85rem;
    }
  }

}