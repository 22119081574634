.g-slider {
  &-empty {
    position: absolute;
    background: {
      image: url("../img/slider_empty.jpg");
      repeat: no-repeat;
      position: center;
      size: cover;
    }
    @include breakpoint ($for-phone-only) {
      height: 14rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
  }

  &-news {
    .fotorama__img {
      height: 100% !important;
      top: 0 !important;
    }

    @include breakpoint ($for-phone-only) {
      position: relative;
      z-index: 5000;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      position: relative;
      z-index: 5000;

    }
    @include breakpoint ($for-tablet-landscape-up) {
    }

    .item_fotorama {
      position: relative;
      @include breakpoint ($for-phone-only) {
        height: 14rem !important;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        height: 15rem !important;
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
    }

    .fotorama__stage {
      @include breakpoint ($for-phone-only) {
        height: 14rem !important;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        height: 15rem !important;
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
    }

    .fotorama__nav-wrap {
      @include breakpoint ($for-phone-only) {
        display: none;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        display: none;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        display: block;
      }
    }

    &__title {
      display: inline-block;
      max-width: 90%;
      @include breakpoint ($for-phone-only) {
        margin: 2rem 1rem .5rem 1rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        margin: 5rem 1rem .5rem 1rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        padding-bottom: .2rem;
        margin: 5rem 1rem .5rem 1rem;
      }
    }

    &__text {
      display: inline-block;
      margin: 0 1rem;
      width: 95%;
      @include breakpoint ($for-phone-only) {
        height: 6rem;
        width: 90%;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        height: 5.2rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        height: 5rem;
      }
      @include breakpoint ($for-desktop-up) {
        height: 5rem;
      }
    }
  }
}


#slider_empty {
  width: 97.7%;
  height: 230px;
  margin-bottom: 30px;
  position: absolute;
  background: {
    image: url("../img/slider_empty.jpg");
    repeat: no-repeat;
    position: center;
    size: cover;
  }
  border-radius: 1rem;
  opacity: 0.75;
}

