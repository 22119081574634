.m-celebrations {
  &__8march {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {

    }
    @include breakpoint($for-desktop-up) {
    }
  }

  &_new_year {
    &__show {
      background-image: url("/static/img/UlSU-logo/new_year_snow.svg");
      background-size: 100%;
      background-repeat: no-repeat;
    }

    &__tree {
      background-image: url("/static/img/UlSU-logo/new_year_tree.svg");
      background-size: 100%;
      background-repeat: no-repeat;
    }

  }
}