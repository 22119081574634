.g-manager-module {
  @include breakpoint ($for-phone-only) {
    padding: 1rem;
    margin: 1rem .25rem 0 .25rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    padding: 1rem;
    margin-top: 1rem;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    padding: 0;
    margin: 0 0 2rem 0;
  }
  @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
    padding: 0;
    margin: 0 0 2rem 0;
  }
  &__title {
    @include breakpoint ($for-phone-only) {
      margin-bottom: 1rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin-bottom: 1em;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin-bottom: 1rem;
    }
    @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
      margin-bottom: 1rem;
    }
  }
  &__manager-section {
    @include breakpoint ($for-phone-only) {
      margin: 0 0 1rem 0;
      display: inline-block;
      &:first-child {
        margin: 0;
      }
      &:last-child {
        margin: 0;
      }
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin: 0 1rem 1rem  0;
      display: inline-block;
      &:first-child {
        margin: 0;
      }
      &:last-child {
        margin: 0;
      }
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin: 0 1rem 2rem  0;
      display: inline-block;
      &:first-child {
        margin: 0;
      }
      &:last-child {
        margin: 0;
      }
    }
    @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
      margin: 0 0 2rem 0;
      display: block;
      &:first-child {
        margin: 0;
      }
      &:last-child {
        margin: 0;
      }
    }
    &__photo {
      @include breakpoint ($for-phone-only) {
        width: 5rem;
        height: 5rem;
        margin-bottom: .5rem;
        img {
          width: 100%;
        }
      }
      @include breakpoint ($for-tablet-portrait-up) {
        width: 5rem;
        height: 5rem;
        margin-bottom: .5rem;
        img {
          width: 100%;
        }
      }
      @include breakpoint ($for-tablet-landscape-up) {
        width: 5rem;
        height: 5rem;
        margin-bottom: .5rem;
        img {
          width: 100%;
        }
      }
      @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
        width: 5rem;
        height: 5rem;
        margin-bottom: .5rem;
        img {
          width: 100%;
        }
      }
    }
    &__post {
      @include breakpoint ($for-phone-only) {

      }
      @include breakpoint ($for-tablet-portrait-up) {

      }
      @include breakpoint ($for-tablet-landscape-up) {

      }
      @include breakpoint ($for-desktop-up, $for-big-desktop-up) {

      }
    }
    &__name {
      margin-bottom: .5rem;
      @include breakpoint ($for-phone-only) {

      }
      @include breakpoint ($for-tablet-portrait-up) {

      }
      @include breakpoint ($for-tablet-landscape-up) {

      }
      @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
      }
    }

  }
}