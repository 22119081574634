.m-oop {
  &__description {
    @include breakpoint ($for-phone-only) {
      background-color: white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
      border-radius: .75rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      background-color: white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    }
    @include breakpoint ($for-tablet-landscape-up) {
      background-color: transparent;
      box-shadow: none;
    }
    @include breakpoint ($for-desktop-up) {
      background-color: transparent;
      box-shadow: none;
    }
    @include breakpoint ($for-big-desktop-up) {
      background-color: transparent;
      box-shadow: none;
    }
  }

  &__passport-min {
    @include breakpoint ($for-phone-only) {
      background-color: white;
      border-radius: .75rem;
      box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    }
    @include breakpoint ($for-tablet-portrait-up) {
      background-color: white;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      background-color: transparent;
    }
    @include breakpoint ($for-desktop-up) {
      background-color: transparent;
    }
    @include breakpoint ($for-big-desktop-up) {
      background-color: transparent;
    }

    &__list-years {
      z-index: 10;
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {

      }
      @include breakpoint ($for-desktop-up) {

      }
      @include breakpoint ($for-big-desktop-up) {

      }

      &__item {
        cursor: pointer;

        &:hover {
          border-color: $color-light-blue;
        }

        @include breakpoint ($for-phone-only) {
          font-size: 1.2em;
          border-bottom: .01rem solid transparent;
          border-right: none;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          font-size: 1.2em;
          border-bottom: .01rem solid transparent;
          border-right: none;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          font-size: 1.2em;
          border-right: .01rem solid transparent;
          border-bottom: none;
        }
        @include breakpoint ($for-desktop-up) {
          font-size: 1.2em;
          border-right: .01rem solid transparent;
          border-bottom: none;
        }
        @include breakpoint ($for-big-desktop-up) {
          font-size: 1.2em;
          border-right: .01rem solid transparent;
          border-bottom: none;
        }

        &-now {
          a:hover {
            text-decoration: none;
          }

          a:focus {
            text-decoration: none;
          }

          @include breakpoint ($for-phone-only) {
          }
          @include breakpoint ($for-tablet-portrait-up) {
          }
          @include breakpoint ($for-tablet-landscape-up) {

          }
          @include breakpoint ($for-desktop-up) {

          }
          @include breakpoint ($for-big-desktop-up) {

          }
        }

        &-next {
          a:hover {
            text-decoration: none;
          }

          a:focus {
            text-decoration: none;
          }

          a:active {
            text-decoration: none;
          }

          @include breakpoint ($for-phone-only) {
            font-size: .6rem;
            color: $color-text-grey;
            text-align: right;
          }
          @include breakpoint ($for-tablet-portrait-up) {
            font-size: .6rem;
            color: $color-text-grey;
            text-align: right;
          }
          @include breakpoint ($for-tablet-landscape-up) {
            font-size: .6rem;
            color: $color-text-grey;
            text-align: right;
          }
          @include breakpoint ($for-desktop-up) {
            font-size: .6rem;
            color: $color-text-grey;
            text-align: right;

          }
          @include breakpoint ($for-big-desktop-up) {
            font-size: .6rem;
            color: $color-text-grey;
            text-align: right;
          }
        }
      }

      &-content {
        z-index: 1;
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
          border-left: .01rem solid $color-border;
        }
        @include breakpoint ($for-desktop-up) {
          border-left: .01rem solid $color-border;
        }
        @include breakpoint ($for-big-desktop-up) {
          border-left: .01rem solid $color-border;
        }
      }

      .m-tabs-active {
        color: $color-dark-blue;
        font-weight: 600;

        &:hover {
          border-color: $color-light-blue;
        }

        @include breakpoint ($for-phone-only) {
          border-top: .1rem solid $color-border-pagination-hover;
          margin-top: -1rem;
          border-right: none;
          padding-top: .9rem;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          border-top: .1rem solid $color-border-pagination-hover;
          margin-top: -1rem;
          border-right: none;
          padding-top: .9rem;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          border-right: .01rem solid $color-border-pagination-hover;
          border-bottom: none;
          border-top: 0;
        }
        @include breakpoint ($for-desktop-up) {
          border-right: .01rem solid $color-border-pagination-hover;
          border-bottom: none;
          border-top: 0;
        }
        @include breakpoint ($for-big-desktop-up) {
          border-right: .01rem solid $color-border-pagination-hover;
          border-bottom: none;
          border-top: 0;
        }
      }
    }
  }

  &-basic-info {
    @include breakpoint ($for-phone-only) {
      background-color: white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
      border-radius: .75rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      background-color: white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    }
    @include breakpoint ($for-tablet-landscape-up) {
      background-color: transparent;
      box-shadow: none;
    }
    @include breakpoint ($for-desktop-up) {
      background-color: transparent;
      box-shadow: none;
    }
    @include breakpoint ($for-big-desktop-up) {
      background-color: transparent;
      box-shadow: none;
    }

    &__item {
      a {
        font-weight: 600;
        font-family: 'Century Gothic', sans-serif;
        cursor: pointer;
        @include link-colors($color-dark-blue, $color-light-blue, $color-dark-blue, $color-dark-blue, $color-dark-blue);
      }

      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {

      }
      @include breakpoint ($for-desktop-up) {

      }
      @include breakpoint ($for-big-desktop-up) {

      }
    }
  }

  &-accreditation {
    font-size: .9rem;
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
}
