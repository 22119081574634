.g-name-university{
  width: 100%;
  @include breakpoint ($for-phone-only) {
    margin: 0;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    margin: 0;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    margin-bottom: .75rem;
  }
  @include breakpoint ($for-desktop-up) {
    margin-bottom: .75rem;
  }
  @include breakpoint ($for-big-desktop-up) {
    margin-bottom: .75rem;
  }
}