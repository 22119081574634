.js-sort-items {
  cursor: pointer;
}
.icon_publication_base {
  width: 10px;
}
.g-sponsors_employees{
  .search-input{
    width: 100%;
  }
  .ms-list{
    margin-top: .25rem;
  }
}