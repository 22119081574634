.g-lk {
  @include breakpoint($for-phone-only) {
  }
  @include breakpoint($for-tablet-portrait-up) {
  }
  @include breakpoint($for-tablet-landscape-up) {
  }
  @include breakpoint($for-desktop-up) {
  }
  @include breakpoint($for-big-desktop-up) {
  }

  &__img {

    @include breakpoint($for-phone-only) {
      width: 6.25rem;
      height: 6.25rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      width: 6.25rem;
      height: 6.25rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      width: 12.25rem;
      height: 12.25rem;
    }
    @include breakpoint($for-desktop-up) {
      width: 12.25rem;
      height: 12.25rem;
    }
    @include breakpoint($for-big-desktop-up) {
      width: 12.25rem;
      height: 12.25rem;
    }

    &__no-auth {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {

      }
      @include breakpoint($for-big-desktop-up) {

      }
    }

    &__auth {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {

      }
      @include breakpoint($for-big-desktop-up) {

      }
    }
  }

  &__name_post {
    margin-bottom: 0;
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {


    }
    @include breakpoint($for-tablet-landscape-up) {

    }
    @include breakpoint($for-desktop-up) {

    }
    @include breakpoint($for-big-desktop-up) {

    }

    &__no-auth {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {

      }
      @include breakpoint($for-big-desktop-up) {

      }
    }

    &__auth {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {

      }
      @include breakpoint($for-big-desktop-up) {

      }
    }
  }

  &__left-icon {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {
    }

    &__no-auth {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {
      }
    }

    &__auth {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {
      }
    }
  }
}