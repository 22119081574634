.g-search-opp {

  @include breakpoint($for-phone-only) {
    margin: 1rem 0 0 0;
  }
  @include breakpoint($for-tablet-portrait-up) {
    margin: 1rem 0 0 0;
  }
  @include breakpoint($for-tablet-landscape-up) {
    margin: 1rem 0 0 0;
  }
  @include breakpoint($for-desktop-up) {
    margin: 1rem 0 4rem 0;
  }
  @include breakpoint($for-big-desktop-up) {
    margin: 1rem 0 4rem 0;
  }
}