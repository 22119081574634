.m-person-name-post {
  @include breakpoint($for-phone-only) {
  }
  @include breakpoint($for-tablet-portrait-up) {
  }
  @include breakpoint($for-tablet-landscape-up) {
  }
  @include breakpoint($for-desktop-up) {
  }
  @include breakpoint($for-big-desktop-up) {
  }

  &__name {
    font-family: 'Futura PT', sans-serif;
    @include breakpoint($for-phone-only) {
      font-size: 1.85rem;
      line-height: 1.85rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      font-size: 1.85rem;
      line-height: 1.85rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      font-size: 1.875rem;
      line-height: 1.875rem;
    }
    @include breakpoint($for-desktop-up, $for-big-desktop-up) {
      font-size: 1.875rem;
      line-height: 1.875rem;
    }
  }

  &__post {
    font-style: italic;
    line-height: 1.3rem;
    font-size: .9rem;
    font-weight: 400;
    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {

    }
    @include breakpoint($for-desktop-up) {

    }
    @include breakpoint($for-big-desktop-up) {

    }
  }

  &__academic-degree {
    line-height: 1.3rem;
    font-style: italic;
    font-size: .9rem;
    font-weight: 400;
    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {

    }
    @include breakpoint($for-desktop-up) {

    }
    @include breakpoint($for-big-desktop-up) {

    }
  }

  &__rank {
    font-size: .9rem;
    line-height: 1.3rem;
    font-style: italic;
    font-weight: 400;
    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {

    }
    @include breakpoint($for-desktop-up) {

    }
    @include breakpoint($for-big-desktop-up) {

    }
  }
}