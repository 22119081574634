.g-navigation {
  &__list-item {
    //height: 105%;
  }

  &-document-group {
    //@include breakpoint ($for-phone-only) {
    //  padding: 1rem;
    //}
    //@include breakpoint ($for-tablet-portrait-up) {
    //  padding: 1rem;
    //}
    //@include breakpoint ($for-tablet-landscape-up) {
    //  padding: 1rem;
    //  margin: 0 0 1rem 0;
    //}
    //@include breakpoint ($for-desktop-up) {
    //  padding: 0;
    //}
    //@include breakpoint ($for-big-desktop-up) {
    //  padding: 0;
    //}
  }

  &__section {
    &-item {
      &:not(:last-child) {
        @include breakpoint($for-phone-only) {
          margin-bottom: 1rem;
        }
        @include breakpoint($for-tablet-portrait-up) {
          margin-bottom: 1rem;
        }
        @include breakpoint($for-tablet-landscape-up) {
          margin-bottom: .5rem;
        }
      }

      @include breakpoint($for-phone-only) {
        //display: block;
        margin-bottom: 1rem;
        line-height: 1.5em;
      }
      @include breakpoint($for-tablet-portrait-up) {
       // display: block;
        margin-bottom: 1rem;
        line-height: 1.5em;
      }
      @include breakpoint($for-tablet-landscape-up) {
        //display: block;
      }

      &-row {
        @include breakpoint($for-tablet-landscape-up) {
          display: inline-block;
          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  @include breakpoint($for-phone-only) {
    padding: 0;
  }
  @include breakpoint($for-tablet-portrait-up) {
    padding: 0;
  }
  @include breakpoint($for-tablet-landscape-up) {
    padding: 0 1rem;
    margin-bottom: 1.5rem;
    margin-top: 2.5rem;
  }
  @include breakpoint($for-desktop-up) {
    padding: 0;
    margin-bottom: 2rem;
    margin-top: 2.5rem;
  }
  @include breakpoint($for-big-desktop-up) {
    padding: 0;
    margin-bottom: 2rem;
    margin-top: 2.5rem;
  }

  &-module {
    display: flex;
    position: relative;
    @include breakpoint($for-phone-only) {
      flex-flow: column;
    }
    @include breakpoint($for-tablet-portrait-up) {
      flex-flow: column;
    }
    @include breakpoint($for-tablet-landscape-up) {
      flex-flow: row nowrap;
      align-items: center;
    }

    &__edit {
      @include breakpoint($for-phone-only) {
        display: inline-block;
        padding: .5rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        display: inline-block;
        padding: .5rem 1rem;
      }
      @include breakpoint($for-tablet-landscape-up) {

        padding: 0 .3rem;
      }
      @include breakpoint($for-desktop-up) {

        padding: 0 .3rem;
      }
      @include breakpoint($for-big-desktop-up) {

        padding: 0 .3rem;
      }
    }

    &__draft-help {
      display: inline-block;
      @include breakpoint($for-phone-only) {
        padding: 0 .5rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        padding: 0 .5rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        padding: 0 .5rem;
      }
      @include breakpoint($for-desktop-up) {
        padding: 0 .5rem;
      }
      @include breakpoint($for-big-desktop-up) {
        padding: 0 .5rem;
      }
    }

    &__more {
      display: none;
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {
        position: absolute;
        right: 0;
        top: 2.5rem;
        padding: 1rem;
        min-width: 10rem;

      }
      @include breakpoint($for-desktop-up) {
        position: absolute;
        right: 0;
        top: 2.5rem;
        padding: 1rem;
        min-width: 10rem;
      }

      .g-navigation-module__lvl-1 {
        display: block;
        @include breakpoint($for-phone-only) {
        }
        @include breakpoint($for-tablet-portrait-up) {
        }
        @include breakpoint($for-tablet-landscape-up) {
        }

        &__item {
          margin-right: 0;
        }

        &__item-draft {
        }

        .g-navigation-module__lvl-2__wrapper-1 {
          @include breakpoint($for-phone-only) {

          }
          @include breakpoint($for-tablet-portrait-up) {

          }
          @include breakpoint($for-tablet-landscape-up) {
            position: static;
            display: block;
          }

          .g-navigation-module__lvl-2__wrapper {
            @include breakpoint($for-phone-only) {

            }
            @include breakpoint($for-tablet-portrait-up) {

            }
            @include breakpoint($for-tablet-landscape-up) {
              position: static;
              display: block;
              padding: 0;
              margin: 0;
            }
          }
        }

        .g-navigation-module__lvl-2 {
          @include breakpoint($for-phone-only) {

          }
          @include breakpoint($for-tablet-portrait-up) {

          }
          @include breakpoint($for-tablet-landscape-up) {
            margin-bottom: 0;
            margin-left: .5rem;
          }
        }

        &:hover {
          .g-navigation-module__lvl-2__wrapper-1 {
            @include breakpoint($for-phone-only) {

            }
            @include breakpoint($for-tablet-portrait-up) {

            }
            @include breakpoint($for-tablet-landscape-up) {
              position: static;
              display: block;
              padding: 0;
            }
          }
        }
      }

    }

    &__lvl-1 {
      box-sizing: border-box;
      @include breakpoint($for-phone-only) {
        display: block;
        position: relative;
        margin-bottom: .5rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        display: block;
        position: relative;
        margin-bottom: .5rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        display: block;
        position: relative;
        margin-bottom: 0;
      }

      &-last {
        padding: .25rem 1rem;

      }

      &__item {
        @include breakpoint($for-phone-only) {
          //display: inline-block;

        }
        @include breakpoint($for-tablet-portrait-up) {
          //display: inline-block;

        }
        @include breakpoint($for-tablet-landscape-up) {

          display: inline-block;
          margin-right: 1rem;


        }
        @include breakpoint($for-desktop-up, $for-big-desktop-up) {
          display: inline-block;
          margin-right: 1rem;
          margin-bottom: 0;

        }

        &-draft {
        }
      }

      //&:hover, &:focus {
      //  .g-navigation-module__lvl-2 {
      //    display: block;
      //  }
      //}

      &-child {
      }

      &-child-draft {
      }

      .g-navigation-module__lvl-2__wrapper-1 {
        @include breakpoint($for-phone-only) {
          display: none;
        }
        @include breakpoint($for-tablet-portrait-up) {
          display: none;
        }
        @include breakpoint($for-tablet-landscape-up) {
          display: none;
        }
      }

      &-end {
        &:hover {
          .g-navigation-module__lvl-2__wrapper-1 {
            @include breakpoint($for-phone-only) {

            }
            @include breakpoint($for-tablet-portrait-up) {

            }
            @include breakpoint($for-tablet-landscape-up) {
              right: 0;
              left: auto !important;
            }
          }
        }
      }

      &:hover {
        .g-navigation-module__lvl-2__wrapper-1 {
          @include breakpoint($for-phone-only) {

          }
          @include breakpoint($for-tablet-portrait-up) {

          }
          @include breakpoint($for-tablet-landscape-up) {
            display: block;
            position: absolute;
            left: 0;
            top: 1.35rem;
            z-index: 100;
          }
        }
      }
    }

    &__lvl-2 {
      @include breakpoint($for-phone-only) {
        margin-bottom: .5rem;

      }
      @include breakpoint($for-tablet-portrait-up) {
        margin-bottom: .5rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        margin-bottom: .25rem;

      }

      &__wrapper {
        @include breakpoint($for-phone-only) {
          margin: .75rem;
        }
        @include breakpoint($for-tablet-portrait-up) {
          margin: .75rem;
        }
        @include breakpoint($for-tablet-landscape-up) {
          margin: 1rem 0 0 0;
          padding: 1rem;
          min-width: 15rem;
          max-width: 15rem;
          width: 12.5rem;
        }

      }

      &__item {
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
      }
    }
  }

  &__m_s {
    @include breakpoint($for-phone-only) {
      padding: 1rem;
      margin: 1rem .25rem 0 .25rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      padding: 1rem;
      margin: 1rem .25rem 0 .25rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      padding: 0;
      margin: 0 0 2rem 0;
    }

    &__wrapper-1 {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
    }

    &__wrapper-2 {
      @include breakpoint($for-phone-only) {
        margin-left: .75rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        margin-left: .75rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        margin-left: .75rem;
      }
    }

    &__lvl-1 {
      @include breakpoint($for-phone-only) {
        margin-bottom: .75rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        margin-bottom: .75rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        margin-bottom: .75rem;
      }

      &__draft {
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
      }

      &__item {
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
      }
    }

    &__lvl-2 {
      @include breakpoint($for-phone-only) {
        margin-bottom: .5rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        margin-bottom: .5rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        margin-bottom: .5rem;
      }

      &__draft {
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
      }

      &__item {
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
      }
    }
  }

  //&_edit {
  //  padding: .125rem .5rem;
  //}
}

.g-main__content-header .g-navigation {


  .g-navigation__list-item {
    @include breakpoint($for-phone-only) {
      display: none;
    }
    @include breakpoint($for-tablet-portrait-up) {
      display: none;
    }
    @include breakpoint($for-tablet-landscape-up) {
      display: block;
    }
  }
}

.g-top-navigation {
  &__icon {
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 0;
    top: 5rem;
    @include breakpoint($for-phone-only) {
      display: block;
    }
    @include breakpoint($for-tablet-portrait-up) {
      display: block;
    }
    @include breakpoint($for-tablet-landscape-up) {
      display: none;
    }
  }

  &__icon-close {
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 0;
    top: 1rem;
    @include breakpoint($for-phone-only) {
      display: block;
    }
    @include breakpoint($for-tablet-portrait-up) {
      display: block;
    }
    @include breakpoint($for-tablet-landscape-up) {
      display: none;
    }
  }

  &__list {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {
      display: flex;
      flex-flow: row nowrap;
      position: absolute;
      padding: 0;
      align-items: flex-start;
      justify-content: space-between;
      z-index: 1;
    }
    @include breakpoint($for-desktop-up) {
      justify-content: flex-start;
      position: absolute;
      z-index: 1;
    }

    &-section {
      display: flex;
      flex-flow: column;
      justify-content: center;
      @include breakpoint($for-phone-only) {
        margin: 0;
      }
      @include breakpoint($for-tablet-portrait-up) {
        justify-content: space-between;
        margin: 0;
      }
      @include breakpoint($for-tablet-landscape-up) {
        justify-content: flex-start;
        &:not(:last-child) {
          margin-right: 2.5rem;
        }
      }

      &:hover {
        .g-top-navigation__section-item {
          @include breakpoint($for-phone-only) {

          }
          @include breakpoint($for-tablet-portrait-up) {

          }
          @include breakpoint($for-tablet-landscape-up) {
            display: inline-block;
          }
        }
      }
    }
  }
}

.g-top-navigation__section {
  &-item-title {
    @include breakpoint($for-phone-only) {
      display: block;
      margin: 0 -1rem 0 -1rem;
      padding: .7rem 1rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      display: block;
      margin: 0 -1rem 0 -1rem;
      padding: .7rem 1rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      margin: 0 0 .4rem 0;
      padding: 0;
    }

    &:after {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {
        display: block;
        height: .125rem;
        content: "";
        margin-top: .25rem;
        width: 65%;
      }
    }
  }

  &-item {
    @include breakpoint($for-phone-only) {
      display: block;
      margin-bottom: 1rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      display: block;
      margin-bottom: 1rem;

    }
    @include breakpoint($for-tablet-landscape-up) {
      margin-bottom: 0;
      &:nth-child(-n+5) {
        display: inline-block;
      }
      display: none;
    }
  }


}


.g-main__wrapper-sidebar, .g-main__wrapper-modules, .g-navigation-document-group {
  .g-navigation__list-item {
    &:not(:empty) {
      display: flex;
      @include breakpoint($for-phone-only) {
        padding: 1rem;
        margin-top: 1rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        padding: 1rem;
        margin-top: 1rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        padding: 1rem;
        margin: 0 0 1rem 0;
        flex-flow: column;
      }
      @include breakpoint($for-desktop-up) {
        padding: 0;
        margin: 2rem 0 0 0;
        flex-flow: column;
      }
      @include breakpoint($for-big-desktop-up) {
        padding: 0;
        margin: 2rem 0 0 0;
        flex-flow: column;
      }
    }

    .g-navigation-module__lvl-1__item {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {

      }
      @include breakpoint($for-big-desktop-up) {

      }
    }
  }


  .g-navigation-module__lvl-2__item {

    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {

    }
    @include breakpoint($for-desktop-up) {

    }
    @include breakpoint($for-big-desktop-up) {

    }
  }

  .g-navigation-module__lvl-3__item {

    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
      display: inline-block;
    }
    @include breakpoint($for-desktop-up) {
      display: inline-block;
    }
    @include breakpoint($for-big-desktop-up) {
      display: inline-block;
    }
  }

  .g-navigation-module__lvl-4__item {
    display: block;
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {
      margin-left: 1.5rem;
    }
  }

  .g-navigation-module__lvl-4__item {
    display: block;
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {
      margin-left: 2rem;
    }
  }
}

.g-header-mobile__bottom .g-navigation {
  display: flex;
  flex-flow: row nowrap;

  @include breakpoint($for-phone-only) {
  }
  @include breakpoint($for-tablet-portrait-up) {
  }
  @include breakpoint($for-tablet-landscape-up) {
    display: none;
  }
  @include breakpoint($for-desktop-up) {
    display: none;
  }
  @include breakpoint($for-big-desktop-up) {
    display: none;
  }

  &__item {
    margin-right: .5rem;
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {
    }
  }
}