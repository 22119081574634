@mixin link-colors($normal, $hover: false, $active: false, $visited: false, $focus: false) {
  color: $normal;
  @if $visited {
    &:visited {
      color: $visited;
    }
  }
  @if $focus {
    &:focus {
      color: $focus;
    }
  }
  @if $hover {
    &:hover {
      color: $hover;
    }
  }
  @if $active {
    &:active {
      color: $active;
    }
  }
}

@each $name, $color in $color-btn {
  .m-btn__fill-color-#{$name} {
    background: $color;
    @include link-colors(white, white, white, white, white);
    border-color: $color;

    &:hover, &:focus {
      background: darken($color, 6%);
      cursor: pointer;
      //border-bottom: 0;
    }

    &:visited {
      color: white;
      //border-bottom: 0;
    }

    a {
      @include link-colors(white, white, white, white, white);
      text-decoration: none;
      border-bottom: 0;

      &:hover, &:focus, &:visited {
        text-decoration: none;
        border-bottom: 0;
      }
    }
  }
  .m-btn__add-border-#{$name} {
    box-shadow: 0 0 0 1px lighten($color, 35%);

    &:hover, &:focus, &:visited {
      box-shadow: 0 0 0 1px lighten($color, 35%);
    }

  }

}

@each $name, $color in $color-btn {
  .m-btn__empty-color-#{$name} {
    background: transparentize($color, .95);
    @include link-colors($color, $color, $color, $color, $color);
    border-color: lighten($color, 25%);
    box-shadow: 0 0 0 1px lighten($color, 35%);
    border-style: solid;

    &:hover, &:focus, &:visited {
      background: transparentize($color, .875);
      cursor: pointer;
      border-color: lighten($color, 25%);
      box-shadow: 0 0 0 1px lighten($color, 35%);
      border-style: solid;
    }

    a {
      @include link-colors($color, $color, $color, $color, $color);
      text-decoration: none;

      &:hover, &:focus, &:visited {
        text-decoration: none;
      }
    }
  }
}


@each $name, $color in $color-status {
  .m-status-#{$name} {
    background: $color;
  }
}

@each $name, $color in $color-map {
  .m-#{$name} {
    color: $color;

    &:hover, &:focus, &:visited {
      color: $color;
    }
  }
}

@each $name, $color in $color-bg-status {
  .m-bg-#{$name} {
    background-color: $color;
  }
}

@function mapget($map, $key) {
  @return map-get($map, $key);
}

@each $name, $color, $icon in $color-social {
  a {
    .m-#{$name} {
      border: 1px solid #{$color};
      box-shadow: 0 0 0 2px lighten($color, 24%);
    }
  }
}

@for $i from 1 through 20 {
  .g-width-#{$i *5} {
    width: #{$i* 5%};
  }
}

@for $i from 0 through 5 {
  .g-main__wrapper-grid__#{$i} {
    grid-area: grid__#{$i};
  }
}

@each $name, $color in $color-tags {
  .m-tag__color-active#{$name} {
    border-color: $color;
    box-shadow: 0 0 0 1px lighten($color, 40%);

    .m-tag__color-active#{$name}-link {
      &:focus, &:visited {
        color: $color;
      }
    }
  }
  .m-tag__color-hover#{$name} {
    &:hover {
      border-color: $color;
      box-shadow: 0 0 0 1px lighten($color, 40%);

      .m-tag__color-hover#{$name}-link {
        color: $color;
      }
    }
  }
}