.m-facs-in-passport {
    @include breakpoint ($for-phone-only) {
      &:not(:last-child){border-bottom: .01rem solid $color-border;}
    }
    @include breakpoint ($for-tablet-portrait-up) {
      border-bottom: 0;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      border-bottom: 0;
    }
    @include breakpoint ($for-desktop-up) {
      border-bottom: 0;
    }
    @include breakpoint ($for-big-desktop-up) {
      border-bottom: 0;
    }
  &__name {
    font-style: italic;
    @include breakpoint ($for-phone-only) {
      font-size: .9em;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      font-size: .9em;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      font-size: .9em;
    }
    @include breakpoint ($for-desktop-up) {
      font-size: .9em;
    }
    @include breakpoint ($for-big-desktop-up) {
      font-size: .9em;
    }
  }
  &__item {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
  &__info {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
    &-form {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
      &__name {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
        }
        @include breakpoint ($for-big-desktop-up) {
        }
      }
      &__duraction {
        font-style: italic;
        @include breakpoint ($for-phone-only) {
          font-size: .85em;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          font-size: .85em;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          font-size: .85em;
        }
        @include breakpoint ($for-desktop-up) {
          font-size: .85em;
        }
        @include breakpoint ($for-big-desktop-up) {
          font-size: .85em;
        }
      }
    }
    &-number {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
    &-exam {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
      &__item {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
        }
        @include breakpoint ($for-big-desktop-up) {
        }
      }
    }
    &-sum {
      font-family: 'Century Gothic', sans-serif;
      font-weight: 600;
      @include breakpoint ($for-phone-only) {
        font-size: 1.7em;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        font-size: 1.7em;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        font-size: 1.7em;
      }
      @include breakpoint ($for-desktop-up) {
        font-size: 1.7em;
      }
      @include breakpoint ($for-big-desktop-up) {
        font-size: 1.7em;
      }
    }
  }
}