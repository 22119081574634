.g-redactor-navigation {
  @include breakpoint ($for-phone-only) {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    padding: .5rem 1rem 1rem 1rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    padding: .5rem 1rem 1rem 1rem;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    display: flex;
    flex-direction: row;
    margin-top: 0;
    padding: 1rem;
  }
  @include breakpoint ($for-desktop-up) {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }
}