.g-separator {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {
  }
  @include breakpoint ($for-big-desktop-up) {
  }
  margin: 1rem 0;
  width: 100%;
  height: .5rem;
}