.m-news-categories {
  @include breakpoint ($for-phone-only) {

  }
  @include breakpoint ($for-tablet-portrait-up) {

  }
  @include breakpoint ($for-tablet-landscape-up) {

  }
  &__item {
    font-size: .9em;
    &-active {
      font-weight: bold;
      color: $color-text;
      &:visited {
        color: $color-text;
      }
    }
  }
}