.m-indicator {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {
  }
  &-list {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    &__item {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
        &:before {
          content: "—";
          color: $color-text;
          font-weight: 600;
        }
      }
    }
  }
  &__name {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
      font-size: 1rem;
      font-weight: 600;
    }
  }
  &__info {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    &-status {
      @include breakpoint ($for-phone-only) {
        font-size: .9em;
        line-height: 1.2em;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        font-size: .9em;
        line-height: 1.2em;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        font-size: .9em;
        line-height: 1.2em;
      }
      @include breakpoint ($for-desktop-up) {
        font-size: .9em;
        line-height: 1.2em;
      }
    }
    &-score {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
    }
    &-danger {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
        font-size: .9em;
        line-height: 1.2em;
      }
    }

  }
  &-moderator {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    &__tip {

      background-color: white;
      @include breakpoint ($for-phone-only) {
        box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
      }
      @include breakpoint ($for-tablet-portrait-up) {
        box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
      }
      @include breakpoint ($for-tablet-landscape-up) {
        box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
      }
      @include breakpoint ($for-desktop-up) {
        box-shadow: 0 5px 15px 0 #bababa;

      }
      &-photo {
        overflow: hidden;
        border-radius: 2.5rem;
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
        }
      }
      &-name {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
        }
      }
      &-post {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
        }
      }
      &-success {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
        }
      }
      &-static {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {

        }
      }
    }
  }
}