.g-alphabet-sort {
  @include breakpoint ($for-phone-only) {
    display: none;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    display: none;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin: 2rem 0;
    width: 100%;
  }
  @include breakpoint ($for-desktop-up) {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
    padding: 0;
    width: 100%;
  }
  &__all-letter {
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {

    }
    @include breakpoint ($for-tablet-landscape-up) {
      padding: 0 0 .25rem 0;
    }
    @include breakpoint ($for-desktop-up) {
      padding: 0 0 .25rem 0;
    }
  }
  &__letter {
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {

    }
    @include breakpoint ($for-tablet-landscape-up) {
      padding: 0 0 .25rem 0;
    }
    @include breakpoint ($for-desktop-up) {
      padding: 0 0 .25rem 0;
    }
  }
  &__filter {
    &-active {
      @include breakpoint ($for-phone-only) {

      }
      @include breakpoint ($for-tablet-portrait-up) {

      }
      @include breakpoint ($for-tablet-landscape-up) {

      }
      @include breakpoint ($for-desktop-up) {

      }
    }
  }
}