.m-document-list {
  @include breakpoint($for-phone-only) {

  }
  @include breakpoint($for-tablet-portrait-up) {

  }
  @include breakpoint($for-tablet-landscape-up) {

  }
  @include breakpoint($for-desktop-up) {

  }
  @include breakpoint($for-big-desktop-up) {

  }

  &__item {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
      border: 1px solid $color-border;
      border-radius: .5rem;
    }
    @include breakpoint($for-desktop-up) {
      border: 1px solid $color-border;
      border-radius: .5rem;
    }
    @include breakpoint($for-big-desktop-up) {
      border: 1px solid $color-border;
      border-radius: .5rem;
    }

    &-empty {
      background-color: mapget($color-bg-status, warning);
    }

    &-name {
      font-weight: 600;
      @include breakpoint($for-phone-only) {
        font-size: 1.1em;
      }
      @include breakpoint($for-tablet-portrait-up) {
        font-size: 1.1em;
      }
      @include breakpoint($for-tablet-landscape-up) {
        font-size: 1.1em;
      }
      @include breakpoint($for-desktop-up) {
        font-size: 1.2em;
      }
      @include breakpoint($for-big-desktop-up) {
        font-size: 1.2em;
      }
    }

    &-help {
      color: $color-text-grey;
      @include breakpoint($for-phone-only) {
        font-size: .8em;
      }
      @include breakpoint($for-tablet-portrait-up) {
        font-size: .8em;
      }
      @include breakpoint($for-tablet-landscape-up) {
        font-size: .8em;
      }
      @include breakpoint($for-desktop-up) {
        font-size: .8em;
      }
      @include breakpoint($for-big-desktop-up) {
        font-size: .8em;
      }
    }

    &-content {
      @include breakpoint($for-phone-only) {
        word-wrap: break-word;
      }
      @include breakpoint($for-tablet-portrait-up) {
        word-wrap: break-word;
      }
      @include breakpoint($for-tablet-landscape-up) {
        word-wrap: normal;
      }
      @include breakpoint($for-desktop-up) {
        word-wrap: normal;
      }
      @include breakpoint($for-big-desktop-up) {
        word-wrap: normal;
      }

      &__title {
        @include breakpoint($for-phone-only) {
        }
        @include breakpoint($for-tablet-portrait-up) {
        }
        @include breakpoint($for-tablet-landscape-up) {
        }
        @include breakpoint($for-desktop-up) {


        }
        @include breakpoint($for-big-desktop-up) {


        }

        &-choice {
          cursor: pointer;
          @include breakpoint($for-phone-only) {
          }
          @include breakpoint($for-tablet-portrait-up) {
          }
          @include breakpoint($for-tablet-landscape-up) {
          }
          @include breakpoint($for-desktop-up) {
            font-size: .9em;
          }
          @include breakpoint($for-big-desktop-up) {
            font-size: .9em;
          }

          &-all {
            @include breakpoint($for-phone-only) {
            }
            @include breakpoint($for-tablet-portrait-up) {
            }
            @include breakpoint($for-tablet-landscape-up) {
            }
            @include breakpoint($for-desktop-up) {
            }
            @include breakpoint($for-big-desktop-up) {

            }
          }
        }

        &-name {
          font-weight: bold;
          @include breakpoint($for-phone-only) {
          }
          @include breakpoint($for-tablet-portrait-up) {
          }
          @include breakpoint($for-tablet-landscape-up) {
          }
          @include breakpoint($for-desktop-up) {
          }
          @include breakpoint($for-big-desktop-up) {

          }
        }
      }

      &__edit {
        @include breakpoint($for-phone-only) {
        }
        @include breakpoint($for-tablet-portrait-up) {
        }
        @include breakpoint($for-tablet-landscape-up) {
        }
        @include breakpoint($for-desktop-up) {
          border-bottom: .01rem solid $color-border-light;
        }
        @include breakpoint($for-big-desktop-up) {
          border-bottom: .01rem solid $color-border-light;
        }

        &-icon {
          background: url(/static/img/edit_arp.svg) 100% 0 no-repeat;

          &:hover {
            cursor: pointer;

            &:after {
              content: "изменить";
              font-size: .6rem;
              background-color: $color-tooltip;
              color: white;
              border-radius: .1rem;
            }
          }

          &__save {
            background: url(/static/img/save-arp.svg) 100% 0 no-repeat;

            &:hover {
              cursor: pointer;

              &:after {
                content: "сохранить";
                font-size: .6rem;
                background-color: $color-tooltip;
                color: white;
                border-radius: .1rem;
              }
            }
          }

          &__loader {
            background: url(/static/img/loading.svg) 100% 0 no-repeat;

            &:hover {
              cursor: pointer;

              &:after {
                content: "сохраняем";
                font-size: .6rem;
                background-color: $color-tooltip;
                color: white;
                border-radius: .1rem;
              }
            }
          }

          @include breakpoint($for-phone-only) {
          }
          @include breakpoint($for-tablet-portrait-up) {
          }
          @include breakpoint($for-tablet-landscape-up) {
          }
          @include breakpoint($for-desktop-up) {
          }
          @include breakpoint($for-big-desktop-up) {

          }
        }

        &-link {
          @include breakpoint($for-phone-only) {
          }
          @include breakpoint($for-tablet-portrait-up) {
          }
          @include breakpoint($for-tablet-landscape-up) {
          }
          @include breakpoint($for-desktop-up) {
          }
          @include breakpoint($for-big-desktop-up) {
          }
        }

        &-years {
          font-size: .6rem;
          color: $color-text-grey;
          @include breakpoint($for-phone-only) {
          }
          @include breakpoint($for-tablet-portrait-up) {
          }
          @include breakpoint($for-tablet-landscape-up) {
          }
          @include breakpoint($for-desktop-up) {
          }
          @include breakpoint($for-big-desktop-up) {
          }
        }
      }

      &__add {
        border-bottom: .01rem solid $color-border;
        @include breakpoint($for-phone-only) {
        }
        @include breakpoint($for-tablet-portrait-up) {
        }
        @include breakpoint($for-tablet-landscape-up) {
        }
        @include breakpoint($for-desktop-up) {
        }
        @include breakpoint($for-big-desktop-up) {
        }

        &-item {
          @include breakpoint($for-phone-only) {
          }
          @include breakpoint($for-tablet-portrait-up) {
          }
          @include breakpoint($for-tablet-landscape-up) {
          }
          @include breakpoint($for-desktop-up) {
            border-top: .01rem solid $color-border;
            border-bottom: .01rem solid $color-border;
            border-right: .01rem solid $color-border;
          }
          @include breakpoint($for-big-desktop-up) {
            border-top: .01rem solid $color-border;
            border-bottom: .01rem solid $color-border;
            border-right: .01rem solid $color-border;
          }
        }

        &-docs {
          &__item {
          }
        }

        &-header {
          border-bottom: .01rem solid $color-border;
          @include breakpoint($for-phone-only) {
          }
          @include breakpoint($for-tablet-portrait-up) {
          }
          @include breakpoint($for-tablet-landscape-up) {
          }
          @include breakpoint($for-desktop-up) {
          }
          @include breakpoint($for-big-desktop-up) {
          }

          &__item {
            color: $color-text-grey;
            font-size: .8rem;
            @include breakpoint($for-phone-only) {
            }
            @include breakpoint($for-tablet-portrait-up) {
            }
            @include breakpoint($for-tablet-landscape-up) {
            }
            @include breakpoint($for-desktop-up) {

            }
            @include breakpoint($for-big-desktop-up) {

            }
          }
        }

        &-button {
          &-icon {
            background: url(/static/img/add-button.svg) 100% 0 no-repeat;
            @include breakpoint($for-phone-only) {
            }
            @include breakpoint($for-tablet-portrait-up) {
            }
            @include breakpoint($for-tablet-landscape-up) {
            }
            @include breakpoint($for-desktop-up) {
            }
            @include breakpoint($for-big-desktop-up) {
            }
          }

          &-name {
            &:hover {
              cursor: pointer;
              color: lighten($color-text, 8);
            }

            @include breakpoint($for-phone-only) {
            }
            @include breakpoint($for-tablet-portrait-up) {
            }
            @include breakpoint($for-tablet-landscape-up) {
            }
            @include breakpoint($for-desktop-up) {
            }
            @include breakpoint($for-big-desktop-up) {
            }
          }

        }
      }
    }

    &-del {
      background-color: transparent;
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {
      }
    }
  }

  &__item-no-file {
    background-color: rgba(220, 20, 61, 0.1);
  }
}

.m-document-list.js-document-list {
  background-color: #ffffff;
}