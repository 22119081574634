//size screen

$for-phone-only: max-width 599px;
$for-tablet-portrait-up:600px;
$for-tablet-landscape-up:900px;
$for-desktop-up:1200px; 
$for-big-desktop-up:1800px;
$for-extrabig-desktop-up:2500px;

//width content
$big:1440px;
$middle:1200px;
$small:100%;

//colors
$color-text: #373737;
$color-text-underline: rgba(55, 55, 55, 0.5);
$color-text-light: rgba(55, 55, 55, 0.9);
$color-text-underline-light: rgba(55, 55, 55, 0.35);
$color-text-orange-light: #d13f00;
$color-text-orange-light-hover: rgba(238, 70, 0, 1);
$color-text-orange-underline-light: rgba(238, 70, 0, 0.35);
$color-text-grey: #636363;
$color-text-error: #ca3232;
$color-error:#CA3232;
$color-error-light: rgba(202, 50, 50, 0.3);

$color-text-grey-light: rgba(19, 18, 64, 0.75);
$color-text-grey-light-30: rgba(55, 55, 55, 0.6);
$color-text-grey-underline: rgba(99, 99, 99, 0.8);
$color-text-grey-underline-light: rgba(19, 18, 64, 0.2);
$color-text-grey-underline-light-30: rgba(55, 55, 55, 0.2);

$color-text-tags: #686868;
$color-border-tags: #aeaeae;
$color-shadow-tags: #EEEEEE;

$color-border-second-menu: rgba(219, 225, 249, 0.6);
$color-shadow-second-menu:rgba(197,189,234,0.6);

$color-border-auth: #C5BDEA;
$color-shadow-auth: #EAE7F7;

$color-banner-top-bg: #FFF7EC;

$color-border-slider-first: #BACDFC;
$color-border-slider-two: rgba(224, 233, 254, 0.6);
$color-shadow-slider: rgba(222, 224, 239, 0.64);

$color-border-slider-banner: #F1C1AD;
$color-shadow-slider-banner: #F8E6DA;

$color-bg-footer:#F7F7F7;

$color-green:#4CB18B;
$color-m-top-navigation__section-item-title:$color-text-orange-light;
$color-m-top-navigation__section-item-title-border:$color-green;
$color-bg-m-header-section:#F2F2FF;
$color-shadow-m-header-section:rgba(242, 242, 255, 0.7);

$color-link-normal:#3854B8;
$color-link-underline:rgba(87, 123, 249, 0.5);
$color-link-underline-hover:rgba(87, 123, 249, 0.7);

$color-link-hover:$color-text-orange-light;
$color-link-visited:$color-link-normal;
$color-link-active:$color-text-orange-light;
$color-link-focus:$color-link-normal;

$color-border: #c1c1c1;
$color-border-light: #ddd;
$color-text-blue: #0d47a1;
$color-dark-blue: #4134ad;
$color-light-blue: #6158ad;
$color-icon:#a2a2a2;
$color-bg-mobile: #f5f5f5;
$color-tooltip:#3a3a3a;
$color-border-pagination-hover:#00a13e;
$color-module-list-oop:#f9fbe7;


$color-bg-jstabs-labels:#F1F9F6;
$color-bg-jstabs-label-active:#4CB18B;

$color-btn:(
  success:#398467,
  primary: #4666cb,
  info:#42b6d9,
  warning:#f0ad4e,
  danger:#d9534f,
  pink:#8a2be2

);


$color-bg-status:(
  success:#EFF6EF,
  primary: #4666cb,
  info:#42b6d9,
  warning:rgba(220,20,61,.1),
  danger:#d84c44,
  pink:#8a2be2

);

$color-status:(
  success:transparent,
  check: #e1f5fe,
  return: #e8ebf7,
  checkadmin: rgba(255, 152, 0, 0.1),
  end:#ffecef,
);


$color-social:(
  vk:#6996c7,
  fb:#496194,
  tw:#28c3ff,
  gp:#ff6d4a,
  yt:#ff4343,
  tg:#31a5d8,
  ig:#d92d77,
  ok:#f6931d,
  ru:#4a4a4a
);
$color-map:(
  red:  rgba(237, 20, 61, 0.9),
  purple: rgba(138, 43, 226, 0.9),
  green: rgba(0, 128, 0, 0.9),
  orange:rgba(192, 94, 0, 0.9),
);

$color-tags:(
    __00FF00:#0f8e0f,
    __32CD32:#32cd32,
    __98FB98:#98fb98,
    __00FA9A:#00fa9a,
    __00FF7F:#00ff7f,
    __3CB371:#3cb371,
    __2E8B57:#2e8b57,
    __008000:#008000,
    __006400:#006400,
    __9ACD32:#9acd32,
    __6B8E23:#6b8e23,
    __808000:#808000,
    __556B2F:#556b2f,
    __66CDAA:#66cdaa,
    __8FBC8F:#8fbc8f,
    __20B2AA:#20b2aa,
    __008080:#008080,
    __00FFFF:#ffa200,
    __7FFFD4:#7fffd4,
    __00CED1:#00ced1,
    __5F9EA0:#5f9ea0,
    __4682B4:#4682b4,
    __B0C4DE:#b0c4de,
    __B0E0E6:#b0e0e6,
    __87CEEB:#87ceeb,
    __00BFFF:#00bfff,
    __1E90FF:#1e90ff,
    __7B68EE:#7b68ee,
    __4169E1:#4169e1,
    __0000FF:#0000ff,
    __000080:#000080,
    __696969:#696969,
    __708090:#708090,
    __2F4F4F:#2f4f4f,
    __CD5C5C:#cd5c5c,
    __F08080:#f08080,
    __FFA07A:#ffa07a,
    __DC143C:#dc143c,
    __FF0000:#ff0000,
    __B22222:#b22222,
    __8B0000:#8b0000,
    __FFC0CB:#ffc0cb,
    __FF69B4:#ff69b4,
    __FF1493:#ff1493,
    __C71585:#c71585,
    __FF6347:#ff6347,
    __FF4500:#ff4500,
    __FF8C00:#ff8c00,
    __FFFF00:#ffff00,
    __FFDAB9:#ffdab9,
    __BDB76B:#bdb76b,
    __DDA0DD:#dda0dd,
    __FF00FF:#ff00ff,
    __BA55D3:#ba55d3,
    __9370DB:#9370db,
    __8A2BE2:#8a2be2,
    __9400D3:#9400d3,
    __800080:#800080,
    __4B0082:#4b0082,
    __6A5ACD:#6a5acd,
    __483D8B:#483d8b,
    __FFDEAD:#ffdead,
    __D2B48C:#d2b48c,
    __BC8F8F:#bc8f8f,
    __F4A460:#f4a460,
    __B8860B:#b8860b,
    __D2691E:#d2691e,
    __A0522D:#a0522d,
    __800000:#800000
);

//fonts


@font-face {
    font-family: 'Futura PT';
    src: url('/static/fonts/FuturaPT-Heavy.eot');
    src: local('/static/fonts/Futura PT Heavy'), local('/static/fonts/FuturaPT-Heavy'),
        url('/static/fonts/FuturaPT-Heavy.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPT-Heavy.woff') format('woff'),
        url('/static/fonts/FuturaPT-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('/static/fonts/FuturaPT-Medium.eot');
    src: local('/static/fonts/Futura PT Medium'), local('/static/fonts/FuturaPT-Medium'),
        url('/static/fonts/FuturaPT-Medium.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPT-Medium.woff') format('woff'),
        url('/static/fonts/FuturaPT-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('/static/fonts/FuturaPT-MediumObl.eot');
    src: local('/static/fonts/Futura PT Medium Oblique'), local('/static/fonts/FuturaPT-MediumObl'),
        url('/static/fonts/FuturaPT-MediumObl.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPT-MediumObl.woff') format('woff'),
        url('/static/fonts/FuturaPT-MediumObl.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('/static/fonts/FuturaPTCond-Medium.eot');
    src: local('/static/fonts/Futura PT Cond Medium'), local('/static/fonts/FuturaPTCond-Medium'),
        url('/static/fonts/FuturaPTCond-Medium.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPTCond-Medium.woff') format('woff'),
        url('/static/fonts/FuturaPTCond-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('/static/fonts/FuturaPT-LightObl.eot');
    src: local('/static/fonts/Futura PT Light Oblique'), local('/static/fonts/FuturaPT-LightObl'),
        url('/static/fonts/FuturaPT-LightObl.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPT-LightObl.woff') format('woff'),
        url('/static/fonts/FuturaPT-LightObl.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url('/static/fonts/FuturaPT-Book.eot');
    src: local('/static/fonts/Futura PT Book'), local('/static/fonts/FuturaPT-Book'),
        url('/static/fonts/FuturaPT-Book.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPT-Book.woff') format('woff'),
        url('/static/fonts/FuturaPT-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('/static/fonts/FuturaPTCond-Bold.eot');
    src: local('/static/fonts/Futura PT Cond Bold'), local('/static/fonts/FuturaPTCond-Bold'),
        url('/static/fonts/FuturaPTCond-Bold.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPTCond-Bold.woff') format('woff'),
        url('/static/fonts/FuturaPTCond-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url('/static/fonts/FuturaPT-BookObl.eot');
    src: local('/static/fonts/Futura PT Book Oblique'), local('/static/fonts/FuturaPT-BookObl'),
        url('/static/fonts/FuturaPT-BookObl.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPT-BookObl.woff') format('woff'),
        url('/static/fonts/FuturaPT-BookObl.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Cond Extra';
    src: url('/static/fonts/FuturaPTCond-ExtraBoldObl.eot');
    src: local('/static/fonts/Futura PT Cond Extra Bold Oblique'), local('/static/fonts/FuturaPTCond-ExtraBoldObl'),
        url('/static/fonts/FuturaPTCond-ExtraBoldObl.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPTCond-ExtraBoldObl.woff') format('woff'),
        url('/static/fonts/FuturaPTCond-ExtraBoldObl.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT';
    src: url('/static/fonts/FuturaPT-Light.eot');
    src: local('/static/fonts/Futura PT Light'), local('/static/fonts/FuturaPT-Light'),
        url('/static/fonts/FuturaPT-Light.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPT-Light.woff') format('woff'),
        url('/static/fonts/FuturaPT-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('/static/fonts/FuturaPT-BoldObl.eot');
    src: local('/static/fonts/Futura PT Bold Oblique'), local('/static/fonts/FuturaPT-BoldObl'),
        url('/static/fonts/FuturaPT-BoldObl.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPT-BoldObl.woff') format('woff'),
        url('/static/fonts/FuturaPT-BoldObl.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Demi';
    src: url('/static/fonts/FuturaPT-DemiObl.eot');
    src: local('/static/fonts/Futura PT Demi Oblique'), local('/static/fonts/FuturaPT-DemiObl'),
        url('/static/fonts/FuturaPT-DemiObl.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPT-DemiObl.woff') format('woff'),
        url('/static/fonts/FuturaPT-DemiObl.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Cond Extra';
    src: url('/static/fonts/FuturaPTCond-ExtraBold.eot');
    src: local('/static/fonts/Futura PT Cond Extra Bold'), local('/static/fonts/FuturaPTCond-ExtraBold'),
        url('/static/fonts/FuturaPTCond-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPTCond-ExtraBold.woff') format('woff'),
        url('/static/fonts/FuturaPTCond-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Demi';
    src: url('/static/fonts/FuturaPT-Demi.eot');
    src: local('/static/fonts/Futura PT Demi'), local('/static/fonts/FuturaPT-Demi'),
        url('/static/fonts/FuturaPT-Demi.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPT-Demi.woff') format('woff'),
        url('/static/fonts/FuturaPT-Demi.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('/static/fonts/FuturaPT-HeavyObl.eot');
    src: local('/static/fonts/Futura PT Heavy Oblique'), local('/static/fonts/FuturaPT-HeavyObl'),
        url('/static/fonts/FuturaPT-HeavyObl.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPT-HeavyObl.woff') format('woff'),
        url('/static/fonts/FuturaPT-HeavyObl.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Extra';
    src: url('/static/fonts/FuturaPT-ExtraBoldObl.eot');
    src: local('/static/fonts/Futura PT Extra Bold Oblique'), local('/static/fonts/FuturaPT-ExtraBoldObl'),
        url('/static/fonts/FuturaPT-ExtraBoldObl.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPT-ExtraBoldObl.woff') format('woff'),
        url('/static/fonts/FuturaPT-ExtraBoldObl.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Cond Book';
    src: url('/static/fonts/FuturaPTCond-Book.eot');
    src: local('/static/fonts/Futura PT Cond Book'), local('/static/fonts/FuturaPTCond-Book'),
        url('/static/fonts/FuturaPTCond-Book.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPTCond-Book.woff') format('woff'),
        url('/static/fonts/FuturaPTCond-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('/static/fonts/FuturaPT-Bold.eot');
    src: local('/static/fonts/Futura PT Bold'), local('/static/fonts/FuturaPT-Bold'),
        url('/static/fonts/FuturaPT-Bold.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPT-Bold.woff') format('woff'),
        url('/static/fonts/FuturaPT-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Extra';
    src: url('/static/fonts/FuturaPT-ExtraBold.eot');
    src: local('/static/fonts/Futura PT Extra Bold'), local('/static/fonts/FuturaPT-ExtraBold'),
        url('/static/fonts/FuturaPT-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPT-ExtraBold.woff') format('woff'),
        url('/static/fonts/FuturaPT-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('/static/fonts/FuturaPTCond-BoldObl.eot');
    src: local('/static/fonts/Futura PT Cond Bold Oblique'), local('/static/fonts/FuturaPTCond-BoldObl'),
        url('/static/fonts/FuturaPTCond-BoldObl.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPTCond-BoldObl.woff') format('woff'),
        url('/static/fonts/FuturaPTCond-BoldObl.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('/static/fonts/FuturaPTCond-MediumObl.eot');
    src: local('/static/fonts/Futura PT Cond Medium Oblique'), local('/static/fonts/FuturaPTCond-MediumObl'),
        url('/static/fonts/FuturaPTCond-MediumObl.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPTCond-MediumObl.woff') format('woff'),
        url('/static/fonts/FuturaPTCond-MediumObl.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Cond Book';
    src: url('/static/fonts/FuturaPTCond-BookObl.eot');
    src: local('/static/fonts/Futura PT Cond Book Oblique'), local('/static/fonts/FuturaPTCond-BookObl'),
        url('/static/fonts/FuturaPTCond-BookObl.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/FuturaPTCond-BookObl.woff') format('woff'),
        url('/static/fonts/FuturaPTCond-BookObl.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}


@font-face {
    font-family: 'Roboto';
    src: local('/static/fonts/Roboto Thin'), local('/static/fonts/Roboto-Thin'), url('/static/fonts/Robotothin.woff2') format('woff2'), url('/static/fonts/Robotothin.woff') format('woff'), url('/static/fonts/Robotothin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('/static/fonts/Roboto Thin Italic'), local('/static/fonts/Roboto-ThinItalic'), url('/static/fonts/Robotothinitalic.woff2') format('woff2'), url('/static/fonts/Robotothinitalic.woff') format('woff'), url('/static/fonts/Robotothinitalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('/static/fonts/Roboto Light'), local('/static/fonts/Roboto-Light'), url('/static/fonts/Robotolight.woff2') format('woff2'), url('/static/fonts/Robotolight.woff') format('woff'), url('/static/fonts/Robotolight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('/static/fonts/Roboto Light Italic'), local('/static/fonts/Roboto-LightItalic'), url('/static/fonts/Robotolightitalic.woff2') format('woff2'), url('/static/fonts/Robotolightitalic.woff') format('woff'), url('/static/fonts/Robotolightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('/static/fonts/Roboto'), local('/static/fonts/Roboto-Regular'), url('/static/fonts/Roboto.woff2') format('woff2'), url('/static/fonts/Roboto.woff') format('woff'), url('/static/fonts/Roboto.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('/static/fonts/Roboto Italic'), local('/static/fonts/Roboto-Italic'), url('/static/fonts/Robotoitalic.woff2') format('woff2'), url('/static/fonts/Robotoitalic.woff') format('woff'), url('/static/fonts/Robotoitalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('/static/fonts/Roboto Medium'), local('/static/fonts/Roboto-Medium'), url('/static/fonts/Robotomedium.woff2') format('woff2'), url('/static/fonts/Robotomedium.woff') format('woff'), url('/static/fonts/Robotomedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('/static/fonts/Roboto Medium Italic'), local('/static/fonts/Roboto-MediumItalic'), url('/static/fonts/Robotomediumitalic.woff2') format('woff2'), url('/static/fonts/Robotomediumitalic.woff') format('woff'), url('/static/fonts/Robotomediumitalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('/static/fonts/Roboto Bold'), local('/static/fonts/Roboto-Bold'), url('/static/fonts/Robotobold.woff2') format('woff2'), url('/static/fonts/Robotobold.woff') format('woff'), url('/static/fonts/Robotobold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('/static/fonts/Roboto Bold Italic'), local('/static/fonts/Roboto-BoldItalic'), url('/static/fonts/Robotobolditalic.woff2') format('woff2'), url('/static/fonts/Robotobolditalic.woff') format('woff'), url('/static/fonts/Robotobolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('/static/fonts/Roboto Black'), local('/static/fonts/Roboto-Black'), url('/static/fonts/Robotoblack.woff2') format('woff2'), url('/static/fonts/Robotoblack.woff') format('woff'), url('/static/fonts/Robotoblack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('/static/fonts/Roboto Black Italic'), local('/static/fonts/Roboto-BlackItalic'), url('/static/fonts/Robotoblackitalic.woff2') format('woff2'), url('/static/fonts/Robotoblackitalic.woff') format('woff'), url('/static/fonts/Robotoblackitalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}
