.m-separator {
  border-bottom: .01rem solid $color-border;
  border-top: .01rem solid $color-border;
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {
  }
  @include breakpoint ($for-big-desktop-up) {
  }
}