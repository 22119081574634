.m-manager-module {
  @include breakpoint ($for-phone-only) {
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: .75rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
  }
  @include breakpoint ($for-tablet-landscape-up) {
    background-color: transparent;
    box-shadow: none;
  }
  @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
    background-color: transparent;
    box-shadow: none;
  }
  &__title {
    color: $color-text;
    font-weight: 600;
    @include breakpoint ($for-phone-only) {
      font-size: 1rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      font-size: 1rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      font-size: 1rem;
    }
    @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
      font-size: 1rem;
    }
  }
  &__manager-section {
    &__photo {
      @include breakpoint ($for-phone-only) {
        border-radius: 1.5rem;
        overflow: hidden;

      }
      @include breakpoint ($for-tablet-portrait-up) {
        border-radius: 1.5rem;
        overflow: hidden;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        border-radius: 1.5rem;
        overflow: hidden;
      }
      @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
        border-radius: 1.5rem;
        overflow: hidden;
      }
    }
    &__post {
      @include breakpoint ($for-phone-only) {
        font-size: 1rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        font-size: 1rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        font-size: .75rem;
      }
      @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
        font-size: .75rem;
      }
    }
    &__name {
      font-weight: 600;
      font-size: 1rem;
      @include breakpoint ($for-phone-only) {

      }
      @include breakpoint ($for-tablet-portrait-up) {

      }
      @include breakpoint ($for-tablet-landscape-up) {

      }
      @include breakpoint ($for-desktop-up, $for-big-desktop-up) {

      }
    }

  }
}