.m-sv-control {
  background-color: $color-bg-footer;

  &__block {
  }

  &__list {
  }

  &__item {
    box-sizing: border-box;
    cursor: pointer;
    border-radius: .25rem;
    border: 2px solid transparent;

    &:hover {
      border: 1px solid $color-text;
    }

    &.active {
      border: 2px solid $color-text;
    }

    &--s_normal {
      font-size: 1.25rem;
    }

    &--s_medium {
      font-size: 1.75rem;
    }

    &--s_large {
      font-size: 2rem;
    }

    &--ls_normal {
    }

    &--ls_medium {
      letter-spacing: .125rem;
    }

    &--ls_large {
      letter-spacing: .25rem;
    }

    &--color1 {
    }

    &--color2 {
      background-color: #0b0b0b;
      color: white;
    }

    &--color3 {
      color: #063462;
      background-color: #9dd1ff;
    }

    &--color4 {
      color: #4d4b43;
      background: #f7f3d6;
    }

    &--color5 {
      color: #a9e44d !important;
      background: #3b2716 !important;
    }

    &--image_on {
    }

    &--image_off {
    }
  }
}

[data-size="normal"] {
  font-size: 100%;
}

[data-size="medium"] {
  font-size: 125%;
}

[data-size="large"] {
  font-size: 150%;
}

[data-spacing="normal"] {
  letter-spacing: normal;
}

[data-spacing="medium"] {
  letter-spacing: 1px;
}

[data-spacing="large"] {
  letter-spacing: 2px;
}

[data-contrast="color1"] {
  background-color: inherit;
  color: inherit;
}

[data-contrast="color2"] {
  * {
    background: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
    color: white !important;
  }

  a {
    color: white !important;
  }

  background-color: #0b0b0b !important;

}

[data-contrast="color3"] {
  * {
    background: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
    color: #063462 !important;
  }

  a {
    color: #063462 !important;
  }

  background-color: #9dd1ff !important;

}

[data-contrast="color4"] {
  * {
    background: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
    color: #4d4b43 !important;
  }

  a {
    color: #4d4b43 !important;
  }

  background: #f7f3d6 !important;

}

[data-contrast="color5"] {
  * {
    background: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
    color: #a9e44d !important;
  }

  a {
    color: #a9e44d !important;
  }

  background: #3b2716 !important;

}