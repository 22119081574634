.g-status-implementation {
  display: flex;
  flex-flow: column;
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
    margin-left: 2rem;
    width: 13rem;
    .g-checkbox{
      margin-bottom: .5rem;
    }
  }
  @include breakpoint ($for-desktop-up) {
    margin-left: 2rem;
    width: 13rem;
    .g-checkbox{
      margin-bottom: .5rem;
    }
  }
}