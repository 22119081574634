.m-list-pb {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {
  }
  &__item {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    &-counter {
      @include breakpoint ($for-phone-only) {
        font-size: 2.5em;
        font-weight: 600;
        font-family: 'Century Gothic', sans-serif
      }
      @include breakpoint ($for-tablet-portrait-up) {
        font-size: 2.5em;
        font-weight: 600;
        font-family: 'Century Gothic', sans-serif
      }
      @include breakpoint ($for-tablet-landscape-up) {
        font-size: 2.5em;
        font-weight: 600;
        font-family: 'Century Gothic', sans-serif
      }
      @include breakpoint ($for-desktop-up) {
        font-size: 2.5em;
        font-weight: 600;
        font-family: 'Century Gothic', sans-serif
      }
      &__small {
        @include breakpoint ($for-phone-only) {
          font-size: 1.3em;
          font-weight: 600;
          font-family: 'Century Gothic', sans-serif
        }
        @include breakpoint ($for-tablet-portrait-up) {
          font-size: 1.3em;
          font-weight: 600;
          font-family: 'Century Gothic', sans-serif
        }
        @include breakpoint ($for-tablet-landscape-up) {
          font-size: 1.3em;
          font-weight: 600;
          font-family: 'Century Gothic', sans-serif
        }
        @include breakpoint ($for-desktop-up) {
          font-size: 1.3em;
          font-weight: 600;
          font-family: 'Century Gothic', sans-serif
        }
      }
    }
    &-text {
      @include breakpoint ($for-phone-only) {
        font-size: .9em;
        line-height: 1.2em;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        font-size: .9em;
        line-height: 1.2em;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        font-size: .9em;
        line-height: 1.2em;
      }
      @include breakpoint ($for-desktop-up) {
        font-size: .9em;
        line-height: 1.2em;
      }
    }
  }
}
.m-filter-pb {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {

  }
  &__item {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up) {
    }
    label{
      font-size: .95em;
    }
    input:checked + label {
      background-color: rgba(97, 87, 174, 0.1);
    }
  }
}