.g-footer {
  &__content-1 {
    padding: 1rem;
    @include breakpoint ($for-phone-only) {
      margin:0 .25rem .5rem .25rem;
      order:3;
      flex: 9 0;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin:0 .25rem .5rem .25rem;
      order:3;
      flex: 9 0;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      order:1;
      flex: 4 0;
    }
  }
  &__content-2 {
    padding: 1rem;
    @include breakpoint ($for-phone-only) {
      margin:0 .25rem .5rem .25rem;
      order:1;
      flex: 9 0;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin:0 .25rem .5rem .25rem;
      order:1;
      flex: 9 0;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      order:2;
      flex: 4 0;
    }
  }
  &__content-3 {
    padding: 1rem;
    @include breakpoint ($for-phone-only) {
      margin:0 .25rem .5rem .25rem;
      order:2;
      flex: 9 0;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin:0 .25rem .5rem .25rem;
      order:2;
      flex: 9 0;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      order:3;
      flex: 2 0;
    }
  }
  &__content-4 {
    padding: 1rem;
    flex: 9 100%;
    @include breakpoint ($for-phone-only) {
      order:4;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      order:4;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      order:4;
    }
  }
}