.m-document-group {
  //@include breakpoint ($for-phone-only) {
  //  background-color: white;
  //  box-shadow: 0 5px 15px 0 #bababa;
  //}
  //@include breakpoint ($for-tablet-portrait-up) {
  //  background-color: white;
  //  box-shadow: 0 5px 15px 0 #bababa;
  //}
  //@include breakpoint ($for-tablet-landscape-up) {
  //  background-color: white;
  //  box-shadow: 0 5px 15px 0 #bababa;
  //}
  //@include breakpoint ($for-desktop-up) {
  //  background-color: white;
  //  box-shadow: none;
  //}
  //@include breakpoint ($for-big-desktop-up) {
  //  background-color: white;
  //  box-shadow: none;
  //}
  &__item {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {
    }
  }

  &__fast {
    &__title {
      background-color: $color-green;
      color: white;
      border-radius: 1rem;
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up, $for-big-desktop-up) {

      }

      &-text {

      }

      &-icon {
        font-size: .75rem;
        border-radius: 0.5rem;
        background-color: darken($color-green, 3.5%);
        border: .15rem solid transparent;

        &:hover {
          cursor: pointer;
          border: .15rem solid lighten($color-green, 5%);
        }
      }
    }


    &__list {
      background-color: darken(#FFFAF2, 1.5%);
      border-radius: 1rem;
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {

      }
      @include breakpoint($for-big-desktop-up) {

      }

      &-item {
        max-height: 4rem;
        overflow: hidden;
        &:hover{
          max-height: 100%;
        }
        @include breakpoint($for-phone-only) {
        }
        @include breakpoint($for-tablet-portrait-up) {
        }
        @include breakpoint($for-tablet-landscape-up) {

        }
        @include breakpoint($for-desktop-up) {

        }
        @include breakpoint($for-big-desktop-up) {

        }
      }
    }
  }
}