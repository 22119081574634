.g-child-section {
  box-sizing: border-box;
  display: flex;
  @include breakpoint($for-phone-only) {
    flex-flow: column;
  }
  @include breakpoint($for-tablet-portrait-up) {
    flex-flow: column;
  }
  @include breakpoint($for-tablet-landscape-up) {

    width: max-content;
    flex-flow: row nowrap;
    align-items: center;
  }
  @include breakpoint($for-desktop-up, $for-big-desktop-up) {

    width: max-content;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__lvl-1 {
    @include breakpoint($for-phone-only) {
      display: block;
      position: relative;
      margin-bottom: .5rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      display: block;
      position: relative;
      margin-bottom: .5rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      display: block;
      position: relative;
      margin-right: 1rem;
      margin-bottom: 0;
    }

    &__item {
      @include breakpoint($for-phone-only) {
        //display: inline-block;
      }
      @include breakpoint($for-tablet-portrait-up) {
        //display: inline-block;
      }
      @include breakpoint($for-tablet-landscape-up) {
        display: block;
        width: inherit;
      }
      @include breakpoint($for-desktop-up, $for-big-desktop-up) {
        display: block;
        width: inherit;
      }
    }

    &:hover, &:focus {
      .g-child-section__lvl-2__wrapper {
        display: block;
      }
    }
  }

  &__lvl-2 {
    @include breakpoint($for-phone-only) {
      position: static;
      margin: .75rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      margin: .75rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      margin: 1rem 0 0 0;
      padding: 1rem;
      min-width: 10rem;
      max-width: 15rem;
      width: 12.5rem;
      z-index: 10000;
    }

    &__wrapper {
      @include breakpoint($for-phone-only) {
        display: none;
      }
      @include breakpoint($for-tablet-portrait-up) {
        display: none;
      }
      @include breakpoint($for-tablet-landscape-up) {
        display: none;
        position: absolute;
        left: 0;
        top: 1.35rem;
      }
    }

    &__item {
      @include breakpoint($for-phone-only) {
        margin-bottom: .5rem;

      }
      @include breakpoint($for-tablet-portrait-up) {
        margin-bottom: .5rem;

      }
      @include breakpoint($for-tablet-landscape-up) {
        margin-bottom: .25rem;

      }

      a {
        @include breakpoint($for-phone-only) {
          padding-bottom: .25rem;
          font-size: .8rem;
        }
        @include breakpoint($for-tablet-portrait-up) {
          padding-bottom: .25rem;
          font-size: .8rem;
        }
        @include breakpoint($for-tablet-landscape-up) {
          padding-bottom: .25rem;
          font-size: 1rem;
        }
      }
    }
  }
}