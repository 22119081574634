.g-facs-in-passport {
  @include breakpoint ($for-phone-only) {
    padding-top: 1rem;

  }
  @include breakpoint ($for-tablet-portrait-up) {
    padding-top: 0;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    padding-top: 0;
  }
  @include breakpoint ($for-desktop-up) {
    padding-top: 0;
  }
  @include breakpoint ($for-big-desktop-up) {
    padding-top: 0;
  }
  &__name {
    @include breakpoint ($for-phone-only) {
      margin-bottom: 1rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin-bottom: 1rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin-bottom: 1rem;
    }
    @include breakpoint ($for-desktop-up) {
      margin-bottom: 1rem;
    }
    @include breakpoint ($for-big-desktop-up) {
      margin-bottom: 1rem;
    }
  }
  &__item {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
  &__info {
    @include breakpoint ($for-phone-only) {
      display: flex;
      flex-flow: row wrap;
      margin-bottom: 1rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: 1rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: 2rem;
    }
    @include breakpoint ($for-desktop-up) {
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: 2rem;
    }
    @include breakpoint ($for-big-desktop-up) {
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: 2rem;
    }
    &-form {
      width: 4rem;
      @include breakpoint ($for-phone-only) {
        margin-bottom: 1rem;
        margin-right: 0;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        margin-bottom: 1rem;
        margin-right: 0;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        margin-right: 1rem;
        margin-bottom: 0;
      }
      @include breakpoint ($for-desktop-up) {
        margin-right: 1rem;
        margin-bottom: 0;
      }
      @include breakpoint ($for-big-desktop-up) {
        margin-right: 1rem;
        margin-bottom: 0;
      }
      &__name {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
        }
        @include breakpoint ($for-big-desktop-up) {
        }
      }
      &__duraction {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
        }
        @include breakpoint ($for-big-desktop-up) {
        }
      }
    }
    &-number {
      width: 9rem;
      @include breakpoint ($for-phone-only) {
        margin-bottom: 1rem;
        margin-right: 0;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        margin-bottom: 1rem;
        margin-right: 0;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        margin-right: 1rem;
        margin-bottom: 0;
      }
      @include breakpoint ($for-desktop-up) {
        margin-right: 1rem;
        margin-bottom: 0;
      }
      @include breakpoint ($for-big-desktop-up) {
        margin-right: 1rem;
        margin-bottom: 0;
      }
    }
    &-exam {
      width: 9rem;
      @include breakpoint ($for-phone-only) {
        margin-bottom: 1rem;
        margin-right: 0;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        margin-bottom: 1rem;
        margin-right: 0;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        margin-right: 1rem;
        margin-bottom: 0;
      }
      @include breakpoint ($for-desktop-up) {
        margin-right: 1rem;
        margin-bottom: 0;
      }
      @include breakpoint ($for-big-desktop-up) {
        margin-right: 1rem;
        margin-bottom: 0;
      }
      &__item {
        @include breakpoint ($for-phone-only) {
          margin-bottom: .5rem;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          margin-bottom: .5rem;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          margin-bottom: .5rem;
        }
        @include breakpoint ($for-desktop-up) {
          margin-bottom: .5rem;
        }
        @include breakpoint ($for-big-desktop-up) {
          margin-bottom: .5rem;
        }
      }
    }
    &-sum {
      width: 9rem;
      @include breakpoint ($for-phone-only) {
        margin-bottom: 1rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        margin-bottom: 1rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        margin-bottom: 0;
      }
      @include breakpoint ($for-desktop-up) {
        margin-bottom: 0;
      }
      @include breakpoint ($for-big-desktop-up) {
        margin-bottom: 0;
      }
    }
  }
}