.g-checkbox {
  display: inline-block;
  vertical-align: middle;

  &__input {
    display: none;

    &:checked + .g-checkbox__custom-input:before {
      position: absolute;
      top: -0.2rem;
      left: .2rem;
      display: block;
      width: 1.1rem;
      height: 1.1rem
    }
  }

  &__custom-input {
    display: inline-block;
    vertical-align: middle;
    width: 1rem;
    height: 1rem;
    position: relative;
    margin-right: .5rem;
  }

  &__label {
    color: $color-text;
    cursor: pointer;
    top: 1px;
    position: relative;
  }
}