.m-navigation {
  &__modal-wrapper {
  }

  &__hot-links {
    &-item {
      font-family: 'Century Gothic', sans-serif;
      @include breakpoint($for-phone-only) {
        font-size: 1em;
      }
      @include breakpoint($for-tablet-portrait-up) {
        font-size: 1em;
      }
      @include breakpoint($for-tablet-landscape-up) {
        font-size: 1.2em;
      }

      &-important {
        font-size: 1.7em;
      }
    }
  }

  &__list-item {

  }

  &-document-group {
    //@include breakpoint ($for-phone-only) {
    //  background-color: white;
    //  box-shadow: 0 5px 15px 0 #bababa;
    //}
    //@include breakpoint ($for-tablet-portrait-up) {
    //  background-color: white;
    //  box-shadow: 0 5px 15px 0 #bababa;
    //}
    //@include breakpoint ($for-tablet-landscape-up) {
    //  background-color: white;
    //  box-shadow: 0 5px 15px 0 #bababa;
    //}
    //@include breakpoint ($for-desktop-up) {
    //  background-color: white;
    //  box-shadow: none;
    //}
    //@include breakpoint ($for-big-desktop-up) {
    //  background-color: white;
    //  box-shadow: none;
    //}
  }

  &__section {
    &-item {
      font-size: .99264em;
    }
  }

  &-mobile-home {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {
    }
  }

  &_edit {
    font-size: .9em;
    //background-color: mapget($color-btn, primary);
    //border-radius: .8rem;
    //a{color: white;}
  }

  &-module {
    &__edit {
      @include breakpoint($for-phone-only) {
        border-radius: 0;
        background-color: $color-light-blue;
        //@include link-colors(white, white, white, white, white);
        &:hover {
          background-color: lighten($color-light-blue, 5%);
        }
      }
      @include breakpoint($for-tablet-portrait-up) {
        border-radius: 0;
        background-color: $color-light-blue;
        //@include link-colors(white, white, white, white, white);
        &:hover {
          background-color: lighten($color-light-blue, 5%);
        }
      }
      @include breakpoint($for-tablet-landscape-up) {
        border-radius: .5rem;
        background-color: $color-light-blue;
        //@include link-colors(white, white, white, white, white);
        &:hover {
          background-color: lighten($color-light-blue, 5%);
        }
      }
      @include breakpoint($for-desktop-up) {
        border-radius: .5rem;
        background-color: $color-light-blue;
        //@include link-colors(white, white, white, white, white);
        &:hover {
          background-color: lighten($color-light-blue, 5%);
        }
      }
      @include breakpoint($for-big-desktop-up) {
        border-radius: .5rem;
        background-color: $color-light-blue;
        //@include link-colors(white, white, white, white, white);
        &:hover {
          background-color: lighten($color-light-blue, 5%);
        }
      }
    }

    &__draft-help {
      font-size: .9em;
      @include breakpoint($for-phone-only) {
        border-left: 0.1rem solid mapget($color-map, red);
      }
      @include breakpoint($for-tablet-portrait-up) {
        border-left: 0.1rem solid mapget($color-map, red);
      }
      @include breakpoint($for-tablet-landscape-up) {
        border-left: 0.1rem solid mapget($color-map, red);
      }
      @include breakpoint($for-desktop-up) {
        border-left: 0.1rem solid mapget($color-map, red);
      }
      @include breakpoint($for-big-desktop-up) {
        border-left: 0.1rem solid mapget($color-map, red);
      }
    }

    &__more {
      z-index: 5000;
      background: white;

      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {
        border: 1px solid $color-border-second-menu;
        border-radius: 1rem;
        box-shadow: 0 13px 15px -5px $color-shadow-second-menu;
      }
      @include breakpoint($for-desktop-up) {
        border: 1px solid $color-border-second-menu;
        border-radius: 1rem;
        box-shadow: 0 13px 15px -5px $color-shadow-second-menu;

      }

      .m-navigation-module__lvl-1 {
        &:hover {
          .m-navigation-module__lvl-2__wrapper {
            border: 0;
            border-radius: 0;
            box-shadow: none;
          }
        }

        &__item {
          border-bottom: 1px solid transparent;
          font-size: .8rem;
          line-height: 1rem;

          &:focus, &:visited, &:active {
            border-bottom: 1px solid transparent;
            color: $color-link-normal;
          }

          &:hover {
            border-bottom: 1px solid $color-text-orange-underline-light;
          }

          @include breakpoint($for-phone-only) {
          }
          @include breakpoint($for-tablet-portrait-up) {
            @include link-colors($color-link-normal, $color-link-hover, $color-link-visited, $color-link-active, $color-link-focus);
          }
          @include breakpoint($for-tablet-landscape-up) {
            @include link-colors($color-link-normal, $color-link-hover, $color-link-visited, $color-link-active, $color-link-focus);
          }
          @include breakpoint($for-desktop-up) {
            @include link-colors($color-link-normal, $color-link-hover, $color-link-visited, $color-link-active, $color-link-focus);
          }
        }

      }
    }

    &__lvl-1 {
      &:hover {
        .m-navigation-module__lvl-2__wrapper {
          background: white;
          @include breakpoint($for-phone-only) {

          }
          @include breakpoint($for-tablet-portrait-up) {
            border: 1px solid $color-border-second-menu;
            border-radius: 1rem;
            box-shadow: 0 13px 15px -5px $color-shadow-second-menu;
          }
          @include breakpoint($for-tablet-landscape-up) {
            border: 1px solid $color-border-second-menu;
            border-radius: 1rem;
            box-shadow: 0 13px 15px -5px $color-shadow-second-menu;
          }
        }
      }

      &__item {
        cursor: pointer;
        //text-transform: lowercase;

        &:first-letter {
          text-transform: uppercase;
        }

        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

          white-space: nowrap;
        }
        @include breakpoint($for-desktop-up) {

          white-space: nowrap;
        }

        &-draft {
          color: $color-border-light !important;
          border-left: .1rem solid darkred;
          padding-left: .5rem;
          text-transform: lowercase;

          &:first-letter {
            text-transform: uppercase;
          }
        }
      }

      &-last {
        background-color: $color-green;
        color: white;
        font-size: .75rem;
        border-radius: .375rem;
        cursor: pointer;
      }
    }

    &__lvl-2 {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {

      }

      &__item {
        &:first-letter {
          text-transform: uppercase;
        }

        @include breakpoint($for-phone-only) {
          line-height: 1.7rem;
          font-size: .8rem;
        }
        @include breakpoint($for-tablet-portrait-up) {
          line-height: 1.7rem;
          font-size: .8rem;
        }
        @include breakpoint($for-tablet-landscape-up) {
          border-bottom: 1px solid transparent;
          line-height: 1.4rem;
          font-size: .8rem;

          &:focus, &:active {
            border-bottom: 1px solid transparent;
          }
        }
      }
    }

    &__lvl-3 {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {

      }

      &__item {
        text-transform: lowercase;

        &:first-letter {
          text-transform: uppercase;
        }

        @include breakpoint($for-phone-only) {
        }
        @include breakpoint($for-tablet-portrait-up) {
        }
        @include breakpoint($for-tablet-landscape-up) {
          font-size: 90%;
        }
      }
    }

    &__lvl-4 {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {

      }

      &__item {
        text-transform: lowercase;

        &:first-letter {
          //text-transform: uppercase;
        }

        @include breakpoint($for-phone-only) {
        }
        @include breakpoint($for-tablet-portrait-up) {
        }
        @include breakpoint($for-tablet-landscape-up) {
          font-size: 90%;
        }
      }
    }

    &__lvl-5 {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {

      }

      &__item {
        //text-transform: lowercase;

        &:first-letter {
          text-transform: uppercase;
        }

        @include breakpoint($for-phone-only) {
        }
        @include breakpoint($for-tablet-portrait-up) {
        }
        @include breakpoint($for-tablet-landscape-up) {
          font-size: 90%;
        }
      }
    }

    &__draft {
      color: $color-text-grey-light-30;
      border-bottom: 1px solid $color-text-grey-underline-light-30;
      //text-transform: lowercase;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

  &__m_s {
    @include breakpoint($for-phone-only) {
      box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
      border-radius: .75rem;

    }
    @include breakpoint($for-tablet-portrait-up) {
      box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
      border-radius: .75rem;

    }
    @include breakpoint($for-tablet-landscape-up) {
      box-shadow: none;
      border-radius: 0;
    }

    &__wrapper-1 {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
    }

    &__wrapper-2 {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
    }

    &__lvl-1 {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }

      &__draft {
        color: $color-text-grey-light-30;
        border-bottom: 1px solid $color-text-grey-underline-light-30;
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
      }

      &__item {
        @include breakpoint($for-phone-only) {
          font-size: .875rem;

        }
        @include breakpoint($for-tablet-portrait-up) {
          font-size: .875rem;
        }
        @include breakpoint($for-tablet-landscape-up) {
          font-size: .875rem;
        }
      }
    }

    &__lvl-2 {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }

      &__draft {
        color: $color-text-grey-light-30;
        border-bottom: 1px solid $color-text-grey-underline-light-30;
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
      }

      &__item {
        @include breakpoint($for-phone-only) {
          font-size: .8rem;
          line-height: 1.7rem;
        }
        @include breakpoint($for-tablet-portrait-up) {
          font-size: .8rem;
          line-height: 1.7rem;
        }
        @include breakpoint($for-tablet-landscape-up) {
          font-size: .75rem;
        }
      }
    }
  }

  //&_edit {
  //  font-size: .8em;
  //  line-height: .75rem;
  //  border-radius: .315rem;
  //  border: 1px solid #82D097;
  //  background-color: rgba(56, 184, 92, 0.05);
  //  box-shadow: 0 0 0 1px #D7F1DE;
  //  color: #38B85C;
  //  z-index: 1;
  //
  //  &:hover {
  //    background-color: rgba(56, 184, 92, 0.15);
  //    text-decoration: none;
  //    color: #38B85C;
  //    border: 1px solid #82D097;
  //  }
  //
  //  &:visited, &:active {
  //    text-decoration: none;
  //    color: #38B85C;
  //    border: 1px solid #82D097;
  //  }
  //}
}


.m-modal-window .m-navigation {
  &__section {
    &-item {

    }
  }
}

.m-top-navigation {
  &__icon {
  }

  &__icon-close {
    background-image: url('/static/img/close.svg');
    background-repeat: no-repeat;
    background-size: 45%;
    background-position: 40% 50%;
  }

  &__list {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }

    &-section {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {
        z-index: 100;
      }
    }
  }
}

.m-top-navigation__section {
  &-item-title {
    font-family: 'Futura PT', sans-serif;
    @include breakpoint($for-phone-only) {
      font-size: 1.2em;
      color: $color-text;
      font-weight: bold;
    }
    @include breakpoint($for-tablet-portrait-up) {
      font-size: 1.2em;
      color: $color-text;
      font-weight: bold;
    }
    @include breakpoint($for-tablet-landscape-up) {
      border: 0;
      background: transparent;
      font-size: .875rem;
      font-weight: 600;
      color: $color-text-light;
    }

    &:after {
      border-radius: .25rem;
      background-color: $color-m-top-navigation__section-item-title-border;
    }
  }

  &-item {
    a {
      border-bottom: 0;

      &:visited, &:active, &:focus {
        border-bottom: 0;
      }

      &:hover {
        border-bottom: 0;
      }
    }

    @include breakpoint($for-phone-only) {
      line-height: 1.2em;
    }
    @include breakpoint($for-tablet-portrait-up) {
      line-height: 1.2em;
    }
    @include breakpoint($for-tablet-landscape-up) {
      font-size: .9em;
      line-height: 1.4em;
    }
  }
}

.m-navigation-window-content__item{
  .m-top-navigation__section {
      &-item a{
          border-bottom: 1px solid $color-link-underline;

        &:visited, &:active, &:focus {
          border-color:  $color-link-underline;
        }

        &:hover {
          border-color:  $color-text-orange-underline-light;
        }
      }
  }
}
//.m-applicant {
//  .m-top-navigation__section-item-title {
//    font-family: 'Century Gothic', sans-serif;
//    @include breakpoint($for-phone-only) {
//    }
//    @include breakpoint($for-tablet-portrait-up) {
//    }
//    @include breakpoint($for-tablet-landscape-up) {
//      color: mapget($color-btn, danger);
//    }
//  }
//}
//
//.m-students {
//  .m-top-navigation__section-item-title {
//    font-family: 'Century Gothic', sans-serif;
//    @include breakpoint($for-phone-only) {
//    }
//    @include breakpoint($for-tablet-portrait-up) {
//    }
//    @include breakpoint($for-tablet-landscape-up) {
//      color: mapget($color-btn, success);
//    }
//  }
//}
//
//.m-common {
//  .m-top-navigation__section-item-title {
//    font-family: 'Century Gothic', sans-serif;
//    @include breakpoint($for-phone-only) {
//    }
//    @include breakpoint($for-tablet-portrait-up) {
//    }
//    @include breakpoint($for-tablet-landscape-up) {
//      color: mapget($color-btn, pink);
//    }
//  }
//}

.m-main__wrapper-sidebar, .m-main__wrapper-modules, .m-navigation-document-group {
  .m-navigation__list-item {
    &:not(:empty) {
      @include breakpoint($for-phone-only) {
        background-color: white;
        box-shadow: 0 5px 15px 0 #bababa;
      }
      @include breakpoint($for-tablet-portrait-up) {
        background-color: white;
        box-shadow: 0 5px 15px 0 #bababa;
      }
      @include breakpoint($for-tablet-landscape-up) {
        background-color: white;
        box-shadow: none;
      }
      @include breakpoint($for-desktop-up) {
        background-color: white;
        box-shadow: none;
      }
      @include breakpoint($for-big-desktop-up) {
        background-color: white;
        box-shadow: none;
      }
    }
  }

  .m-navigation-module__lvl-1__item {

  }

  .m-navigation-module__lvl-2__item {

  }
}

.m-header-mobile__bottom .m-navigation {
  @include breakpoint($for-phone-only) {
  }
  @include breakpoint($for-tablet-portrait-up) {
  }
  @include breakpoint($for-tablet-landscape-up) {
  }
  @include breakpoint($for-desktop-up) {
  }
  @include breakpoint($for-big-desktop-up) {

  }

  &__item {
    color: $color-light-blue;
    @include breakpoint($for-phone-only) {
      font-size: .75rem;
      border: 0;

      &:visited, &:active, &:focus, &:hover {
        border: 0;
      }
    }
    @include breakpoint($for-tablet-portrait-up) {
      font-size: .75rem;
      border: 0;

      &:visited, &:active, &:focus, &:hover {
        border: 0;
      }
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {
    }
  }
}