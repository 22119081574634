.g-top-bar {
  &__module {
    @include breakpoint($for-phone-only) {
      display: none;
    }
    @include breakpoint($for-tablet-portrait-up) {
      display: none;
    }
    @include breakpoint($for-tablet-landscape-up) {
      flex: 1 0 auto;
      display: flex;
      align-items: center;

    }
    @include breakpoint($for-desktop-up) {
      flex: 1 0 auto;
      display: flex;
      align-items: center;

    }
    @include breakpoint($for-big-desktop-up) {
      flex: 1 0 auto;
      display: flex;
      align-items: center;
    }
  }

  &__navigation {
    .g-button-navigation {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-menu {
        width: 1.25rem;
      }
    }

    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {

    }
    @include breakpoint($for-desktop-up) {;
    }
    @include breakpoint($for-big-desktop-up) {

    }
  }

  &__logo {
    display: flex;
    align-items: center;

    a {
      padding: 0;
    }

    &-icon {
      width: 2rem;
      //padding-top: .4rem;
    }

    @include breakpoint($for-phone-only) {
      padding: 0
    }
    @include breakpoint($for-tablet-portrait-up) {
      padding: 0
    }
    @include breakpoint($for-tablet-landscape-up) {
      padding: 0;
      margin: 0 1.25rem;
    }
    @include breakpoint($for-desktop-up) {
      padding: 0;
      margin: 0 1.25rem;
    }
    @include breakpoint($for-big-desktop-up) {
      padding: 0;
      margin: 0 1.25rem;
    }

    &-mobile {
      @include breakpoint($for-phone-only) {
        display: flex;
      }
      @include breakpoint($for-tablet-portrait-up) {
        display: flex;
      }
      @include breakpoint($for-tablet-landscape-up) {
        display: none;
      }
      @include breakpoint($for-desktop-up) {
        display: none;
      }
      @include breakpoint($for-big-desktop-up) {
        display: none;
      }
    }

    @include breakpoint($for-phone-only) {
      flex: 1 0 auto;
    }
    @include breakpoint($for-tablet-portrait-up) {
      flex: 1 0 auto;
    }
    @include breakpoint($for-tablet-landscape-up) {
      flex: 0 0 auto;
    }
    @include breakpoint($for-desktop-up) {
      flex: 0 0 auto;
    }
    @include breakpoint($for-big-desktop-up) {
      flex: 0 0 auto;
    }
  }

  &__breadcrumb {
    align-items: center;

    @include breakpoint($for-phone-only) {
      display: none;
    }
    @include breakpoint($for-tablet-portrait-up) {
      display: none;
    }
    @include breakpoint($for-tablet-landscape-up) {
      flex: 1 0 auto;
      display: flex;
    }
    @include breakpoint($for-desktop-up) {
      flex: 1 0 auto;
      display: flex;
    }
    @include breakpoint($for-big-desktop-up) {
      flex: 1 0 auto;
      display: flex;
    }
  }

  &__auth {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;

    .icon-auth {
      width: 1.1em;
      height: 100%;
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {


      }
      @include breakpoint($for-big-desktop-up) {


      }
    }

    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {


    }
    @include breakpoint($for-desktop-up) {


    }
    @include breakpoint($for-big-desktop-up) {

    }
  }

  &__search {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-search {
      width: 1.1em;
      height: 100%;
    }

    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {

      position: relative;
      margin-right: .5rem;
    }
    @include breakpoint($for-desktop-up) {

      position: relative;
      margin-right: 0;
    }
    @include breakpoint($for-big-desktop-up) {

      position: relative;
    }
  }

  &__eye {
    display: flex;
    align-items: center;
    justify-content: center;

    .g-icon-eye {
      width: 1.4em;
      height: 100%;
    }

    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
      position: relative;
      margin-right: 1.5rem;
    }
    @include breakpoint($for-desktop-up) {
      position: relative;
      margin-right: 1.5rem;
    }
    @include breakpoint($for-big-desktop-up) {
      position: relative;
      margin-right: 1.5rem;
    }
  }

  &__languages {
    display: flex;
    align-items: center;
    justify-content: center;

    .g-language {
      @include breakpoint($for-phone-only) {
        margin: 0 .8rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        margin: 0 1rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        margin: 0 .25rem;
      }
      @include breakpoint($for-desktop-up) {
        margin: 0 .25rem;
      }
      @include breakpoint($for-big-desktop-up) {
        margin: 0 .25rem;
      }
    }

    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {
      margin-right: 1.5rem;
    }
    @include breakpoint($for-desktop-up) {
      margin-right: 1.5rem;
    }
    @include breakpoint($for-big-desktop-up) {
      margin-right: 1.5rem;
    }
  }
}