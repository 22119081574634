.m-banner {
  background-color: #FCFCFB;
  @include breakpoint($for-phone-only) {
    overflow: hidden;
    border-bottom: 1px solid $color-border;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: .75rem;
  }
  @include breakpoint($for-tablet-portrait-up) {
    overflow: hidden;
    border-bottom: 1px solid $color-border;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: .75rem;

  }
  @include breakpoint($for-tablet-landscape-up) {
    overflow: hidden;
    border-radius: 1.5rem;
    border-bottom: 0;
    box-shadow: none;
  }
  @include breakpoint($for-desktop-up) {
    overflow: inherit;
    border-radius: 1.5rem;
    border-bottom: 0;
    box-shadow: none;
  }

  &__preloader {
    border-radius: 50%;
    font-size: .6em;
    text-indent: -9999em;
    border-top: .4em solid rgba(61, 108, 179, 0.27);
    border-right: .4em solid rgba(61, 108, 179, 0.27);
    border-bottom: .4em solid rgba(61, 108, 179, 0.27);
    border-left: .4em solid $color-text-blue;
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {

    }
    @include breakpoint($for-desktop-up) {

    }
  }

  &__list {
    @include breakpoint($for-phone-only) {
      opacity: 1;
    }
    @include breakpoint($for-tablet-portrait-up) {
      opacity: 0;
    }
    @include breakpoint($for-tablet-landscape-up) {
      opacity: 0;
    }
    @include breakpoint($for-desktop-up) {
      opacity: 1;
    }

    &-item {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
        &:hover, &:focus {
          cursor: pointer;
        }
      }

      &-top {

        @include breakpoint($for-phone-only) {
        }
        @include breakpoint($for-tablet-portrait-up) {
        }
        @include breakpoint($for-tablet-landscape-up) {
          background-color: $color-banner-top-bg;
          border-radius: 1.5rem;
          background: url("/static/img/bg_banner-top.svg") no-repeat;
          background-size: cover;
        }
        @include breakpoint($for-desktop-up) {
          background-color: $color-banner-top-bg;
          border-radius: 1.5rem;
          background: url("/static/img/bg_banner-top.svg") no-repeat;
          background-size: cover;
        }
      }

      &-title {
        font-weight: 600;
        border-bottom: 1px solid transparent;
        font-size: .875rem;
        line-height: 1.2rem;

        &-top {
          border-bottom: 0;
          font-family: 'Futura PT', sans-serif;
          color: $color-text-orange-light;
          @include breakpoint($for-phone-only) {
            font-size: 1.3rem;
            line-height: 1.5rem;
          }
          @include breakpoint($for-tablet-portrait-up) {
            font-size: 1.3rem;
            line-height: 1.5rem;
          }
          @include breakpoint($for-tablet-landscape-up) {
            font-size: 1.3rem;
            line-height: 1.5rem;
          }
          @include breakpoint($for-desktop-up) {
            font-size: 1.3rem;
            line-height: 1.5rem;
          }

          &:hover, &:visited, &:active, &:focus {
            border-bottom: 0;
            color: $color-text-orange-light-hover;
          }
        }
      }

      &-text {
        font-size: .75rem;

        &-top {
          font-size: .75rem;
          @include breakpoint($for-phone-only) {

          }
          @include breakpoint($for-tablet-portrait-up) {

          }
          @include breakpoint($for-tablet-landscape-up) {

          }
          @include breakpoint($for-desktop-up) {

          }
        }
      }

      &-img_1 {
        background: url(/static/img/9may.png) 100% 100% no-repeat;
        background-size: contain;
      }

      &-attention {
        @include breakpoint($for-phone-only) {
          border-radius: 0;
          background-color: mapget($color-bg-status, danger);
        }
        @include breakpoint($for-tablet-portrait-up) {
          border-radius: 0;
          background-color: mapget($color-bg-status, danger);
        }
        @include breakpoint($for-tablet-landscape-up) {
          border-radius: 0;
          background-color: mapget($color-bg-status, danger);
        }
        @include breakpoint($for-desktop-up) {
          border-radius: 0 .25rem .25rem 0;
          background-color: mapget($color-bg-status, danger);
        }
      }
    }
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
