.g-news-carousel {
  &__item {
    //position: relative;
    &-wrapper {
      position: absolute;
      width: 100%;
      top: 0;
      @include breakpoint ($for-phone-only) {

      }
      @include breakpoint ($for-tablet-portrait-up) {
        padding-top: 3rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        padding-top: 3rem;
        top: 2.5rem
      }
      @include breakpoint ($for-desktop-up) {
        padding-top: 3rem;
        top: 2.5rem
      }
      @include breakpoint ($for-big-desktop-up) {
        padding-top: 8rem;
        top: -2.5rem
      }
      &-text {
        margin: 0 auto;
        align-items: flex-end;
        flex-direction: column;
        @include breakpoint ($for-phone-only) {
          width: 100%;
          display: none;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          width: 100%;
          display: flex;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          width: 100%;
          display: flex;
        }
        @include breakpoint ($for-desktop-up) {
          width: 1200px;
          display: flex;
        }
        @include breakpoint ($for-big-desktop-up) {
          width: 1500px;
          display: flex;
        }
        &-title {
          position: relative;
          @include breakpoint ($for-phone-only) {
            width: 100%;
          }
          @include breakpoint ($for-tablet-portrait-up) {
            width: 40%;
            padding-right: 5%;
          }
          @include breakpoint ($for-tablet-landscape-up) {
            width: 45%;
          }
          @include breakpoint ($for-desktop-up) {
            width: 45%;
            padding-right: 5%;
          }
          @include breakpoint ($for-big-desktop-up) {
            width: 45%;
            padding-right: 5%;
          }
          a {
            display: inline-block;
            max-width: 100%;
          }
        }
        &-paragraph {
          position: relative;
          @include breakpoint ($for-phone-only) {
            width: 100%;
          }
          @include breakpoint ($for-tablet-portrait-up) {
            width: 40%;
            padding-right: 5%;
            height: 5rem;
          }
          @include breakpoint ($for-tablet-landscape-up) {
            width: 45%;
            height: 18rem;
          }
          @include breakpoint ($for-desktop-up) {
            width: 45%;
            padding-right: 5%;
          }
          @include breakpoint ($for-big-desktop-up) {
            width: 45%;
            padding-right: 5%;
          }
        }
      }
    }
    &-img{
      width: 100%;
      @include breakpoint ($for-phone-only) {
        height: 15rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        height: 15rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        height: 15rem;
      }
      @include breakpoint ($for-desktop-up) {
        height: 15rem;
      }
      @include breakpoint ($for-big-desktop-up) {
        height: 18rem;
      }
    }
  }
}