.g-form_education_at_profile {
  @include breakpoint ($for-phone-only) {
    padding: 0;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    padding: 0;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    padding-right: 1rem;
    min-width: 12rem;
  }
  @include breakpoint ($for-desktop-up) {
    padding-right: 1rem;
    min-width: 12rem;
  }
  @include breakpoint ($for-big-desktop-up) {
    padding-right: 1rem;
    min-width: 12rem;
  }
  &-name {
    @include breakpoint ($for-phone-only) {
      margin-bottom: 1rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-desktop-up) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-big-desktop-up) {
      margin-bottom: .5rem;
    }
  }
  &-list_fac {
    @include breakpoint ($for-phone-only) {
      display: flex;
      flex-flow: column
    }
    @include breakpoint ($for-tablet-portrait-up) {
      display: flex;
      flex-flow: column;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      display: flex;
      flex-flow: row nowrap;
    }
    @include breakpoint ($for-desktop-up) {
      display: flex;
      flex-flow: row nowrap;
    }
    @include breakpoint ($for-big-desktop-up) {
      display: flex;
      flex-flow: row nowrap;
    }
    &__item {
      @include breakpoint ($for-phone-only) {
        &:not(:last-child) {
          margin-right: 0;
        }
      }
      @include breakpoint ($for-tablet-portrait-up) {
        &:not(:last-child) {
          margin-right: 0;
        }
      }
      @include breakpoint ($for-tablet-landscape-up) {
        &:not(:last-child) {
          margin-right: 2rem;
        }
      }
      @include breakpoint ($for-desktop-up) {
        &:not(:last-child) {
          margin-right: 2rem;
        }
      }
      @include breakpoint ($for-big-desktop-up) {
        &:not(:last-child) {
          margin-right: 2rem;
        }
      }
    }
  }
}