.g-jstabs {
  @include breakpoint($for-phone-only) {
    padding: 1rem;
    margin: 0 .25rem;
  }
  @include breakpoint($for-tablet-portrait-up) {
    padding: 1rem;
    margin: 0 .25rem;
  }
  @include breakpoint($for-tablet-landscape-up) {
    padding: 0;
    margin: 0;
  }
  @include breakpoint($for-desktop-up) {
  }
  @include breakpoint($for-big-desktop-up) {
  }

  &__labels {
    display: inline-flex;
    @include breakpoint($for-phone-only) {
      flex-flow: column;
      padding: .25rem .125rem;
      margin-bottom: 1.25rem;
      width: 100%;
    }
    @include breakpoint($for-tablet-portrait-up) {
      width: 100%;
      flex-flow: column;
      padding: .25rem .125rem;
      margin-bottom: 1.25rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      width: auto;
      flex-flow: row nowrap;
      padding: .25rem .125rem;
      margin-bottom: 1.25rem;
    }
    @include breakpoint($for-desktop-up) {
      width: auto;
      flex-flow: row nowrap;
      padding: .25rem .125rem;
      margin-bottom: 1.25rem;
    }
  }

  &__label {
    @include breakpoint($for-phone-only) {
      margin: 0 .125rem;
      padding: .75rem 1rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      margin: 0 .125rem;
      padding: .75rem 1rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      margin: 0 .125rem;
      padding: .5rem 1rem;
    }
    @include breakpoint($for-desktop-up) {
      margin: 0 .125rem;
      padding: .5rem 1rem;
    }

    &-active {
    }
  }

  &__content {
    display: none;

    &-active {
      display: block;
    }

    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {
    }
  }
}