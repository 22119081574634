.g-filter-doc-education {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  @include breakpoint ($for-desktop-up) {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__item {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
      &:not(:last-child) {
        margin-right: .5rem;
      }
    }
    @include breakpoint ($for-desktop-up) {
      &:not(:last-child) {
        margin-right: .5rem;
      }
    }
  }

}