.g-logo{
  @include breakpoint ($for-phone-only) {
    width: 3.5rem;
    margin-bottom: .5rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    width: 4rem;
    margin-right: 1rem;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    width: 6.5rem;
  }
  @include breakpoint ($for-desktop-up) {

  }
  @include breakpoint ($for-big-desktop-up) {

  }
}