.g-page {
  @include breakpoint ($for-phone-only) {
    padding: 1rem;
    margin-bottom: 1rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    padding: 1rem;
    margin-bottom: 1rem;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    padding: 1rem;
    margin: 0 0 2rem 0;
  }
  @include breakpoint ($for-desktop-up) {
    padding: 0;
    margin: 0 0 2rem 0;
  }
  &__title {
    @include breakpoint ($for-phone-only) {
      margin-bottom: .5rem;
      margin-top: 2rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin-bottom: .5rem;
      margin-top: 2rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin-bottom: .5rem;
      margin-top: 4rem;
    }
    @include breakpoint ($for-desktop-up) {
      margin-bottom: .5rem;
      margin-top: 4rem;
    }
    &-light {
      @include breakpoint ($for-phone-only) {
        padding: .01rem 1rem;
        margin-bottom: 1rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        padding: .01rem 1rem;
        margin-bottom: 1rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        padding: .01rem 1rem;
        margin-bottom: 1rem;
      }
      @include breakpoint ($for-desktop-up) {
        padding: 0;
        margin: 4rem 0 2rem 0;
      }
    }
    &-custom {
      @include breakpoint ($for-phone-only) {

      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-end;
      }
      &__item {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
        }
      }

    }
  }
  &__subtitle {
    @include breakpoint ($for-phone-only) {
      margin-top: 1rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin-top: 1rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin-top: 2rem;
    }
    @include breakpoint ($for-desktop-up) {
      margin-top: 2rem;
    }
  }
}