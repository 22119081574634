//@import "normalize";
@import "variables";
@import "breakpoint";
@import "mixins";
@import "base";
@import "modificators";
/* inject:imports */
@import "./staticfiles/scss/_animate.scss";
@import "./staticfiles/scss/_base.scss";
@import "./staticfiles/scss/_mixins.scss";
@import "./staticfiles/scss/_modificators.scss";
@import "./staticfiles/scss/_variables.scss";
@import "./staticfiles/scss/accounts/_g-accounts.scss";
@import "./staticfiles/scss/accounts/_m-accounts.scss";
@import "./staticfiles/scss/alphabet-sort/_g-alphabet-sort.scss";
@import "./staticfiles/scss/alphabet-sort/_m-alphabet-sort.scss";
@import "./staticfiles/scss/auth/_g-auth.scss";
@import "./staticfiles/scss/auth/_m-auth.scss";
@import "./staticfiles/scss/authorized-user/_g-authorized-user.scss";
@import "./staticfiles/scss/authorized-user/_m-authorized-user.scss";
@import "./staticfiles/scss/bootstrap-select/_bootstrap-select.scss";
@import "./staticfiles/scss/bootstrap-select/_bootstrap.scss";
@import "./staticfiles/scss/breadcrumb/_g-breadcrumb.scss";
@import "./staticfiles/scss/breadcrumb/_m-breadcrumb.scss";
@import "./staticfiles/scss/celebrations/_g-celebrations.scss";
@import "./staticfiles/scss/celebrations/_m-celebrations.scss";
@import "./staticfiles/scss/checkbox/_g-checkbox.scss";
@import "./staticfiles/scss/checkbox/_m-checkbox.scss";
@import "./staticfiles/scss/child-section/_g-child-section.scss";
@import "./staticfiles/scss/child-section/_m-child-section.scss";
@import "./staticfiles/scss/comment/_g-comment.scss";
@import "./staticfiles/scss/comment/_m-comment.scss";
@import "./staticfiles/scss/contact/_g-contact.scss";
@import "./staticfiles/scss/contact/_m-contact.scss";
@import "./staticfiles/scss/contacts-module/_g-contacts-module.scss";
@import "./staticfiles/scss/contacts-module/_m-contacts-module.scss";
@import "./staticfiles/scss/content-header/_g-content-header.scss";
@import "./staticfiles/scss/content-header/_m-content-header.scss";
@import "./staticfiles/scss/content-header-section/_g-content-header.scss";
@import "./staticfiles/scss/content-header-section/_m-content-header.scss";
@import "./staticfiles/scss/copyright/_g-copyright.scss";
@import "./staticfiles/scss/copyright/_m-copyright.scss";
@import "./staticfiles/scss/description-module/_g-description-module.scss";
@import "./staticfiles/scss/description-module/_m-description-module.scss";
@import "./staticfiles/scss/document-group/_g-document-group.scss";
@import "./staticfiles/scss/document-group/_m-document-group.scss";
@import "./staticfiles/scss/banner/_g-banner.scss";
@import "./staticfiles/scss/banner/_m-banner.scss";
@import "./staticfiles/scss/document-list/_g-document-list.scss";
@import "./staticfiles/scss/document-list/_m-document-list.scss";
@import "./staticfiles/scss/button-navigation/_g-button-navigation.scss";
@import "./staticfiles/scss/button-navigation/_m-button-navigation.scss";
@import "./staticfiles/scss/education-list-fac/_g-education-list-fac.scss";
@import "./staticfiles/scss/education-list-fac/_m-education-list-fac.scss";
@import "./staticfiles/scss/effective-contract-pps-edit/_g-effective-contract-pps-edit.scss";
@import "./staticfiles/scss/effective-contract-pps-edit/_m-effective-contract-pps-edit.scss";
@import "./staticfiles/scss/facs-in-passport/_g-facs-in-passport.scss";
@import "./staticfiles/scss/facs-in-passport/_m-facs-in-passport.scss";
@import "./staticfiles/scss/effective-contract-pps-view/_g-effective-contract-pps-view.scss";
@import "./staticfiles/scss/effective-contract-pps-view/_m-effective-contract-pps-view.scss";
@import "./staticfiles/scss/feedback-ec/_g-feedback-ec.scss";
@import "./staticfiles/scss/feedback-ec/_m-feedback-ec.scss";
@import "./staticfiles/scss/filter-doc-education/_g-filter-doc-education.scss";
@import "./staticfiles/scss/filter-doc-education/_m-filter-doc-education.scss";
@import "./staticfiles/scss/filter-statuses/_g-filter-statuses.scss";
@import "./staticfiles/scss/filter-struct-ec/_g-filter-struct-ec.scss";
@import "./staticfiles/scss/filter-struct-ec/_m-filter-struct-ec.scss";
@import "./staticfiles/scss/form/_g-form.scss";
@import "./staticfiles/scss/form/_m-form.scss";
@import "./staticfiles/scss/footer/_g-footer.scss";
@import "./staticfiles/scss/footer/_m-footer.scss";
@import "./staticfiles/scss/form_education_at_profile/_g-form_education_at_profile.scss";
@import "./staticfiles/scss/form_education_at_profile/_m-form_education_at_profile.scss";
@import "./staticfiles/scss/gallery/_g-gallery.scss";
@import "./staticfiles/scss/gallery/_m-gallery.scss";
@import "./staticfiles/scss/gallery/_modal_window.scss";
@import "./staticfiles/scss/gotoup/_g-gotoup.scss";
@import "./staticfiles/scss/gotoup/_m-gotoup.scss";
@import "./staticfiles/scss/header-all-news/_g-header-all-news.scss";
@import "./staticfiles/scss/header-all-news/_m-header-all-news.scss";
@import "./staticfiles/scss/header-information/_g-header-information.scss";
@import "./staticfiles/scss/header-information/_m-header-information.scss";
@import "./staticfiles/scss/header-menu/_g-header-menu.scss";
@import "./staticfiles/scss/header-menu/_m-header-menu.scss";
@import "./staticfiles/scss/header-mobile/_g-header-mobile.scss";
@import "./staticfiles/scss/header-mobile/_m-header-mobile.scss";
@import "./staticfiles/scss/header-section/_g-header-section.scss";
@import "./staticfiles/scss/header-section/_m-header-section.scss";
@import "./staticfiles/scss/indicator/_g-indicator.scss";
@import "./staticfiles/scss/indicator/_m-indicator.scss";
@import "./staticfiles/scss/how-to-do/_g-how-to-do.scss";
@import "./staticfiles/scss/how-to-do/_m-how-to-do.scss";
@import "./staticfiles/scss/jstabs/_g-jstabs.scss";
@import "./staticfiles/scss/jstabs/_m-jstabs.scss";
@import "./staticfiles/scss/languages/_g-languages.scss";
@import "./staticfiles/scss/languages/_m-languages.scss";
@import "./staticfiles/scss/level-education/_g-level-education.scss";
@import "./staticfiles/scss/level-education/_m-level-education.scss";
@import "./staticfiles/scss/list-employe-search/_g-list-employe-search.scss";
@import "./staticfiles/scss/list-employe-search/_m-list-employe-search.scss";
@import "./staticfiles/scss/list-employees/_g-list-employees.scss";
@import "./staticfiles/scss/list-employees/_m-list-employees.scss";
@import "./staticfiles/scss/header-wrapper/_g-header-wrapper.scss";
@import "./staticfiles/scss/header-wrapper/_m-header-wrapper.scss";
@import "./staticfiles/scss/list-oop/_g-list-oop.scss";
@import "./staticfiles/scss/list-oop/_m-list-oop.scss";
@import "./staticfiles/scss/list-forms_education_at_profile/_g-list-forms_education_at_profile.scss";
@import "./staticfiles/scss/list-forms_education_at_profile/_m-list-forms_education_at_profile.scss";
@import "./staticfiles/scss/list-supporting-documentation/_g-list-supporting-documentation.scss";
@import "./staticfiles/scss/list-supporting-documentation/_m-list-supporting-documentation.scss";
@import "./staticfiles/scss/list-years-ec/_g-list-years-ec.scss";
@import "./staticfiles/scss/list-years-ec/_m-list-years-ec.scss";
@import "./staticfiles/scss/list-profiles/_g-list-profiles.scss";
@import "./staticfiles/scss/list-profiles/_m-list-profiles.scss";
@import "./staticfiles/scss/logo/_g-logo.scss";
@import "./staticfiles/scss/logo/_m-logo.scss";
@import "./staticfiles/scss/lk/_g-lk.scss";
@import "./staticfiles/scss/lk/_m-lk.scss";
@import "./staticfiles/scss/mCustomScrollbar/_mCustomScrollbar.scss";
@import "./staticfiles/scss/modal-window/_g-modal-window.scss";
@import "./staticfiles/scss/modal-window/_m-modal-window.scss";
@import "./staticfiles/scss/module-oop/_g-module-oop.scss";
@import "./staticfiles/scss/module-oop/_m-module-oop.scss";
@import "./staticfiles/scss/manager-module/_g-manager-module.scss";
@import "./staticfiles/scss/manager-module/_m-manager-module.scss";
@import "./staticfiles/scss/more-info/_g-more-info.scss";
@import "./staticfiles/scss/more-info/_m-more-info.scss";
@import "./staticfiles/scss/name-university/_g-name-university.scss";
@import "./staticfiles/scss/name-university/_m-name-university.scss";
@import "./staticfiles/scss/modules/gallery.scss";
@import "./staticfiles/scss/navigation/_g-navigation.scss";
@import "./staticfiles/scss/navigation/_m-navigation.scss";
@import "./staticfiles/scss/new-carousel/_g-new-carousel.scss";
@import "./staticfiles/scss/new-carousel/_m-new-carousel.scss";
@import "./staticfiles/scss/news-categories/_g-news-categories.scss";
@import "./staticfiles/scss/news-categories/_m-news-categories.scss";
@import "./staticfiles/scss/number_of_sets/_g-number_of_sets.scss";
@import "./staticfiles/scss/number_of_sets/_m-number_of_sets.scss";
@import "./staticfiles/scss/old_style/_edit_doc_oop.scss";
@import "./staticfiles/scss/old_style/_lk.scss";
@import "./staticfiles/scss/old_style/_menu_edit.scss";
@import "./staticfiles/scss/old_style/_modificator.scss";
@import "./staticfiles/scss/old_style/_news.scss";
@import "./staticfiles/scss/old_style/_search.scss";
@import "./staticfiles/scss/old_style/_tables.scss";
@import "./staticfiles/scss/old_style/_tabs.scss";
@import "./staticfiles/scss/oop/_g-oop.scss";
@import "./staticfiles/scss/oop/_m-oop.scss";
@import "./staticfiles/scss/owl-carousel/_animate.scss";
@import "./staticfiles/scss/owl-carousel/_autoheight.scss";
@import "./staticfiles/scss/owl-carousel/_core.scss";
@import "./staticfiles/scss/owl-carousel/_lazyload.scss";
@import "./staticfiles/scss/owl-carousel/_theme.default.scss";
@import "./staticfiles/scss/owl-carousel/_theme.scss";
@import "./staticfiles/scss/owl-carousel/_video.scss";
@import "./staticfiles/scss/page/_g-page.scss";
@import "./staticfiles/scss/page/_m-page.scss";
@import "./staticfiles/scss/navigation-window/_g-navigation-window.scss";
@import "./staticfiles/scss/navigation-window/_m-navigation-window.scss";
@import "./staticfiles/scss/passport-profile/_g-passport-profile.scss";
@import "./staticfiles/scss/passport-profile/_m-passport-profile.scss";
@import "./staticfiles/scss/person-auxiliary-info/_g-person-auxiliary-info.scss";
@import "./staticfiles/scss/person-auxiliary-info/_m-person-auxiliary-info.scss";
@import "./staticfiles/scss/person-main-info/_g-person-main-info.scss";
@import "./staticfiles/scss/person-main-info/_m-person-main-info.scss";
@import "./staticfiles/scss/person-name-post/_g-person-name-post.scss";
@import "./staticfiles/scss/person-name-post/_m-person-name-post.scss";
@import "./staticfiles/scss/publication-base/_g-publication-base.scss";
@import "./staticfiles/scss/publication-base/_m-publication-base.scss";
@import "./staticfiles/scss/publication-base-detail/_g-publication-base-detail.scss";
@import "./staticfiles/scss/publication-base-detail/_m-publication-base-detail.scss";
@import "./staticfiles/scss/publication-base-list/_g-publication-base-list.scss";
@import "./staticfiles/scss/publication-base-list/_m-publication-base-list.scss";
@import "./staticfiles/scss/redactor-navigation/_g-redactor-navigation.scss";
@import "./staticfiles/scss/redactor-navigation/_m-redactor-navigation.scss";
@import "./staticfiles/scss/registration/_g-registration.scss";
@import "./staticfiles/scss/registration/_m-registration.scss";
@import "./staticfiles/scss/schedule/_g-schedule.scss";
@import "./staticfiles/scss/schedule/_m-schedule.scss";
@import "./staticfiles/scss/score/_g-score.scss";
@import "./staticfiles/scss/score/_m-score.scss";
@import "./staticfiles/scss/search/_g-search.scss";
@import "./staticfiles/scss/search/_m-search.scss";
@import "./staticfiles/scss/search/_ya.scss";
@import "./staticfiles/scss/search-opp/_g-search-opp.scss";
@import "./staticfiles/scss/search-opp/_m-search-opp.scss";
@import "./staticfiles/scss/news/_g-news.scss";
@import "./staticfiles/scss/news/_m-news.scss";
@import "./staticfiles/scss/separator/_g-separator.scss";
@import "./staticfiles/scss/separator/_m-separator.scss";
@import "./staticfiles/scss/pagination/_g-pagination.scss";
@import "./staticfiles/scss/pagination/_m-pagination.scss";
@import "./staticfiles/scss/slider/_fotorama.scss";
@import "./staticfiles/scss/slider/_g-slider.scss";
@import "./staticfiles/scss/slider/_m-slider.scss";
@import "./staticfiles/scss/slogan/_g-slogan.scss";
@import "./staticfiles/scss/slogan/_m-slogan.scss";
@import "./staticfiles/scss/socials/_g-socials.scss";
@import "./staticfiles/scss/socials/_m-socials.scss";
@import "./staticfiles/scss/static-page/_g-static-page.scss";
@import "./staticfiles/scss/static-page/_m-static-page.scss";
@import "./staticfiles/scss/status-implementation/_g-status-implementation.scss";
@import "./staticfiles/scss/status-implementation/_m-status-implementation.scss";
@import "./staticfiles/scss/status-university/_g-status-university.scss";
@import "./staticfiles/scss/status-university/_m-status-university.scss";
@import "./staticfiles/scss/structura/_g-structura.scss";
@import "./staticfiles/scss/structura/_m-structura.scss";
@import "./staticfiles/scss/tabs/_g-tabs.scss";
@import "./staticfiles/scss/tabs/_m-tabs.scss";
@import "./staticfiles/scss/sv-control/_g-sv-control.scss";
@import "./staticfiles/scss/sv-control/_m-sv-control.scss";
@import "./staticfiles/scss/template_1/_g-template_1.scss";
@import "./staticfiles/scss/template_1/_m-template_1.scss";
@import "./staticfiles/scss/top-bar/_g-top-bar.scss";
@import "./staticfiles/scss/top-bar/_m-top-bar.scss";
@import "./staticfiles/scss/tags/_g-tags.scss";
@import "./staticfiles/scss/tags/_m-tags.scss";
@import "./staticfiles/scss/training-course/_g-training-course.scss";
@import "./staticfiles/scss/training-course/_m-training-course.scss";
@import "./staticfiles/scss/vacancies/_g-vacancies.scss";
@import "./staticfiles/scss/vacancies/_m-vacancies.scss";
@import "./staticfiles/scss/victories/_g-victories.scss";
@import "./staticfiles/scss/victories/_m-victories.scss";
@import "./staticfiles/scss/modules/float-tag-menu/_g-float-tag-menu.scss";
@import "./staticfiles/scss/modules/float-tag-menu/_m-float-tag-menu.scss";
/* endinject */
@import "animate";


