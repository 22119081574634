.m-passport-profile {
  &__content {
    @include breakpoint ($for-phone-only) {
      border-top: .1rem solid $color-border;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      border-top: none;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      border-top: none;
    }
    @include breakpoint ($for-desktop-up) {
      border-top: none;
    }
    @include breakpoint ($for-big-desktop-up) {
      border-top: none;
    }
  }
  input[id^='g-passport-profile-tab'] + label {
    font-size: 1em;
    font-weight: 600;
    color: $color-dark-blue;
    font-family: 'Century Gothic', sans-serif;
    cursor: pointer;
  }
  input[id^='g-passport-profile-tab']:checked + label {
    border-bottom: .01rem solid $color-border-pagination-hover;
  }

}