.m-alphabet-sort {
  @include breakpoint ($for-phone-only) {

  }
  @include breakpoint ($for-tablet-portrait-up) {

  }
  @include breakpoint ($for-tablet-landscape-up) {

  }
  @include breakpoint ($for-desktop-up) {

  }
  &__all-letter {
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {

    }
    @include breakpoint ($for-tablet-landscape-up) {
      font-size: 1.1rem;
      color: $color-text-blue;
      cursor: pointer;
      outline: none;
    }
    @include breakpoint ($for-desktop-up) {
      font-size: 1.1rem;
      color: $color-text-blue;
      cursor: pointer;
      outline: none;
    }
  }
  &__letter {
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {

    }
    @include breakpoint ($for-tablet-landscape-up) {
      font-size: 1.1em;
      color: $color-text-blue;
      cursor: pointer;
      outline: none;
    }
    @include breakpoint ($for-desktop-up) {
      font-size: 1.1em;
      color: $color-text-blue;
      cursor: pointer;
      outline: none;
    }
  }
  &__filter {
    &-active {
      @include breakpoint ($for-phone-only) {

      }
      @include breakpoint ($for-tablet-portrait-up) {

      }
      @include breakpoint ($for-tablet-landscape-up) {
        //border-bottom: .1rem solid $color-dark-blue;
        //font-size: 1.3rem;
      }
      @include breakpoint ($for-desktop-up) {
        //border-bottom: .1rem solid $color-dark-blue;
        //font-size: 1.3rem;
      }
    }
  }
}