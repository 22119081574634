.m-body {
  @include breakpoint($for-phone-only) {
    background-color: white;
  }
  @include breakpoint($for-tablet-portrait-up) {
    background-color: white;
  }
  @include breakpoint($for-tablet-landscape-up) {
    background-color: transparent;
    &__bg {
      background: url("/static/img/backgrount-left.svg") no-repeat;
      z-index: -100;
    }
  }


}

.m-header {
  &-bg {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
      background-size: contain;
    }
  }

  &-wrapper {
    //background-color: pink;
  }

  &-abs {
    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {

    }
    @include breakpoint($for-desktop-up) {

    }
    @include breakpoint($for-big-desktop-up) {
    }
  }

  &-section {

    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {

    }
    @include breakpoint($for-desktop-up) {

    }
    @include breakpoint($for-big-desktop-up) {
    }
  }
}

.m-main {
  //background: #dedede;
  //&__content-header{background: #c4c4f3}
  //&__content{background: green;}
  //&__modules{background: yellow;}
  //&__sidebar{background: red;}
}

.m-content__slider {
  background-color: #fcfcfc;
  @include breakpoint($for-phone-only) {
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: .75rem;
  }
  @include breakpoint($for-tablet-portrait-up) {
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: .75rem;
  }
  @include breakpoint($for-tablet-landscape-up) {
    border: 1px solid $color-border-slider-first;
    border-radius: 2rem;
    box-shadow: 0 32px 50px -20px $color-shadow-slider;
  }

  &:before {
    content: "";
    border-radius: 2.2rem;
    border: 4px solid $color-border-slider-two;
    z-index: -1;
  }

  &-content {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {

    }
    @include breakpoint($for-desktop-up) {

    }
    @include breakpoint($for-big-desktop-up) {
    }
  }

  &-banner {
    @include breakpoint($for-phone-only) {
      background-color: white;
      z-index: 100000;
      border-radius: .75rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      background-color: white;
      z-index: 100000;
      border-radius: .75rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      background: url("/static/img/bg_slider_banner.svg") no-repeat;
      background-size: contain;
      background-position-x: right;
      border-radius: 1.8rem;
    }
    @include breakpoint($for-desktop-up) {
      background: url("/static/img/bg_slider_banner.svg") no-repeat;
      background-size: contain;
      background-position-x: right;
      border-radius: 1.8rem;
    }
    @include breakpoint($for-big-desktop-up) {
      background: url("/static/img/bg_slider_banner.svg") no-repeat;
      background-size: contain;
      background-position-x: right;
      border-radius: 1.8rem;
    }

    &__wrapper {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {
      }
    }

    &__icon {
      @include breakpoint($for-phone-only) {
        border: 1px solid $color-border-slider-banner;
        border-radius: .75rem;
        box-shadow: 0 0 0 2px $color-shadow-slider-banner;
      }
      @include breakpoint($for-tablet-portrait-up) {
        border: 1px solid $color-border-slider-banner;
        border-radius: .75rem;
        box-shadow: 0 0 0 2px $color-shadow-slider-banner;
      }
      @include breakpoint($for-tablet-landscape-up) {
        border: 1px solid $color-border-slider-banner;
        border-radius: .75rem;
        box-shadow: 0 0 0 2px $color-shadow-slider-banner;
      }
      @include breakpoint($for-desktop-up) {
        border: 1px solid $color-border-slider-banner;
        border-radius: .75rem;
        box-shadow: 0 0 0 2px $color-shadow-slider-banner;

      }
      @include breakpoint($for-big-desktop-up) {
        border: 1px solid $color-border-slider-banner;
        border-radius: .75rem;
        box-shadow: 0 0 0 2px $color-shadow-slider-banner;
      }
    }

    &__title {
      &-link {
        font-family: 'Futura PT', sans-serif;
        color: $color-text-orange-light;
        border-color: $color-text-orange-underline-light;
        @include breakpoint($for-phone-only) {
          font-size: 1.1rem;
        }
        @include breakpoint($for-tablet-portrait-up) {
          font-size: 1.1rem;
        }
        @include breakpoint($for-tablet-landscape-up) {
          font-size: 1.375rem;
        }
        @include breakpoint($for-desktop-up) {
          font-size: 1.375rem;
        }
        @include breakpoint($for-big-desktop-up) {
          font-size: 1.375rem;
        }

        &:hover {
          color: $color-text-orange-light-hover;
        }
        &:visited,&:active, &:focus{
          color: $color-text-orange-light;
        }
      }
    }

    &__subtitle {
      @include breakpoint($for-phone-only) {
        font-size: .75rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        font-size: .75rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        font-size: .75rem;
      }
      @include breakpoint($for-desktop-up) {
        font-size: .75rem;
      }
      @include breakpoint($for-big-desktop-up) {
        font-size: .75rem;
      }
    }
  }
}

.m-footer {
  @include breakpoint($for-phone-only) {
    background-color: white;
  }
  @include breakpoint($for-tablet-portrait-up) {
    background-color: white;

  }
  @include breakpoint($for-tablet-landscape-up) {
    background-color: $color-bg-footer;

  }

}

