.m-lk {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {
  }
  @include breakpoint ($for-big-desktop-up) {
  }
  &__img {
    z-index: 2;
    overflow: hidden;
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
    img{
      width: 100%;
      object-fit: cover;
    }
  }
  &__name_post{
    color: $color-text;
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
  &__left-icon{
    @include breakpoint ($for-phone-only) {
      .m-icon_more_menu{
        fill: $color-icon;
      }
    }
    @include breakpoint ($for-tablet-portrait-up) {
      .m-icon_more_menu{
        fill: $color-icon;
      }
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
}