.m-gotoup {
  z-index: 10000;
  opacity: 0.75;
  &:hover{
    opacity: 1;
  }
  @include breakpoint($for-phone-only) {

  }
  @include breakpoint($for-tablet-portrait-up) {

  }
  @include breakpoint($for-tablet-landscape-up) {

  }
  @include breakpoint($for-desktop-up) {

  }
  @include breakpoint($for-big-desktop-up) {

  }
}