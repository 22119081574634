.g-body {
  margin: 0 auto;
  position: relative;

  &__bg {
    @include breakpoint($for-phone-only) {
      display: none;
    }
    @include breakpoint($for-tablet-portrait-up) {
      display: none;
    }
    @include breakpoint($for-tablet-landscape-up) {
      display: none;

    }
    @include breakpoint($for-desktop-up) {
      position: absolute;
      top: 0;
      left: -212px;
      width: 735px;
      height: 575px;
      display: block;

    }
    @include breakpoint($for-big-desktop-up) {
      position: absolute;
      top: 0;
      left: -212px;
      width: 735px;
      height: 575px;
      display: block;
    }
  }
}

.g-header {
  position: relative;
  margin: 0 auto;
  @include breakpoint($for-phone-only) {
    padding: 0;
    width: $small;
  }
  @include breakpoint($for-tablet-portrait-up) {
    padding: 0;
    width: $small;
  }
  @include breakpoint($for-tablet-landscape-up) {
    width: $small;
  }
  @include breakpoint($for-desktop-up) {
    width: $middle;
  }
  @include breakpoint($for-big-desktop-up) {
    width: $big;
  }

  &-abs {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
      position: absolute;
    }
    @include breakpoint($for-desktop-up) {
      position: absolute;
    }
    @include breakpoint($for-big-desktop-up) {
      position: absolute;
    }
  }

}

.g-main {
  position: relative;
  @include breakpoint($for-phone-only) {
    width: $small;
  }
  @include breakpoint($for-tablet-portrait-up) {
    width: $small;
  }
  @include breakpoint($for-tablet-landscape-up) {
    width: $small;

  }
  @include breakpoint($for-desktop-up) {
    width: $middle;

  }
  @include breakpoint($for-big-desktop-up) {
    width: $big;
  }

  margin: 0 auto;

  &__content-header {
    @include breakpoint($for-phone-only) {
      order: 1;
    }
    @include breakpoint($for-tablet-portrait-up) {
      order: 1;
      width: 100%;
    }
    @include breakpoint($for-tablet-landscape-up) {
      order: 1;
      width: 100%;
    }
    @include breakpoint($for-desktop-up) {
      order: 1;
      width: 100%;
    }
  }

  &__wrapper {
    display: grid;
    box-sizing: content-box;
    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {
      margin-top: 4rem;
    }
    @include breakpoint($for-desktop-up) {
      margin-top: 4rem;
    }

    &-content__home {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {
        padding: 0 1rem;
      }
    }

    &-content__1 {
      grid-template-columns: 100%;
      grid-template-areas:
        "grid__0"
        "grid__1"
        "grid__3"
        "grid__4"
        "grid__5";
    }

    &-content__2 {
      grid-template-columns: 100%;
      grid-template-areas:
        "grid__0"
        "modules"
        "grid__1"
        "grid__3"
        "grid__4"
        "grid__5";
    }

    &-content__3 {
      @include breakpoint($for-phone-only) {
        grid-template-columns: 100%;
        grid-template-areas:
        "sidebar"
        "grid__0"
        "grid__1"
        "grid__3"
        "grid__4"
        "grid__5";
      }
      @include breakpoint($for-tablet-portrait-up) {
        grid-template-columns: 100%;
        grid-template-areas:
        "sidebar"
        "grid__0"
        "grid__1"
        "grid__3"
        "grid__4"
        "grid__5";
      }
      @include breakpoint($for-tablet-landscape-up) {
        grid-template-columns: minmax(0,10fr) 2fr;
        grid-template-areas:
        "grid__0 sidebar"
        "grid__1 sidebar"
        "grid__3 sidebar"
        "grid__4 sidebar"
        "grid__5 sidebar";
        grid-column-gap: 1rem;
      }
      @include breakpoint($for-desktop-up) {
        grid-template-columns: minmax(0,10fr) 2fr;
        grid-template-areas:
        "grid__0 sidebar"
        "grid__1 sidebar"
        "grid__3 sidebar"
        "grid__4 sidebar"
        "grid__5 sidebar";
        grid-column-gap: 1.5rem;
      }
      @include breakpoint($for-big-desktop-up) {
        grid-template-columns:minmax(0,10fr) 2fr;
        grid-template-areas:
        "grid__0 sidebar"
        "grid__1 sidebar"
        "grid__3 sidebar"
        "grid__4 sidebar"
        "grid__5 sidebar";
        grid-column-gap: 2rem;
      }
    }

    &-content__4 {
      @include breakpoint($for-phone-only) {
        grid-template-columns: 100%;
        grid-template-areas:
        "grid__0"
        "modules"
        "grid__1"
        "grid__3"
        "grid__4"
        "grid__5"
        "sidebar";
      }
      @include breakpoint($for-tablet-portrait-up) {
        grid-template-columns: 100%;
        grid-template-areas:
        "grid__0"
        "sidebar"
        "modules"
        "grid__1"
        "grid__3"
        "grid__4"
        "grid__5";
      }
      @include breakpoint($for-tablet-landscape-up) {
        grid-template-columns: 100%;
        grid-template-areas:
        "grid__0"
        "sidebar"
        "modules"
        "grid__1"
        "grid__3"
        "grid__4"
        "grid__5";
      }
      @include breakpoint($for-desktop-up) {
        grid-template-columns: minmax(0,10fr) 2fr;
        grid-template-areas:
        "grid__0 sidebar"
        "modules sidebar"
        "grid__1 sidebar"
        "grid__3 sidebar"
        "grid__4 sidebar"
        "grid__5 sidebar";
        grid-column-gap: 1.5rem;
      }
      @include breakpoint($for-big-desktop-up) {
        grid-template-columns: minmax(0,10fr) 2fr;
        grid-template-areas:
        "grid__0 sidebar"
        "modules sidebar"
        "grid__1 sidebar"
        "grid__3 sidebar"
        "grid__4 sidebar"
        "grid__5 sidebar";
        grid-column-gap: 2rem;
      }
    }

    &-modules {
      z-index: 49;
      grid-area: modules;
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {

      }

    }

    &-sidebar {
      z-index: 49;
      grid-area: sidebar;
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
    }

  }
}

//---------------

.g-main__full {
  position: relative;
  width: 100%;


  &__content-header {
    margin: 0 auto;
    @include breakpoint($for-phone-only) {
      width: $small;
      order: 1;
    }
    @include breakpoint($for-tablet-portrait-up) {
      width: $small;
      order: 1;
    }
    @include breakpoint($for-tablet-landscape-up) {
      width: $small;
      order: 1;
    }
    @include breakpoint($for-desktop-up) {
      width: $middle;
      order: 1;
    }
    @include breakpoint($for-big-desktop-up) {
      width: $big;
      order: 1;
    }
  }

  &__wrapper {
    display: grid;
    box-sizing: content-box;
    margin: 0 auto;
    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {
      margin-top: 4rem;
    }
    @include breakpoint($for-desktop-up) {
      margin-top: 4rem;
    }

    &-content__home {
      @include breakpoint($for-phone-only) {
        width: $small;
      }
      @include breakpoint($for-tablet-portrait-up) {
        width: $small;
      }
      @include breakpoint($for-tablet-landscape-up) {
        width: $small;

      }
      @include breakpoint($for-desktop-up) {
        width: $middle;

      }
      @include breakpoint($for-big-desktop-up) {
        width: $big;
        padding: 0 1rem;
      }
    }

    &-content__5 {
      @include breakpoint($for-phone-only) {
        grid-template-columns: 100%;
        grid-template-areas:
        "grid__0"
        "modules"
        "grid__1"
        "grid__3"
        "grid__4"
        "grid__5"
        "sidebar";
      }
      @include breakpoint($for-tablet-portrait-up) {
        grid-template-columns: 100%;
        grid-template-areas:
        "grid__0"
        "sidebar"
        "modules"
        "grid__1"
        "grid__3"
        "grid__4"
        "grid__5";
      }
      @include breakpoint($for-tablet-landscape-up) {
        grid-template-columns: 100%;
        grid-template-areas:
        "grid__0"
        "sidebar"
        "modules"
        "grid__1"
        "grid__3"
        "grid__4"
        "grid__5";
      }
      @include breakpoint($for-desktop-up) {
        grid-template-columns: .25fr minmax(0, 10fr) 2fr .25fr;
        grid-template-areas:
        "grid__pad_1 grid__0 sidebar grid__pad_2"
        "grid__pad_1 modules sidebar grid__pad_2"
        "grid__pad_1 grid__1 sidebar grid__pad_2"
        "grid__pad_1 grid__3 sidebar grid__pad_2"
        "grid__pad_1 grid__4 sidebar grid__pad_2"
        "grid__pad_1 grid__5 sidebar grid__pad_2";
        grid-column-gap: 1.5rem;
      }
      @include breakpoint($for-big-desktop-up) {
        grid-template-columns: .25fr minmax(0, 10fr) 2fr .25fr;
        grid-template-areas:
        "grid__pad_1 grid__0 sidebar grid__pad_2"
        "grid__pad_1 modules sidebar grid__pad_2"
        "grid__pad_1 grid__1 sidebar grid__pad_2"
        "grid__pad_1 grid__3 sidebar grid__pad_2"
        "grid__pad_1 grid__4 sidebar grid__pad_2"
        "grid__pad_1 grid__5 sidebar grid__pad_2";
        grid-column-gap: 2rem;
      }
    }

    &-modules {
      z-index: 49;
      grid-area: modules;
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {

      }

    }

    &-sidebar {
      z-index: 49;
      grid-area: sidebar;
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
    }

  }
}


.g-content__slider {
  position: relative;
  @include breakpoint($for-phone-only) {
    order: 2;
    margin: 0 .25rem;
  }
  @include breakpoint($for-tablet-portrait-up) {
    order: 2;
    margin: 0 .25rem;
  }
  @include breakpoint($for-tablet-landscape-up) {
    margin: 0 1rem;
    display: flex;
    flex-flow: row nowrap;
  }
  @include breakpoint($for-desktop-up) {
    margin: 0;
    display: flex;
    flex-flow: row nowrap;
    padding: .25rem;
  }

  &:before {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: -4px;
    left: -4px;
  }

  &-content {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
      width: 46rem;
    }
    @include breakpoint($for-desktop-up) {
      width: 66rem;
    }
    @include breakpoint($for-big-desktop-up) {
      width: 66rem;
    }
  }

  &-banner {
    @include breakpoint($for-phone-only) {
      width: 100%;
      padding: 1rem;
      box-sizing: border-box;
      margin-top: -1.5rem;
      position: relative;

    }
    @include breakpoint($for-tablet-portrait-up) {
      width: 100%;
      padding: 1rem;
      box-sizing: border-box;
      margin-top: -2rem;
      position: relative;

    }
    @include breakpoint($for-tablet-landscape-up) {
      margin-top: 0;
      width: 23.375rem;
      padding: 3.25rem 2rem 2rem 2rem;
      box-sizing: border-box;
    }
    @include breakpoint($for-desktop-up) {
      width: 23.375rem;
      padding: 3.25rem 2rem 2rem 2rem;
      box-sizing: border-box;
      margin-top: 0;
    }
    @include breakpoint($for-big-desktop-up) {
      width: 23.375rem;
      padding: 3.25rem 2rem 2rem 2rem;
      box-sizing: border-box;
      margin-top: 0;
    }

    &__wrapper {
      display: flex;
      @include breakpoint($for-phone-only) {
        flex-flow: column;
      }
      @include breakpoint($for-tablet-portrait-up) {
        flex-flow: column;
      }
      @include breakpoint($for-tablet-landscape-up) {
        flex-flow: column;
      }
      @include breakpoint($for-desktop-up) {
        flex-flow: column;
      }
      @include breakpoint($for-big-desktop-up) {
        flex-flow: column;
      }
    }

    &__icon {
      box-sizing: border-box;

      img {
        width: 100%
      }

      @include breakpoint($for-phone-only) {
        display: none;
      }
      @include breakpoint($for-tablet-portrait-up) {
        display: none;
      }
      @include breakpoint($for-tablet-landscape-up) {
        width: 3.5rem;
        height: 3.5rem;
        padding: .75rem;
        margin-bottom: 1.5rem;
        display: block;
      }
      @include breakpoint($for-desktop-up) {
        width: 3.5rem;
        height: 3.5rem;
        padding: .75rem;
        margin-bottom: 1.5rem;
        display: block;
      }
      @include breakpoint($for-big-desktop-up) {
        width: 3.5rem;
        height: 3.5rem;
        padding: .75rem;
        margin-bottom: 1.5rem;
        display: block;

      }
    }

    &__title {
      @include breakpoint($for-phone-only) {
        margin-bottom: .75rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        margin-bottom: .75rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        margin-bottom: .75rem;
      }
      @include breakpoint($for-desktop-up) {
        margin-bottom: .75rem;
      }
      @include breakpoint($for-big-desktop-up) {
        margin-bottom: .75rem;
      }
    }

    &__subtitle {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {
      }
    }
  }
}

.g-footer {
  @include breakpoint($for-phone-only) {
    margin-top: 1rem;
  }
  @include breakpoint($for-tablet-portrait-up) {
    margin-top: 1rem;
  }
  @include breakpoint($for-tablet-landscape-up) {
    margin-top: 3rem;
  }
  @include breakpoint($for-desktop-up) {
    margin-top: 3rem;
  }
  @include breakpoint($for-big-desktop-up) {
    margin-top: 3rem;
  }

  &__wrapper {
    flex: 0 0 auto;
    margin: 0 auto;
    min-height: 100px;
    display: flex;
    @include breakpoint($for-phone-only) {
      width: $small;
      flex-flow: column;
    }
    @include breakpoint($for-tablet-portrait-up) {
      width: $small;
      flex-flow: column;
    }
    @include breakpoint($for-tablet-landscape-up) {
      width: $small;
      flex-flow: row wrap;
      padding: 1rem 0;
    }
    @include breakpoint($for-desktop-up) {
      width: $middle;
      flex-flow: row wrap;
      padding: 1rem 0;
    }
    @include breakpoint($for-big-desktop-up) {
      width: $big;
      flex-flow: row wrap;
      padding: 1rem 0;
    }
  }

}

//@include breakpoint ($for-phone-only){}
//@include breakpoint ($for-tablet-portrait-up){}
//@include breakpoint ($for-tablet-landscape-up){}
//@include breakpoint ($for-desktop-up){}
//@include breakpoint ($for-big-desktop-up){}
