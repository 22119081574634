
.g-tabs {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {
  }
  @include breakpoint ($for-big-desktop-up) {
  }
  section {
    display: none;
  }
  input[id^="tab"] {
    display: none;
    &:not(:checked) + label {
      &:hover, &:focus {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
        }
        @include breakpoint ($for-big-desktop-up) {
        }
      }
    }
  }
  label[for^="tab"] {
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {

    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up) {

    }
    @include breakpoint ($for-big-desktop-up) {

    }
  }

  input:checked + label[for^="tab"] {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
  $list: 1 2 3 4 5 6 7;
  @each $i in $list {
    #tab#{$i}:checked ~ #g-content__#{$i} {
      display: block;
    }
  }
}

.g-tabs__main {
  @include breakpoint ($for-phone-only) {
    margin-top: -1rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    margin-top: -1rem;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    margin-top: 0;
  }
  @include breakpoint ($for-desktop-up) {

  }
  @include breakpoint ($for-big-desktop-up) {

  }

  input[id^="tab"] {
    &:not(:checked) + label {
      &:hover, &:focus {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
        }
        @include breakpoint ($for-big-desktop-up) {
        }
      }
    }
  }
  label[for^="tab"] {
    @include breakpoint ($for-phone-only) {
      margin: 0 -1rem;
      padding: .5rem 1rem;
      display: block;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin: 0 -1rem;
      padding: .5rem 1rem;
      display: block;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      padding: .2rem 1rem;
      display: inline-block;
      margin: .5rem 0 0 0;
    }
    @include breakpoint ($for-desktop-up) {
      padding: .2rem 1rem;
      display: inline-block;
      margin: .5rem 0 0 0;
    }
    @include breakpoint ($for-big-desktop-up) {
      padding: .2rem 1rem;
      display: inline-block;
      margin: .5rem 0 0 0;
    }
  }

  input:checked + label[for^="tab"] {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }

}
