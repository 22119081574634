.g-list-profiles {
  @include breakpoint ($for-phone-only) {
    width: 100%;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    width: 100%;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    width: 12.5rem;
  }
  @include breakpoint ($for-desktop-up) {
    width: 12.5rem;
  }
  @include breakpoint ($for-big-desktop-up) {
    width: 12.5rem;
  }
  &__item {
    @include breakpoint ($for-phone-only) {
      padding: 0  .3rem .3rem .3rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      padding: 0 .3rem .3rem .3rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      padding: .1rem .4rem .1rem 0;
      margin-right: 1.25rem;
    }
    @include breakpoint ($for-desktop-up) {
      padding: .1rem .4rem .1rem 0;
      margin-right: 1.25rem;
    }
    @include breakpoint ($for-big-desktop-up) {
      padding: .1rem .4rem .1rem 0;
      margin-right: 1.25rem;
    }
  }
}