.hidden{
  display: none;
}
.delete_box_file{
  position: absolute;
  top: 0;
  right: 0;
}
.save_btn{
  position: fixed;
  right: 0;
  z-index: 1000;
  top: 48%;
  text-align: left;
  img{
    width: 16px;
    float: left;
    margin-right: .5rem;
  }
}
.right-btn-save{
  position: absolute;
  right: -83px;
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  transition: all 0.2s ease;
  &:hover, &:focus{
    right: 0;
    transition: all 0.2s ease;
  }
  span{margin-left: .8rem;}
}
.right-btn-save-continue{
  @extend .right-btn-save;
  top: 2.2rem;
  right: -266px;
  white-space: nowrap;
}