.g-list-employees {
  @include breakpoint($for-phone-only) {
  }
  @include breakpoint($for-tablet-portrait-up) {

  }
  @include breakpoint($for-tablet-landscape-up) {

  }
  @include breakpoint($for-desktop-up) {

  }

  &__header {
    @include breakpoint($for-phone-only) {
      display: none;
    }
    @include breakpoint($for-tablet-portrait-up) {
      display: none;
    }
    @include breakpoint($for-tablet-landscape-up) {
      display: flex;
      justify-content: space-between;
      padding: .4rem 1rem;
    }
    @include breakpoint($for-desktop-up) {
      display: flex;
      justify-content: space-between;
      padding: .4rem 0;
    }

    &-fio {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {

      }
    }

    &-contact {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {

      }
    }
  }

  &__load {
    @include breakpoint($for-phone-only) {
      padding: 1rem;
      margin: 1rem .25rem 1rem .25rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      padding: 1rem;
      margin: 1rem .25rem 1rem .25rem;

    }
    @include breakpoint($for-tablet-landscape-up) {
      padding: 0;
      margin: 0;

    }
    @include breakpoint($for-desktop-up) {
      padding: 0;
      margin: 0;
    }
  }

  &__content {
    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {

    }
    @include breakpoint($for-desktop-up) {

    }
  }

  &__item {

    @include breakpoint($for-phone-only) {
      padding: 1rem;
      margin: 1rem .25rem 1rem .25rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      padding: 1rem;
      margin: 1rem .25rem 1rem .25rem;
      display: flex;
    }
    @include breakpoint($for-tablet-landscape-up) {
      padding: 1rem;
      display: flex;
      margin: 4rem 0;
    }
    @include breakpoint($for-desktop-up) {
      display: flex;
      margin: 4rem 0;
      padding: 0;
    }

    &-template {
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
      @include breakpoint($for-desktop-up) {

      }
    }

    &-img {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @include breakpoint($for-phone-only) {
        width: 5rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.5rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        width: 5rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        margin-right: 1rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        width: 5rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        margin-right: 2rem;
      }
      @include breakpoint($for-desktop-up) {
        width: 5rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        margin-right: 2rem;
      }
    }

    &-content {
      justify-content: center;
      display: flex;
      flex-flow: column;
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {
        flex: 1 0;

      }
      @include breakpoint($for-tablet-landscape-up) {
        flex: 1 0;
      }
      @include breakpoint($for-desktop-up) {
        flex: 1 0;
      }

      &__name {
        @include breakpoint($for-phone-only) {
          margin-bottom: .25rem;
        }
        @include breakpoint($for-tablet-portrait-up) {
          margin-bottom: .25rem;
        }
        @include breakpoint($for-tablet-landscape-up) {
          margin-bottom: .25rem;
        }
        @include breakpoint($for-desktop-up) {
          margin-bottom: .25rem;
        }
      }

      &__post {
        @include breakpoint($for-phone-only) {
          margin-bottom: .5rem;
        }
        @include breakpoint($for-tablet-portrait-up) {
          margin-bottom: .5rem;
        }
        @include breakpoint($for-tablet-landscape-up) {
          margin-bottom: .5rem;
        }
        @include breakpoint($for-desktop-up) {
          margin-bottom: .5rem;
        }
      }

      &__department {
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
        @include breakpoint($for-desktop-up) {

        }
      }
    }

    &-contact {
      @include breakpoint($for-phone-only) {
        margin-top: 1.5rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        margin: .5rem 0 0 .5rem;
        text-align: right;
      }
      @include breakpoint($for-tablet-landscape-up) {
        margin: 0;
        text-align: right;
      }
      @include breakpoint($for-desktop-up) {
        margin: 0;
        text-align: right;
      }

      &-phone {
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
        @include breakpoint($for-desktop-up) {

        }
      }

      &-email {
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
        @include breakpoint($for-desktop-up) {

        }
      }

      &-email-3 {
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
        @include breakpoint($for-desktop-up) {

        }
      }
    }
  }
}