.m-auth__link {
  border-radius: .625rem;
  border: 1px solid $color-border-auth;
  box-shadow: 0 0 0 1px $color-shadow-auth;

  a {
    font-size: .75rem;
    border: 0;
    line-height: 1rem;
  }

  &:hover {
    border: 1px solid $color-text-orange-light;
    box-shadow: 0 0 0 1px $color-text-orange-underline-light;
  }
}


.m-form-auth {
  &__fields {
    @include breakpoint($for-phone-only) {
      font-size: 1rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      font-size: 1rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      font-size: .95rem;
    }
  }

  &__links {
    @include breakpoint($for-phone-only) {
      font-size: 8rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      font-size: 8rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      font-size: .75rem;
    }
  }
}
