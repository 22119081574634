.g-level-education {
  @include breakpoint ($for-phone-only) {
    padding: 1rem;
    margin: .5rem .25rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    padding: 1rem;
    margin: .5rem .25rem;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    padding: 1rem;
    margin: 0;
  }
  @include breakpoint ($for-desktop-up) {
    padding: 1rem 0;
    margin: 0;
  }
  @include breakpoint ($for-big-desktop-up) {
    margin: 0;
    padding: 1rem 0;
  }
  &__header {
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up) {
      margin: .7rem 0;
    }
    @include breakpoint ($for-big-desktop-up) {
      margin: .7rem 0;
    }
  }
  &__list {
    @include breakpoint ($for-phone-only) {
      display: flex;
      flex-flow: column;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      display: flex;
      flex-flow: column;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      display: flex;
      flex-flow: row nowrap;
    }
    @include breakpoint ($for-desktop-up) {
      display: flex;
      flex-flow: row nowrap;
    }
    @include breakpoint ($for-big-desktop-up) {
      display: flex;
      flex-flow: row nowrap;
    }
    &-item {
      @include breakpoint ($for-phone-only) {
        padding: .7rem 0;
        &:not(:last-child) {
          margin: 0;
        }
      }
      @include breakpoint ($for-tablet-portrait-up) {
        padding: .7rem 0;
        &:not(:last-child) {
          margin: 0;
        }
      }
      @include breakpoint ($for-tablet-landscape-up) {
        padding: 0;
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
      @include breakpoint ($for-desktop-up) {
        padding: 0;
        &:not(:last-child) {
          margin-right: 1.5rem;
        }
      }
      @include breakpoint ($for-big-desktop-up) {
        padding: 0;
        &:not(:last-child) {
          margin-right: 1.5rem;
        }
      }
      &-name {
        @include breakpoint ($for-phone-only) {
          margin-bottom: .1rem;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          margin-bottom: .1rem;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          margin-bottom: .1rem;
        }
        @include breakpoint ($for-desktop-up) {
          margin-bottom: .1rem;
        }
        @include breakpoint ($for-big-desktop-up) {
          margin-bottom: .1rem;
        }
      }
      &-count {
        @include breakpoint ($for-phone-only) {

        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {

        }
        @include breakpoint ($for-desktop-up) {

        }
        @include breakpoint ($for-big-desktop-up) {

        }
      }
    }
  }
}