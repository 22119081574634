.g-pagination {
  @include breakpoint ($for-phone-only) {
    margin: 0 .25rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {
    margin: 3rem 0 1rem 0;
  }
  &__pages {
    @include breakpoint ($for-phone-only) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;

    }
    &__item {
      @include breakpoint ($for-phone-only) {
        width: 3rem;
        height: 3rem;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        align-items: center;
        width: 3rem;
        height: 3rem;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin: 0 .5rem 0 0;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        display: inline-block;
        padding:  .25rem 1rem;
        text-align: center;
        min-width: 1rem;
        width: auto;
        height: inherit;
        margin: 0 .5rem 0 0;
        &-next, &-prev {
          &:after {
            display: inline-block;
          }
        }
      }
      &-active {
      }
    }
    &__separator {
      @include breakpoint ($for-phone-only) {
        width: .3rem;
        height: .3rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        width: .3rem;
        height: .3rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        display: none;
      }
    }
  }
}