.g-contacts-module {
  @include breakpoint ($for-phone-only) {
    padding: 1rem;
    margin: 1rem .25rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    padding: 1rem;
    margin: 1rem 0;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    padding: 0;
    margin: 1rem 0;
  }
  @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
    padding: 0;
    margin: 1rem 0;
  }
  &__title {
    @include breakpoint ($for-phone-only) {
      margin-bottom: 1rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin-bottom: 1em;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin-bottom: 1rem;
    }
    @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
      margin-bottom: 1rem;
    }
  }
  &__address {
    @include breakpoint ($for-phone-only) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
      margin-bottom: .5rem;
    }
  }
  &__phone {
    @include breakpoint ($for-phone-only) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
      margin-bottom: .5rem;
    }
  }
  &__email {
    @include breakpoint ($for-phone-only) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
      margin-bottom: .5rem;
    }
  }
  &__hour {
    @include breakpoint ($for-phone-only) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin-bottom: .5rem;
    }
    @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
      margin-bottom: .5rem;
    }
  }
}