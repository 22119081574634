.g-education-list-fac {
  @include breakpoint($for-phone-only) {
  }
  @include breakpoint($for-tablet-portrait-up) {
  }
  @include breakpoint($for-tablet-landscape-up) {
  }
  @include breakpoint($for-desktop-up) {
  }

  &__title {
    @include breakpoint($for-phone-only) {
      padding: 1rem;
      margin-top: 2rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      padding: 1rem;
      display: inline-block;
    }
    @include breakpoint($for-tablet-landscape-up) {
      margin: 3rem 0 2.5rem 0;
      display: inline-block;
      padding: 0;
    }
    @include breakpoint($for-desktop-up, $for-big-desktop-up) {
      margin: 3rem 0 2.5rem 0;
      padding: 0;
      display: inline-block;
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: .325rem;
        margin-top: .25rem;
      }
    }
  }

  &__oop-list {
    display: flex;
    @include breakpoint($for-phone-only) {
      flex-flow: column;
    }
    @include breakpoint($for-tablet-portrait-up) {
      flex-flow: column;
    }
    @include breakpoint($for-tablet-landscape-up) {
      flex-flow: row wrap;
    }
    @include breakpoint($for-desktop-up) {
      flex-flow: row wrap;
    }

    &__item {
      display: inline-flex;
      @include breakpoint($for-phone-only) {
        align-items: center;
        width: 100%;
        margin: 0 0 .5rem 0;
        padding: .75rem 1rem;
        box-sizing: border-box;
      }
      @include breakpoint($for-tablet-portrait-up) {
        align-items: center;
        width: 100%;
        margin: 0 0 .5rem 0;
        padding: .75rem 1rem;
        box-sizing: border-box;
      }
      @include breakpoint($for-tablet-landscape-up) {
        align-items: center;
        min-width: 3rem;
        max-width: 19rem;
        margin: 0 .5rem .5rem 0;
        padding: .75rem 1rem;
      }
      @include breakpoint($for-desktop-up) {
        align-items: center;
        min-width: 3rem;
        max-width: 19rem;
        margin: 0 .5rem .5rem 0;
        padding: .75rem 1rem;
      }

    }
  }
}