.m-breadcrumb {
  &__item {
    font-size: .675rem;
    list-style-type: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:not(:first-child):before {
      content: "/";
      color: $color-text-grey;
    }
    &-link {
      font-size: .675rem;
      text-decoration: none;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
    &-active {
      color: $color-text-grey;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
