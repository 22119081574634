.g-document-group {
  //@include breakpoint ($for-phone-only) {
  //  padding: 1rem;
  //}
  //@include breakpoint ($for-tablet-portrait-up) {
  //  padding: 1rem;
  //}
  //@include breakpoint ($for-tablet-landscape-up) {
  //  padding: 1rem;
  //}
  //@include breakpoint ($for-desktop-up) {
  //  padding: 0;
  //}
  //@include breakpoint ($for-big-desktop-up) {
  //  padding: 0;
  //}
  &__item {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {
    }

    &-table {
      overflow: scroll;
      max-height: 100rem;
      @include breakpoint($for-phone-only) {
        //max-width: 840px;
      }
      @include breakpoint($for-tablet-portrait-up) {
        //max-width: 840px;
      }
      @include breakpoint($for-tablet-landscape-up) {
        //max-width: 840px;
        margin-bottom: 5rem;
        margin-top: 1rem;
      }
      @include breakpoint($for-desktop-up) {
        margin-bottom: 5rem;
        margin-top: 1rem;
        //max-width: 100%;
        width: 100%;

      }
      @include breakpoint($for-big-desktop-up) {
        //max-width: 100%;
        width: 100%;
        margin-bottom: 5rem;
        margin-top: 1rem;

      }
    }
  }

  &__fast {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include breakpoint($for-phone-only) {
        padding: 1rem;
        margin-top: 2rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        padding: .5rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        margin: 2rem 0 .5rem 0;
        padding: .5rem .5rem .5rem .75rem;
      }
      @include breakpoint($for-desktop-up, $for-big-desktop-up) {
        margin: 2rem 0 .5rem 0;
        padding: .5rem .5rem .5rem .75rem;
      }

      &-text {

      }

      &-icon {
        padding: 0.25rem 0.5rem;
      }
    }

    &__list {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
        padding: .25rem .75rem;

      }
      @include breakpoint($for-desktop-up) {
        padding: .25rem .75rem;
      }
      @include breakpoint($for-big-desktop-up) {
        padding: .25rem .75rem;
      }

      &-item {
        @include breakpoint($for-phone-only) {
        }
        @include breakpoint($for-tablet-portrait-up) {
        }
        @include breakpoint($for-tablet-landscape-up) {
          margin: .75rem 0;
        }
        @include breakpoint($for-desktop-up) {
          margin: .75rem 0;
        }
        @include breakpoint($for-big-desktop-up) {
          margin: .75rem 0;
        }
      }
    }
  }
}
