.g-authorized-user {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  @include breakpoint($for-phone-only) {
    margin-bottom: .5rem;
    display: none;
  }
  @include breakpoint($for-tablet-portrait-up) {
    margin-bottom: .5rem;
    display: none;
  }
  @include breakpoint($for-tablet-landscape-up) {
    margin-bottom: 0;
    display: block;
  }
  @include breakpoint($for-desktop-up) {
    display: block;

  }
  @include breakpoint($for-big-desktop-up) {
    display: block;
  }

  &__name {
    position: relative;
    min-width: 8.5rem;
    &-button {
      display: flex;
      flex-flow: row nowrap;
      position: relative;
      &-icon {
        margin: .25rem 0 .25rem .25rem;
        width: 1.625rem;
        height: 1.625rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;

      }

      a {
        padding: .5rem .75rem;
        display: inline-block;
      }
    }

    &:hover {
      .g-authorized-user__name-menu {
        display: block;
      }
    }

    &-menu {
      display: none;
      position: absolute;
      padding: 2rem 1rem .5rem 1rem;
      top: 1rem;
      width:calc(100% - 2.15rem);
      &__item{
        margin-bottom: .25rem;
      }
    }
  }

  &__list-user {

  }

  &__logout {
  }

  &__manual {
  }

  &__left {
    @include breakpoint($for-phone-only) {
      margin-bottom: .5rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      margin-bottom: .5rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      margin-bottom: 0;
      position: absolute;
      bottom: -1rem;
      right: -8.3rem;
      width: 16rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
    }
    @include breakpoint($for-desktop-up) {
      position: absolute;
      bottom: -1rem;
      right: -8.3rem;
      width: 16rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
    }
    @include breakpoint($for-big-desktop-up) {
      position: absolute;
      bottom: -1rem;
      right: -8.3rem;
      width: 16rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
    }
  }
}