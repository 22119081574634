.g-accounts {
  @include breakpoint($for-phone-only) {
    //padding: 0 1rem;
  }
  @include breakpoint($for-tablet-portrait-up) {
    //padding: 0 1rem;
  }
  @include breakpoint($for-tablet-landscape-up) {
    padding: 0 0 8rem 0;
  }
  @include breakpoint($for-desktop-up) {
    padding: 0 0 10rem 0;
  }
  @include breakpoint($for-big-desktop-up) {
    padding: 0 0 12rem 0;
  }

  &__bg-img {

    position: absolute;
    top: 0;
    height: 33rem;
    @include breakpoint($for-phone-only) {
      display: none;
    }
    @include breakpoint($for-tablet-portrait-up) {
      display: none;
    }
    @include breakpoint($for-tablet-landscape-up) {
      display: none;
    }
    @include breakpoint($for-desktop-up) {
      display: block;
      right: -5%;
    }
    @include breakpoint($for-big-desktop-up) {
      display: block;
      right: -10%;
    }
  }

  &__form {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
      width: 45%;
    }
    @include breakpoint($for-desktop-up) {
      width: 35%;
    }
    @include breakpoint($for-big-desktop-up) {
      width: 35%;
    }
  }
}