.m-level-education {
  @include breakpoint ($for-phone-only) {
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: .75rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: .75rem;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: 0;
  }
  @include breakpoint ($for-desktop-up) {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
  }
  @include breakpoint ($for-big-desktop-up) {
    background-color: transparent;
    box-shadow: none;
  }
  &__header {
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up) {

    }
    @include breakpoint ($for-big-desktop-up) {

    }
  }
  &__list {
    @include breakpoint ($for-phone-only) {

    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up) {

    }
    @include breakpoint ($for-big-desktop-up) {

    }
    &-item {
      cursor: pointer;
      @include breakpoint ($for-phone-only) {
        &:not(:last-child){border-bottom: .01rem solid $color-border;}
      }
      @include breakpoint ($for-tablet-portrait-up) {
        &:not(:last-child){border-bottom: .01rem solid $color-border;}
      }
      @include breakpoint ($for-tablet-landscape-up) {
        &:not(:last-child){border-bottom: none;}
      }
      @include breakpoint ($for-desktop-up) {
        &:not(:last-child){border-bottom: none;}
      }
      @include breakpoint ($for-big-desktop-up) {
        &:not(:last-child){border-bottom: none;}
      }
      &-active {
        .m-level-education__list-item-name {
          color: $color-dark-blue;
        }
      }
      &-name {
        color: $color-link-normal;
        @include breakpoint ($for-phone-only) {

        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {

        }
        @include breakpoint ($for-desktop-up) {

        }
        @include breakpoint ($for-big-desktop-up) {

        }
      }
      &-count {
        color: $color-tooltip;
        @include breakpoint ($for-phone-only) {
          font-size: .7em;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          font-size: .7em;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          font-size: .7em;
        }
        @include breakpoint ($for-desktop-up) {
          font-size: .7em;
        }
        @include breakpoint ($for-big-desktop-up) {
          font-size: .7em;
        }
      }
    }
  }
}