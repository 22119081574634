.m-child-section {
  &__lvl-1 {
    &__item {
      //&:first-letter {
      //  text-transform: uppercase;
      //}
      //text-transform: lowercase;
      @include breakpoint($for-phone-only) {

      }
      @include breakpoint($for-tablet-portrait-up) {

      }
      @include breakpoint($for-tablet-landscape-up) {

      }
    }

    &-draft {
    }
  }

  &__lvl-2 {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
      background: white;
      border: 1px solid $color-border-second-menu;
      border-radius: 1rem;
      box-shadow: 0 13px 15px -5px $color-shadow-second-menu;
    }

    &__item a {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
        border-bottom: 1px solid transparent;
        line-height: 1.4rem;
        font-size: .8rem;
        &:hover,&:visited {
          border-bottom: 1px solid $color-text-orange-underline-light;
        }
        &:focus, &:active {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
}