.m-list-employe-search {
  @include breakpoint ($for-phone-only) {
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: .75rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
  }
  @include breakpoint ($for-tablet-landscape-up) {
    background-color: transparent;
    box-shadow: none;
  }
  @include breakpoint ($for-desktop-up) {
    background-color: transparent;
    box-shadow: none;
  }
}