.g-socials {
  &__title {
    margin-bottom: 1rem;
    padding-bottom: .25rem;
  }

  &__list {
    @include breakpoint($for-phone-only) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
    @include breakpoint($for-tablet-portrait-up) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
    @include breakpoint($for-tablet-landscape-up) {
      display: flex;
      flex-flow: column;
    }

    &-item {
      display: flex;
      align-items: center;

      &:hover, &:focus {
        text-decoration: none;
      }

      @include breakpoint($for-tablet-landscape-up) {
        &:not(:last-child) {
          margin-bottom: .8rem;
        }
      }

      &__icon {

        padding: .4rem;
        box-sizing: border-box;
        display: block;
        width: 2rem;
        height: 2rem;
        img{width: 100%}
        @include breakpoint($for-phone-only) {

        }
        @include breakpoint($for-tablet-portrait-up) {

        }
        @include breakpoint($for-tablet-landscape-up) {
        }
      }

      &__name {
        @include breakpoint($for-phone-only) {
          display: none;
        }
        @include breakpoint($for-tablet-portrait-up) {
          display: none;
        }
        @include breakpoint($for-tablet-landscape-up) {
          display: block;
          margin-left: .75rem;
        }
      }
    }
  }
}