.g-list-employe-search {
  @include breakpoint ($for-phone-only) {
    padding: 1rem;
    margin: 1rem .25rem 1rem .25rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    padding: 1rem;
    margin: 1rem .25rem 1rem .25rem;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    padding: 1rem;
    margin-bottom: 0;
  }
  @include breakpoint ($for-desktop-up) {
    padding: 0;
    margin-bottom: 0;
  }
}