.g-document-list {
  width: 100%;
  padding: 0 0 1rem 1rem;
  position: relative;
  box-sizing: border-box;
  @include breakpoint($for-phone-only) {
    padding: 0 1rem 1rem 0;

  }
  @include breakpoint($for-tablet-portrait-up) {
    padding: 0 1rem 1rem 0;

  }
  @include breakpoint($for-tablet-landscape-up) {
    padding: 0 1rem 1rem 0;

  }
  @include breakpoint($for-desktop-up) {
    padding: 0 0 1rem 1rem;

  }
  @include breakpoint($for-big-desktop-up) {
    padding: 0 0 1rem 1rem;

  }

  &__item {
    @include breakpoint($for-phone-only) {
      //margin-bottom: 1rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      //margin-bottom: 1rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      padding: 1rem;
      margin-left: -.5rem;
      margin-top: 1.5rem;
    }
    @include breakpoint($for-desktop-up) {
      padding: 1rem;
      margin-left: -.5rem;
      margin-top: 1.5rem;
    }
    @include breakpoint($for-big-desktop-up) {
      //margin-bottom: 1rem;
      padding: 1rem;
      margin-left: -.5rem;
      margin-top: 1.5rem;
    }

    &-empty {
      @include breakpoint($for-phone-only) {
        margin-right: -1rem;
        margin-left: -1rem;
        padding: .5rem .5rem .5rem 1rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        margin-right: -1rem;
        margin-left: -1rem;
        padding: .5rem .5rem .5rem 1rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        margin-right: -1rem;
        margin-left: -2rem;
        padding: .5rem .5rem .5rem 2rem;
      }
      @include breakpoint($for-desktop-up) {
        margin-right: 0;
        margin-left: -2rem;
        padding: .5rem .5rem .5rem 2rem;
      }
      @include breakpoint($for-big-desktop-up) {
        margin-right: 0;
        margin-left: -2rem;
        padding: .5rem .5rem .5rem 2rem;
      }
    }

    &-name {
      @include breakpoint($for-phone-only) {
        margin-bottom: .5rem;
      }
      @include breakpoint($for-tablet-portrait-up) {
        margin-bottom: .5rem;
      }
      @include breakpoint($for-tablet-landscape-up) {
        margin-bottom: .5rem;
      }
      @include breakpoint($for-desktop-up) {
        margin-bottom: .5rem;
      }
      @include breakpoint($for-big-desktop-up) {
        margin-bottom: .5rem;
      }
    }

    &-help {
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {
      }
    }

    &-content {
      margin-bottom: .5rem;
      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {
      }

      ul li a {
        //white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 45rem;
      }

      &__edit {
        @include breakpoint($for-phone-only) {
        }
        @include breakpoint($for-tablet-portrait-up) {
        }
        @include breakpoint($for-tablet-landscape-up) {
        }
        @include breakpoint($for-desktop-up) {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          margin: .3rem 0;
          padding: .3rem 0 .6rem 0;
        }
        @include breakpoint($for-big-desktop-up) {
          display: flex;
          flex-flow: row wrap;
          align-content: flex-start;
          margin: .3rem 0;
          padding: .3rem 0;

        }

        &-icon {
          width: 1rem;
          height: 1rem;
          display: inline-block;
          position: relative;

          &:hover {
            &:after {
              position: absolute;
              left: -3.5rem;
              top: 0;
              padding: .12rem .3rem;
            }
          }

          &__save {
            width: 1rem;
            height: 1rem;
            display: inline-block;
            position: relative;
            @include breakpoint($for-phone-only) {
            }
            @include breakpoint($for-tablet-portrait-up) {
            }
            @include breakpoint($for-tablet-landscape-up) {
            }
            @include breakpoint($for-desktop-up) {
              margin-right: .5rem;
            }
            @include breakpoint($for-big-desktop-up) {
              margin-right: .5rem;
            }

            &:hover {
              &:after {
                position: absolute;
                left: -3.7rem;
                top: 0;
                padding: .12rem .3rem;
              }
            }
          }

          &__loader {
            width: 1rem;
            height: 1rem;
            display: inline-block;
            position: relative;
            @include breakpoint($for-phone-only) {
            }
            @include breakpoint($for-tablet-portrait-up) {
            }
            @include breakpoint($for-tablet-landscape-up) {
            }
            @include breakpoint($for-desktop-up) {
              margin-right: .5rem;
            }
            @include breakpoint($for-big-desktop-up) {
              margin-right: .5rem;
            }

            &:hover {
              &:after {
                position: absolute;
                left: -3.8rem;
                top: 0;
                padding: .12rem .3rem;
              }
            }
          }

          @include breakpoint($for-phone-only) {
          }
          @include breakpoint($for-tablet-portrait-up) {
          }
          @include breakpoint($for-tablet-landscape-up) {
          }
          @include breakpoint($for-desktop-up) {
            margin-right: .5rem;
          }
          @include breakpoint($for-big-desktop-up) {
            margin-right: .5rem;
          }
        }

        &-choice {
          display: none;
        }

        &-doc {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          margin: .5rem 0;
          @include breakpoint($for-phone-only) {
          }
          @include breakpoint($for-tablet-portrait-up) {
          }
          @include breakpoint($for-tablet-landscape-up) {
          }
          @include breakpoint($for-desktop-up) {
          }
          @include breakpoint($for-big-desktop-up) {
          }

          &__item {
            margin-right: 1rem;
            @include breakpoint($for-phone-only) {
            }
            @include breakpoint($for-tablet-portrait-up) {
            }
            @include breakpoint($for-tablet-landscape-up) {
            }
            @include breakpoint($for-desktop-up) {
            }
            @include breakpoint($for-big-desktop-up) {
            }

            a {
              max-width: 30rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
            }
          }
        }

        &-link {
          margin-top: -.25rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @include breakpoint($for-phone-only) {
          }
          @include breakpoint($for-tablet-portrait-up) {
          }
          @include breakpoint($for-tablet-landscape-up) {
            max-width: 51rem;
          }
          @include breakpoint($for-desktop-up) {
            max-width: 51rem;
          }
          @include breakpoint($for-big-desktop-up) {
            max-width: 51rem;
          }
        }

        &-years {
          @include breakpoint($for-phone-only) {
          }
          @include breakpoint($for-tablet-portrait-up) {
          }
          @include breakpoint($for-tablet-landscape-up) {
          }
          @include breakpoint($for-desktop-up) {
            width: 100%;
            margin-left: 1.5rem;
          }
          @include breakpoint($for-big-desktop-up) {
            width: 100%;
            margin-left: 1.5rem;
          }
        }
      }

      &__title {
        @include breakpoint($for-phone-only) {
        }
        @include breakpoint($for-tablet-portrait-up) {
        }
        @include breakpoint($for-tablet-landscape-up) {
        }
        @include breakpoint($for-desktop-up) {
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-end;

        }
        @include breakpoint($for-big-desktop-up) {
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-end;

        }

        &-choice {

          @include breakpoint($for-phone-only) {
          }
          @include breakpoint($for-tablet-portrait-up) {
          }
          @include breakpoint($for-tablet-landscape-up) {
          }
          @include breakpoint($for-desktop-up) {

          }
          @include breakpoint($for-big-desktop-up) {

          }

          &-all {
            display: none;
            @include breakpoint($for-phone-only) {
            }
            @include breakpoint($for-tablet-portrait-up) {
            }
            @include breakpoint($for-tablet-landscape-up) {
            }
            @include breakpoint($for-desktop-up) {
              margin-right: 1rem;
            }
            @include breakpoint($for-big-desktop-up) {
              margin-right: 1rem;
            }
          }
        }

        &-name {
          @include breakpoint($for-phone-only) {
          }
          @include breakpoint($for-tablet-portrait-up) {
          }
          @include breakpoint($for-tablet-landscape-up) {
          }
          @include breakpoint($for-desktop-up) {
            margin-right: 1rem;
          }
          @include breakpoint($for-big-desktop-up) {
            margin-right: 1rem;
          }
        }
      }

      &__add {
        @include breakpoint($for-phone-only) {
        }
        @include breakpoint($for-tablet-portrait-up) {
        }
        @include breakpoint($for-tablet-landscape-up) {
        }
        @include breakpoint($for-desktop-up) {
          margin: .5rem 0 1rem 0;
          padding-bottom: .5rem;
        }
        @include breakpoint($for-big-desktop-up) {
          margin: .5rem 0 1rem 0;
          padding-bottom: .5rem;
        }

        &-item {

          @include breakpoint($for-phone-only) {
          }
          @include breakpoint($for-tablet-portrait-up) {
          }
          @include breakpoint($for-tablet-landscape-up) {
          }
          @include breakpoint($for-desktop-up) {
            margin-left: -2rem;
            padding: .5rem 0 .5rem 2rem;
            margin-top: .5rem;
          }
          @include breakpoint($for-big-desktop-up) {
            margin-top: .5rem;
            margin-left: -2rem;
            padding: .5rem 0 .5rem 2rem;
          }
        }

        &-header {
          display: flex;
          flex-flow: row nowrap;
          padding-bottom: .25rem;
          @include breakpoint($for-phone-only) {
          }
          @include breakpoint($for-tablet-portrait-up) {
          }
          @include breakpoint($for-tablet-landscape-up) {
          }
          @include breakpoint($for-desktop-up) {
          }
          @include breakpoint($for-big-desktop-up) {
          }

          &__item {
            margin-right: 1rem;
            @include breakpoint($for-phone-only) {
            }
            @include breakpoint($for-tablet-portrait-up) {
            }
            @include breakpoint($for-tablet-landscape-up) {
            }
            @include breakpoint($for-desktop-up) {

            }
            @include breakpoint($for-big-desktop-up) {

            }
          }
        }

        &-docs {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          margin: .5rem 0;
          @include breakpoint($for-phone-only) {
          }
          @include breakpoint($for-tablet-portrait-up) {
          }
          @include breakpoint($for-tablet-landscape-up) {
          }
          @include breakpoint($for-desktop-up) {
          }
          @include breakpoint($for-big-desktop-up) {
          }

          &__item {
            padding-right: .5rem;

            h5 {
              margin: 0;
            }

            @include breakpoint($for-phone-only) {
            }
            @include breakpoint($for-tablet-portrait-up) {
            }
            @include breakpoint($for-tablet-landscape-up) {
            }
            @include breakpoint($for-desktop-up) {
            }
            @include breakpoint($for-big-desktop-up) {
            }
          }
        }


        &-button {
          display: flex;
          align-items: center;
          flex-flow: row nowrap;
          margin: 1rem 0;

          &-icon {
            height: 1rem;
            width: 1rem;
            margin-right: .25rem;
            @include breakpoint($for-phone-only) {
            }
            @include breakpoint($for-tablet-portrait-up) {
            }
            @include breakpoint($for-tablet-landscape-up) {
            }
            @include breakpoint($for-desktop-up) {
            }
            @include breakpoint($for-big-desktop-up) {
            }
          }

          &-name {
            @include breakpoint($for-phone-only) {
            }
            @include breakpoint($for-tablet-portrait-up) {
            }
            @include breakpoint($for-tablet-landscape-up) {
            }
            @include breakpoint($for-desktop-up) {
            }
            @include breakpoint($for-big-desktop-up) {
            }
          }

        }
      }
    }

    &-del {
      position: absolute;
      top: .5rem;
      right: .5rem;

      @include breakpoint($for-phone-only) {
      }
      @include breakpoint($for-tablet-portrait-up) {
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
      @include breakpoint($for-desktop-up) {
      }
      @include breakpoint($for-big-desktop-up) {
      }
    }
  }

}