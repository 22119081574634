.m-static-page {
  @include breakpoint($for-phone-only) {
    border-bottom: 1px solid $color-border;
    
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: .75rem;
  }
  @include breakpoint($for-tablet-portrait-up) {
    border-bottom: 1px solid $color-border;
    
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
  }
  @include breakpoint($for-tablet-landscape-up) {
    border: 0;
    box-shadow: none;
  }
  @include breakpoint($for-desktop-up) {
    border: 0;
    box-shadow: none;
  }
  @include breakpoint($for-big-desktop-up) {
    border: 0;
    box-shadow: none;
  }

  &__title {
    @include breakpoint($for-phone-only) {
      font-size: 1.7em;
      
    }
    @include breakpoint($for-tablet-portrait-up) {
      font-size: 1.7em;
      
    }
    @include breakpoint($for-tablet-landscape-up) {
      font-size: 2em;
      background-color: transparent;
    }
    @include breakpoint($for-desktop-up) {
      font-size: 2em
    }
    @include breakpoint($for-big-desktop-up) {
      font-size: 2em
    }
  }

  &__subtitle {
    @include breakpoint($for-phone-only) {
      font-size: 1.1em
    }
    @include breakpoint($for-tablet-portrait-up) {
      font-size: 1.1em
    }
    @include breakpoint($for-tablet-landscape-up) {
      font-size: 1.1em
    }
    @include breakpoint($for-desktop-up) {
      font-size: 1.1em
    }
    @include breakpoint($for-big-desktop-up) {
      font-size: 1.1em
    }
  }

  &__editor {
    z-index: 100;
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {
    }
  }
}