.g-schedule {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {
  }
  @include breakpoint ($for-big-desktop-up) {
  }
  &__class-now {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
  &__weeks {
    @include breakpoint ($for-phone-only) {
      display: flex;
      flex-flow: row nowrap;
      margin: 0 -1rem .5rem -1rem;
      padding: .5rem 1rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      display: flex;
      flex-flow: row nowrap;
      margin: 0 -1rem .5rem -1rem;
      padding: .25rem 1rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
    &__item {
      @include breakpoint ($for-phone-only) {
        padding: .25rem 0;
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
      @include breakpoint ($for-tablet-portrait-up) {
        padding: .25rem 0;
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }

    }
  }
  &__days-of-the-week {
    @include breakpoint ($for-phone-only) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin: 0 0 .5rem 0;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin: 0 0 .5rem 0;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      display: flex;
      flex-flow: row nowrap;
      margin: 0 0 .5rem 0;
      justify-content: flex-start;
    }
    @include breakpoint ($for-desktop-up) {
      display: flex;
      flex-flow: row nowrap;
      margin: 0 0 .5rem 0;
      justify-content: flex-start;
    }
    @include breakpoint ($for-big-desktop-up) {
      display: flex;
      flex-flow: row nowrap;
      margin: 0 0 .5rem 0;
      justify-content: flex-start;
    }
    &__item {
      @include breakpoint ($for-phone-only) {
        padding: 0;
        margin: 0;
        width: auto;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        width: auto;
        padding: 0;
        margin: 0;
      }
      @include breakpoint ($for-tablet-landscape-up) {

        width: calc(85% / 6);
        &:not(:last-child) {
          padding-right: .8rem;
        }
        &:not(:first-child) {
          padding-left: .8rem;
        }
      }
      @include breakpoint ($for-desktop-up) {
        width: calc(100% / 6);
        &:not(:last-child) {
          padding-right: .8rem;
        }
        &:not(:first-child) {
          padding-left: .8rem;
        }
      }
      @include breakpoint ($for-big-desktop-up) {
        width: calc(100% / 6);
        &:not(:last-child) {
          padding-right: .8rem;
        }
        &:not(:first-child) {
          padding-left: .8rem;
        }
      }
    }
  }
  &__list-class {
    @include breakpoint ($for-phone-only) {
      margin-top: 1.5rem;
      display: none;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      margin-top: 1.5rem;
      display: none;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      display: block;
      width: calc(85% / 6);
      &:not(:last-child) {
        padding-right: .8rem;
      }
      &:not(:first-child) {
        padding-left: .8rem;
      }
    }
    @include breakpoint ($for-desktop-up) {
      width: calc(100% / 6);
      &:not(:last-child) {
        padding-right: .8rem;
      }
      &:not(:first-child) {
        padding-left: .8rem;
      }
    }
    @include breakpoint ($for-big-desktop-up) {
      width: calc(100% / 6);
      &:not(:last-child) {
        padding-right: .8rem;
      }
      &:not(:first-child) {
        padding-left: .8rem;
      }
    }
    &-week {
      @include breakpoint ($for-phone-only) {
        display: none;
        flex-flow: column;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        display: none;
        flex-flow: column;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        flex-flow: row nowrap;
      }
      @include breakpoint ($for-desktop-up) {
        flex-flow: row nowrap;
      }
      @include breakpoint ($for-big-desktop-up) {
        flex-flow: row nowrap;
      }
    }
    &__item {
      @include breakpoint ($for-phone-only) {
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
      @include breakpoint ($for-tablet-portrait-up) {
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
  }
  &__card {
    @include breakpoint ($for-phone-only) {
      width: 100%;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      width: 100%;
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
    &__header {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }

    }
    &__content {
      @include breakpoint ($for-phone-only) {
        display: flex;
        flex-flow: row wrap;
        padding: 1.5rem 0 0 .5rem;
        position: relative;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        display: flex;
        flex-flow: row wrap;
        padding: 1.5rem 0 0 .5rem;
        position: relative;
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }

    }
    &-type {
      @include breakpoint ($for-phone-only) {
        padding: 0 .5rem 0 0;
        text-align: right;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        padding: 0 .5rem 0 0;
        text-align: right;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        padding: 0 .5rem 0 0;
        text-align: right;
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
    &-room {
      @include breakpoint ($for-phone-only) {
        padding: .5rem;
        margin-top: -2.7rem;
        text-align: center;
        position: absolute;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        padding: .5rem;
        margin-top: -2.7rem;
        text-align: center;
        position: absolute;
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
    &-name {
      @include breakpoint ($for-phone-only) {
        width: 79%;
        padding: 0 .25rem 0 .5rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        width: 79%;
        padding: 0 .25rem 0 .5rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        padding-bottom: .5rem;
      }
      @include breakpoint ($for-desktop-up) {
        padding-bottom: .5rem;
      }
      @include breakpoint ($for-big-desktop-up) {
        padding-bottom: .5rem;
      }
    }
    &-time {
      @include breakpoint ($for-phone-only) {
        width: 11%;
        padding: 0 .5rem 0 .25rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        width: 11%;
        padding: 0 .5rem 0 .25rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        width: 100%;
        padding: 0 0 .25rem 0;
        margin-bottom: .25rem;
      }
      @include breakpoint ($for-desktop-up) {
        width: 100%;
        padding: 0 0 .25rem 0;
        margin-bottom: .25rem;
      }
      @include breakpoint ($for-big-desktop-up) {
        width: 100%;
        padding: 0 0 .25rem 0;
        margin-bottom: .25rem;
      }
      &__from {
        @include breakpoint ($for-phone-only) {
          text-align: center;
          &:after {
            content: " - ";
            display: block;
            width: auto;
          }
        }
        @include breakpoint ($for-tablet-portrait-up) {
          text-align: center;
          &:after {
            content: " - ";
            display: block;
            width: auto;
          }
        }
        @include breakpoint ($for-tablet-landscape-up) {
          display: inline-block;
          text-align: left;
          margin-right: .2rem;
          &:after {
            content: '';
          }
          &:before {
            content: "c";
            display: inline-block;
            width: auto;
            padding-right: .2rem;
          }
        }
        @include breakpoint ($for-desktop-up) {
          display: inline-block;
          text-align: left;
          margin-right: .2rem;
          &:after {
            content: '';
          }
          &:before {
            content: "c";
            display: inline-block;
            width: auto;
            padding-right: .2rem;
          }
        }
        @include breakpoint ($for-big-desktop-up) {
          display: inline-block;
          text-align: left;
          margin-right: .2rem;
          &:after {
            content: '';
          }
          &:before {
            content: "c";
            display: inline-block;
            width: auto;
            padding-right: .2rem;
          }
        }
      }
      &__to {
        @include breakpoint ($for-phone-only) {
          text-align: center;
        }
        @include breakpoint ($for-tablet-portrait-up) {
          text-align: center;
        }
        @include breakpoint ($for-tablet-landscape-up) {
          display: inline-block;
          text-align: left;

          &:before {
            content: 'до';
            width: auto;
            display: inline-block;
            padding-right: .2rem;
          }
        }
        @include breakpoint ($for-desktop-up) {
          display: inline-block;
          text-align: left;

          &:before {
            content: 'до';
            width: auto;
            display: inline-block;
            padding-right: .2rem;
          }
        }
        @include breakpoint ($for-big-desktop-up) {
          display: inline-block;
          text-align: left;
          &:before {
            content: 'до';
            width: auto;
            display: inline-block;
            padding-right: .2rem;
          }
        }
      }
    }
    &-lecturer {
      @include breakpoint ($for-phone-only) {
        padding: .5rem;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        padding: .5rem;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        padding: .5rem;
      }
      @include breakpoint ($for-desktop-up) {
        padding: .5rem;
      }
      @include breakpoint ($for-big-desktop-up) {
        padding: .5rem;
      }
    }
  }
}

.g-schedule__list-home {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {
  }
  @include breakpoint ($for-big-desktop-up) {
  }
  .g-schedule__list-class {
    @include breakpoint ($for-phone-only) {
      display: none;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      display: none;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      width: 100%;
      display: none;
    }
    @include breakpoint ($for-desktop-up) {
      width: 100%;
      display: none;
    }
    @include breakpoint ($for-big-desktop-up) {
      width: 100%;
      display: none;
    }
    .g-schedule__list-class__item {
      @include breakpoint ($for-phone-only) {
        display: none;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        display: none;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        display: none;
      }
      @include breakpoint ($for-desktop-up) {
        display: none;
      }
      @include breakpoint ($for-big-desktop-up) {
        display: none;
      }
    }
  }
}