.m-filter-struct-ec {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {

  }
  &__item {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {

    }
    @include breakpoint ($for-desktop-up) {
    }
    label{
      font-size: .95em;
    }
    input:checked + label {
      background-color: rgba(97, 87, 174, 0.1);
    }
  }
}