.m-slider {

  &-empty {
  }

  &-news {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {

    }
    @include breakpoint($for-tablet-landscape-up) {

    }

    .fotorama {
    }

    .fotorama__img {
      object-fit: cover;
    }

    .item_fotorama {
      background-color: rgba(0, 0, 0, 0.45);
      @include breakpoint($for-phone-only) {
        border-radius: .75rem .75rem 0 0;
      }
      @include breakpoint($for-tablet-portrait-up) {
        border-radius: .75rem .75rem 0 0;
      }
      @include breakpoint($for-tablet-landscape-up) {
        border-radius: 1.8rem;
      }
    }

    .fotorama__stage {
      &:before {
        background: transparent !important;
      }

      @include breakpoint($for-phone-only) {
        border-radius: .75rem .75rem 0 0;
      }
      @include breakpoint($for-tablet-portrait-up) {
        border-radius: .75rem .75rem 0 0;

      }
      @include breakpoint($for-tablet-landscape-up) {

        border-radius: 1.8rem;
      }
      @include breakpoint($for-desktop-up) {
        border-radius: 1.8rem;
      }

      &__frame {
        @include breakpoint($for-phone-only) {
          border-radius: .75rem .75rem 0 0;
        }
        @include breakpoint($for-tablet-portrait-up) {
          border-radius: .75rem .75rem 0 0;

        }
        @include breakpoint($for-tablet-landscape-up) {

        }
        @include breakpoint($for-desktop-up) {
          border-radius: 1rem;
        }
      }

    }

    &__title {
      color: white;
      font-family: 'Futura PT', sans-serif;
      border-bottom: .01rem solid white;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:hover, &:focus {
        color: white;
        text-decoration: none;
        border-bottom: .01rem solid white;
      }

      &:visited {
        color: white;
      }

      @include breakpoint($for-phone-only) {
        overflow: hidden;
        font-size: 1.5em;
      }
      @include breakpoint($for-tablet-portrait-up) {
        font-size: 1.5em;
      }
      @include breakpoint($for-tablet-landscape-up) {
        font-size: 2em;
      }
    }

    &__text {
      color: white;

      a {
        color: white;
        //text-decoration: underline;
      }

      @include breakpoint($for-phone-only) {
        overflow: hidden;
      }
      @include breakpoint($for-tablet-portrait-up) {
        overflow: hidden;
      }
      @include breakpoint($for-tablet-landscape-up) {
      }
    }
  }
}