.unknown_curator {
    background: red;
}
.ec_select_left_block {
    width: 400px;
}
.ec_select_right_block{
    width: 140px;
    margin-left: 10px;
    margin-top: -6px;
}
.disabled {
    opacity: .8;
}