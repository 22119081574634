.m-training-course {
  @include breakpoint ($for-phone-only) {
    border-bottom: .01rem solid $color-border-light;
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  border-bottom: .01rem solid $color-border-light;
  @include breakpoint ($for-tablet-landscape-up) {
    border: 0;
    &:hover, &:focus {
      background-color: lighten($color-border-light, 10%);
      border-left: 0.01rem solid $color-border-pagination-hover;
      cursor: pointer;
    }
  }
  @include breakpoint ($for-desktop-up) {
    border: 0;
    &:hover, &:focus {
      background-color: lighten($color-border-light, 10%);
      border-left: 0.01rem solid $color-border-pagination-hover;
      cursor: pointer;
    }
  }
  @include breakpoint ($for-big-desktop-up) {
    border: 0;
    &:hover, &:focus {
      background-color: lighten($color-border-light, 10%);
      border-left: 0.01rem solid $color-border-pagination-hover;
      cursor: pointer;
    }
  }
  &__empty {
      background-color: mapget($color-bg-status, warning);
  }
  &__info {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
    &-name {
      font-weight: 600;
      font-size: 1.2rem;
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
    &-other {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
      &__status {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
        }
        @include breakpoint ($for-big-desktop-up) {
        }
      }
      &__language {
        color: $color-dark-blue;
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
        }
        @include breakpoint ($for-big-desktop-up) {
        }
      }
    }
  }
  &__semester {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
  &__docs {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {
    }
  }
  &-title {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
      border-bottom: 0.01rem solid $color-border-light;
    }
    @include breakpoint ($for-desktop-up) {
      border-bottom: 0.01rem solid $color-border-light;
    }
    @include breakpoint ($for-big-desktop-up) {
      border-bottom: 0.01rem solid $color-border-light;
    }
    &__name {
      color: $color-text-grey;
      font-size: .8rem;
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {

      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {
      }
    }
    &__doc {
      color: $color-text-grey;
      font-size: .8rem;
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {

      }
      @include breakpoint ($for-desktop-up) {

      }
      @include breakpoint ($for-big-desktop-up) {

      }
    }
  }

}