.m-list-years-ec {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {
  }
  &__show-year {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
      font-size: 2.5rem;
      line-height: initial;
    }
  }
  &__select-year {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
      font-size: .7rem;
      @include link-colors($color-link-normal, $color-link-hover, $color-link-visited, $color-link-active, $color-link-focus);
    }
    &:hover, &:focus {
      cursor: pointer;
    }
    &__content {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
        background-color: white;
        box-shadow: 0 5px 15px 0 #bababa;
      }
      &-item {
        @include breakpoint ($for-phone-only) {
        }
        @include breakpoint ($for-tablet-portrait-up) {
        }
        @include breakpoint ($for-tablet-landscape-up) {
        }
        @include breakpoint ($for-desktop-up) {
          color: $color-text;
          font-size: 1rem;
        }
        &:hover,&:focus{
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

}