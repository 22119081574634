.g-filter-struct-ec {
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {
  }
  @include breakpoint ($for-desktop-up) {

  }
  &__item {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin-bottom: .1rem;
    }
    @include breakpoint ($for-desktop-up) {
      margin-bottom: .1rem;
    }
    label{
      padding: .2rem .2rem .1rem .2rem;
      display: inline-block;
    }
  }
}