.g-gotoup {
  position: fixed;

  @include breakpoint($for-phone-only) {
    display: none ;
  }
  @include breakpoint($for-tablet-portrait-up) {
    display: none ;
  }
  @include breakpoint($for-tablet-landscape-up) {
    display: none;
    bottom: 2rem;
    right: 1rem;
  }
  @include breakpoint($for-desktop-up) {
    display: none;
    bottom: 5rem;
    right: 2rem;
  }
  @include breakpoint($for-big-desktop-up) {
    display: none;
    bottom: 5rem;
    right: 2rem;
  }
}