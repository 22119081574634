.m-publication-base {
  @include breakpoint ($for-phone-only) {
    border-bottom: 1px solid $color-border;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: .75rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    border-bottom: 1px solid $color-border;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: .75rem;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    border-bottom: 0;
    box-shadow: none;
  }
}
