.g-gallery {
  width: 100% !important;
  @include breakpoint ($for-phone-only) {
  }
  @include breakpoint ($for-tablet-portrait-up) {
    display: flex;
    flex-flow: row wrap;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    display: flex;
    flex-flow: row wrap;
  }

  a {
    display: inline-block;
    padding: 0;
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
      margin-right: 1rem;
    }

    img {
      @include breakpoint ($for-phone-only) {
        margin-top: -.3rem;
        width: 7rem !important;
        height: 7rem !important;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        width: 6rem !important;
        height: 6rem !important;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        width: 6rem;
        height: 6rem;
      }

    }
  }
}

