.g-how-to-do {
  @include breakpoint ($for-phone-only) {
    margin: 1rem .5rem 0 .5rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    margin: 1rem .5rem 0 .5rem;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    margin: 2rem 0;
  }
  @include breakpoint ($for-desktop-up) {
    margin: 2rem 0;
  }
  @include breakpoint ($for-big-desktop-up) {
    margin: 2rem 0;
  }
}