.g-news-categories {
  display: flex;
  @include breakpoint($for-phone-only) {
    flex-flow: wrap;
    padding: 0 .875rem 1rem .875rem;
    &:not(:last-child) {
      margin-bottom: .25rem;
    }
  }
  @include breakpoint($for-tablet-portrait-up) {
    flex-flow: wrap;
    margin: 0 0 1rem 0;
    padding: 0 .875rem 1rem .875rem;
    &:not(:last-child) {
      margin-right: .4rem;
    }
  }
  @include breakpoint($for-tablet-landscape-up) {
    flex-flow: row nowrap;
    margin: 1rem 0 4rem 0;
    padding: 0;
    &:not(:last-child) {
      margin-right: .4rem;
    }
  }

  &__item {
    &-active {
    }
  }
}