.g-description-module {
  z-index: 1;
  img{
    display: none !important;
  }
  @include breakpoint ($for-phone-only) {
    padding: 1rem;
    margin: 0 .25rem;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    padding: 1rem;

  }
  @include breakpoint ($for-tablet-landscape-up) {
    padding: 1rem;

  }
  @include breakpoint ($for-desktop-up, $for-big-desktop-up) {
    padding: 0;

  }
}
