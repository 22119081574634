.g-header-wrapper {
  @include breakpoint($for-phone-only) {
  }
  @include breakpoint($for-tablet-portrait-up) {

  }
  @include breakpoint($for-tablet-landscape-up) {
    width: $small;

  }
  @include breakpoint($for-desktop-up) {
    width: $middle;

  }
  @include breakpoint($for-big-desktop-up) {
    width: $big;

  }

  &__top-bar {
    position: relative;
    @include breakpoint($for-phone-only) {
      height: auto;
      display: none;
    }
    @include breakpoint($for-tablet-portrait-up) {
      display: none;
    }
    @include breakpoint($for-tablet-landscape-up) {
      display: flex;
      flex-flow: row nowrap;
      padding: 1rem 0;
      align-items: center;
    }
    @include breakpoint($for-desktop-up) {
      display: flex;
      flex-flow: row nowrap;
      padding: 1rem 0;
      align-items: center;
    }
    @include breakpoint($for-big-desktop-up) {
      display: flex;
      flex-flow: row nowrap;
      padding: 1rem 0;
      align-items: center;
    }
  }

  .g-user {
    @include breakpoint($for-phone-only) {
      margin-bottom: 3rem;
    }
  }

  &__content-bar {
    display: flex;
    flex-flow: row nowrap;
    @include breakpoint($for-phone-only) {
      flex-flow: column;
    }
    @include breakpoint($for-tablet-portrait-up) {
      flex-flow: column;
    }
    @include breakpoint($for-tablet-landscape-up) {
      flex-flow: row nowrap;
      display: flex;
      padding: 0 1rem;
      margin: 1.5rem 0;
    }
    @include breakpoint($for-desktop-up) {
      flex-flow: row nowrap;
      display: flex;
      padding: 0;
      margin: 1.5rem 0;
    }
  }

  &__bottom {
    position: relative;
    @include breakpoint($for-phone-only) {

    }
    @include breakpoint($for-tablet-portrait-up) {
      margin: 1rem 1rem 1.5rem 1rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      padding: 0 1rem;
      margin: 0;
    }
    @include breakpoint($for-desktop-up) {
      margin: 0;
      padding: 0;
    }
    @include breakpoint($for-big-desktop-up) {
      padding: 0;
      margin: 0;
    }

    &-item {
      margin-right: .5rem;
    }

    &-bar {
      position: relative;
    }

    &__home {
      @include breakpoint($for-phone-only) {
        margin: 1rem 0 1.5rem 0;
      }
      @include breakpoint($for-tablet-portrait-up) {
        margin: 1rem 0 1.5rem 0;
      }
      @include breakpoint($for-tablet-landscape-up) {
        margin: 0;
      }
      @include breakpoint($for-desktop-up) {
        margin: 0;
      }
      @include breakpoint($for-big-desktop-up) {
        margin: 0;
      }
    }
  }

  &__home {
    @include breakpoint($for-phone-only) {
      margin: 2rem 1rem 0 1rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      margin: 2rem 1rem 0 1rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      margin: 0;
    }
    @include breakpoint($for-desktop-up) {
      margin: 0;
    }
    @include breakpoint($for-big-desktop-up) {
      margin: 0;
    }
  }

}