.m-page {
  @include breakpoint ($for-phone-only) {
    background-color: white;
  }
  @include breakpoint ($for-tablet-portrait-up) {
    background-color: white;
  }
  @include breakpoint ($for-tablet-landscape-up) {
    background-color: transparent;
    box-shadow: none;
  }
  @include breakpoint ($for-desktop-up) {
    background-color: transparent;
    box-shadow: none;
  }

  &__title {
    font-family: 'Century Gothic', sans-serif;
    @include breakpoint ($for-phone-only) {
      font-size: 1.7em;
      color: $color-text;
      font-weight: bold;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      font-size: 1.7em;
      color: $color-text;
      font-weight: bold;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      font-size: 2em;
      color: $color-text;
    }
    @include breakpoint ($for-desktop-up) {
      font-size: 2em;
      color: $color-text;
    }

    &-light {
      @include breakpoint ($for-phone-only) {
        background-color: white;
        //box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
      }
      @include breakpoint ($for-tablet-portrait-up) {
        background-color: white;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
      }
      @include breakpoint ($for-tablet-landscape-up) {
        background-color: transparent;
        box-shadow: none;
      }
      @include breakpoint ($for-desktop-up) {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }

  &__subtitle {
    @include breakpoint ($for-phone-only) {
      font-size: .9em;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      font-size: .9em;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      font-size: 1em;
    }
    @include breakpoint ($for-desktop-up) {
      font-size: 1.1em;
    }
  }
}