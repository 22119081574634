.m-checkbox{
  &__input{
    &:checked + .m-checkbox__custom-input:before{
      background: url(/static/img/ok.svg) center center no-repeat;
      background-size: 100%;
      content: "";
    }
  }
  &__custom-input{
    //border: .1rem solid $color-text-blue;
    //border-radius: .25rem;
    background: url(/static/img/checkbox.svg) center center no-repeat;
    &:hover, &:focus{
      cursor: pointer;
    }
  }
  &__label{}
}