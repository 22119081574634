.g-person-auxiliary-info {
  @include breakpoint ($for-phone-only) {
    padding: 1rem;
    margin:  .5rem .25rem;

  }
  @include breakpoint ($for-tablet-portrait-up) {
    padding: 1rem;
    margin: .5rem .25rem;

  }
  @include breakpoint ($for-tablet-landscape-up) {
    padding: 1rem;
    margin: 1rem 0;
  }
  @include breakpoint ($for-desktop-up) {
    padding: 0;
    margin: 1rem 0;
  }
  @include breakpoint ($for-big-desktop-up) {
    margin: 1rem 0;
    padding: 0;
  }

  &__work {
    a {
      &:before {
        content: '—';
        display: inline-block;
        width: auto;
        height: 1em;
        margin-right: .25rem;
      }

      &:not(:last-child) {
        margin-bottom: .5rem;
      }
    }

    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {

    }
  }

  &__grate {
    width: 100%;
    @include breakpoint ($for-phone-only) {
      &:not(:empty) {
        margin: .5rem 1% .5rem 1%;
      }
    }
    @include breakpoint ($for-tablet-portrait-up) {
      &:not(:empty) {
        margin: .5rem 1% .5rem 1%;
      }
    }
    @include breakpoint ($for-tablet-landscape-up) {
      &:not(:empty) {
        margin: .5rem 1% .5rem 1%;
      }
    }
    @include breakpoint ($for-desktop-up) {
      &:not(:empty) {
        margin: 0 0 .5rem 0;
      }
    }
    @include breakpoint ($for-big-desktop-up) {
      &:not(:empty) {
        margin: 0 0 .5rem 0;
      }
    }
  }

  &__experience {
    span {
      display: block;
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
        margin-bottom: -.4rem;
      }
      @include breakpoint ($for-big-desktop-up) {
        margin-bottom: -.4rem;
      }
    }

    &-list {
      @include breakpoint ($for-phone-only) {
        display: flex;
        flex-flow: row nowrap;
        margin: 0 0 1rem 0;
      }
      @include breakpoint ($for-tablet-portrait-up) {
        display: block;
        margin: 0 0 1rem 0;
      }
      @include breakpoint ($for-tablet-landscape-up) {
        display: block;
        margin: 0 0 1rem 0;
      }
      @include breakpoint ($for-desktop-up) {
        display: block;
        margin: 0;
      }
      @include breakpoint ($for-big-desktop-up) {
        display: block;
        margin: 0;
      }
    }

    @include breakpoint ($for-phone-only) {
      display: inline-block;
      width: 30%;
      vertical-align: top;
      margin: .5rem 1% .5rem 1%;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      display: inline-block;
      width: auto;
      vertical-align: top;
      margin: .5rem 1% .5rem 1%;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      display: inline-block;
      width: auto;
      vertical-align: top;
      margin: .5rem 1% .5rem 1%;
    }
    @include breakpoint ($for-desktop-up) {
      display: block;
      width: 100%;
      vertical-align: top;
      margin: 0 0 .5rem 0;
    }
    @include breakpoint ($for-big-desktop-up) {
      display: block;
      width: 100%;
      vertical-align: top;
      margin: 0 0 .5rem 0;
    }
  }

  &__contacts {
    @include breakpoint ($for-phone-only) {
    }
    @include breakpoint ($for-tablet-portrait-up) {
    }
    @include breakpoint ($for-tablet-landscape-up) {
    }
    @include breakpoint ($for-desktop-up) {
    }
    @include breakpoint ($for-big-desktop-up) {

    }

    &-address {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {

      }
    }

    &-phone {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {

      }
    }

    &-email {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {

      }
    }

    &-opening-hours {
      @include breakpoint ($for-phone-only) {
      }
      @include breakpoint ($for-tablet-portrait-up) {
      }
      @include breakpoint ($for-tablet-landscape-up) {
      }
      @include breakpoint ($for-desktop-up) {
      }
      @include breakpoint ($for-big-desktop-up) {

      }
    }
  }
}