.m-header-information {
  font-size: .625rem;
  color: $color-text-grey;

  &__cycle {
    border-radius: .25rem;

    &-green {
      background-color: green;
    }
  }

  &__link {
    font-size: .625rem;
  }
}