/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;

  .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    -moz-backface-visibility: hidden; /* fix firefox animation glitch */
  }

  .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }

  .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);
  }

  .owl-wrapper,
  .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
  }

  .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    @include breakpoint ($for-phone-only) {
      height: 15rem;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      height: 15rem;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      height: 18rem;
    }
    @include breakpoint ($for-desktop-up) {
      height: 18rem;
    }
    @include breakpoint ($for-big-desktop-up) {
      height: 18rem;
    }
  }
  .owl-item img {
    display: block;
    width: 100%;
  }

  .owl-nav.disabled,
  .owl-dots.disabled {
    display: none;
  }

  .owl-nav .owl-prev,
  .owl-nav .owl-next,
  .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &.owl-loaded {
    display: block;
  }

  &.owl-loading {
    display: block;
  }

  &.owl-hidden {
    opacity: 0;
  }

  &.owl-loading {
    animation: refresh 3s ease-in-out 1;
    -webkit-animation: refresh 3s ease-in-out 1;
  }
  @keyframes refresh {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1
    }
  }
  &.owl-refresh .owl-item {
    visibility: hidden;
    opacity: 0;
  }

  &.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &.owl-grab {
    cursor: move;
    cursor: grab;
  }

  &.owl-rtl {
    direction: rtl;
  }

  &.owl-rtl .owl-item {
    float: right;
  }
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}
