.m-header-section {
  z-index: 1;
  overflow: hidden;

  img {
    opacity: .5;
    @include breakpoint($for-phone-only) {
      object-fit: cover;
    }
    @include breakpoint($for-tablet-portrait-up) {
      object-fit: cover;
    }
    @include breakpoint($for-tablet-landscape-up) {
      object-fit: cover;
    }
    @include breakpoint($for-desktop-up, $for-big-desktop-up) {
      object-fit: cover;
    }
  }


  @include breakpoint($for-phone-only) {
    box-shadow: 0 38px 24px -24px $color-shadow-m-header-section;
  }
  @include breakpoint($for-tablet-portrait-up) {
    box-shadow: 0 38px 24px -24px $color-shadow-m-header-section;
  }
  @include breakpoint($for-tablet-landscape-up) {
    box-shadow: 0 38px 24px -24px $color-shadow-m-header-section;
  }

  &-small {
    @include breakpoint($for-phone-only) {
    }
    @include breakpoint($for-tablet-portrait-up) {
    }
    @include breakpoint($for-tablet-landscape-up) {
    }
    @include breakpoint($for-desktop-up) {
    }
    @include breakpoint($for-big-desktop-up) {
    }
  }

  &-middle {
    @include breakpoint($for-phone-only) {
      background: $color-bg-m-header-section;
      border-radius: .75rem;
    }
    @include breakpoint($for-tablet-portrait-up) {
      background: $color-bg-m-header-section;
      border-radius: .75rem;
    }
    @include breakpoint($for-tablet-landscape-up) {
      background: $color-bg-m-header-section;
      border-radius: 1.875rem;
    }
    @include breakpoint($for-desktop-up) {
      background: $color-bg-m-header-section;
      border-radius: 1.875rem;
    }
    @include breakpoint($for-big-desktop-up) {
      background: $color-bg-m-header-section;
      border-radius: 1.875rem;
    }
  }


}
