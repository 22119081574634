//Страница редактирования новости
.new_edit{
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  .block_news_edit{
    padding: 0 1.5rem;
    &:nth-child(2) {
      border-left: 1px solid $color-border;
    }
    input[type=checkbox] {
      width: 15px;
      height: 15px;
      margin: 0 5px;
    }
    div[id^="cke_id_text"] {
      width: 100%!important;
    }
    .bootstrap-select {margin-bottom: .5rem;}
    .tabs {
      margin-top: 0;
    }
    section {
      .checkbox_events {
        .lesson {
          margin-bottom: 1rem;
          label{display: block;}
        }
        .period {
          width: 60%;
          display: inline-block;
          vertical-align: top;
        }
        .period:last-child {
          width: 39%;
          display: inline-block;
          visibility: hidden;
        }
      }
    }
    .checkbox_events {
      .checkbox_period, .week {
        display: inline-block;
        width: 49.5%;
        vertical-align: top;
      }
      label {
        display: flex;
        margin: 3px 0;
        input[type=radio], input[type=checkbox] {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
      }
    }
    img{
      max-width: 12rem;
    }
    .new_slide {
      position: relative;
      padding-top: 1.5rem;
      .delete-slide {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    hr {
      margin: 1rem 0;
    }
    input[type=file] {
      display: block;
      margin-bottom: .5rem;
    }
    .events_edit {
      margin-top: 3rem;
    }
  }
}
