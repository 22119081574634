.m-list-profiles {
  @include breakpoint ($for-phone-only) {

  }
  @include breakpoint ($for-tablet-portrait-up) {
  }
  @include breakpoint ($for-tablet-landscape-up) {

  }
  @include breakpoint ($for-desktop-up) {

  }
  @include breakpoint ($for-big-desktop-up) {

  }
  .m-tabs-active{
    color: $color-dark-blue;
    border-right: .01rem solid $color-border-pagination-hover;
  }
  &__item {
    border-right: .01rem solid $color-border;
    @include breakpoint ($for-phone-only) {
      font-size: .9em;
    }
    @include breakpoint ($for-tablet-portrait-up) {
      font-size: .9em;
    }
    @include breakpoint ($for-tablet-landscape-up) {
      font-size: .9em;
    }
    @include breakpoint ($for-desktop-up) {
      font-size: .9em;
    }
    @include breakpoint ($for-big-desktop-up) {
      font-size: .9em;
    }
  }
}